.row {
  .column {
    img {
      width: 100%;
    }
  }
}

.LoaderDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ThreeRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.TwoRow {
  display: none;
  width: 100%;
  justify-content: space-between;
  // display: none;
}

.OneRow {
  // display: none;
  width: 100%;
  // justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.searchBOX {
  &.nlw {
    margin-top: 100px;
  }
}

.newsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00141f;

  //   background-color: red;
  .wpagni {
    margin-top: 40px;
    // width: 70%;

    // flex-direction: row !important;
    // flex-wrap: wrap;

    .allblogWrapperMain {
      display: grid !important;
      grid-template-columns: auto auto;
      align-items: flex-start;
      gap: 20px;
      justify-content: space-between;
    }
  }

  .paginationOwn {
    list-style: none;
    display: flex;
    padding: 5px;
    // background-color: #fff;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .pprevlcc {
    }

    li {
      &.selected {
        a {
          color: #00141f;
          background-color: #fff;
          padding: 5px 10px;
        }
      }
    }

    a {
      color: #fff;
      padding: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.newsListWrapper {
  padding-top: 10vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;

  .leftCon {
    display: flex;
    flex-direction: column;
  }

  .rightCon {
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 25%;
  /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  .ThreeRow {
    // display: none;
  }

  .TwoRow {
    // display: flex;
  }

  .OneRow {
    // display: none;
    .blogBtmWRAP {
      flex-direction: column;
      .leftCon {
        order: 2;
      }
      .rightFilter {
        order: 1;
      }
      .filter-card{
        .NewsFilters{
          display: unset !important;
          label.contact-chip{
            width: fit-content;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) and (min-width: 600px){
  .OneRow {
    .leftCon {
      .allblogWrapperMain{
        // display: flex !important;
      }
    }
  }
}
@media only screen and (max-width: 600px){
  .OneRow {
    display: flex;
    .leftCon {
      .allblogWrapperMain{
        display: unset !important;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .searchBOX {
    width: auto;
  }
  .ThreeRow {
    display: none;
  }

  .TwoRow {
    display: none;
  }

  .OneRow {
    display: flex;
    .leftCon {
      .allblogWrapperMain{
        // display: unset !important;
        .solo-BLOGCON{
          width: auto;
          padding: 1rem;
          img{
            height: 150px;
          }
          .BLOG-Tags{
            p{
              margin-bottom: 5px !important;
              font-size: 10px;
            }
          }
          .tp{
            font-size: 12px;
            line-height: 18px;
          }
          .BLOG-content{
            p{
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .newsListWrapper .leftCon {
    align-items: center;
  }
}

.solo-BLOGCON {
  cursor: pointer;
  margin: 2rem;
  width: 410px;
  min-height: 456px;
  background: #023047;
  // background: red;
  box-shadow: -20px 20px 40px rgba(166, 36, 75, 0.1);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.03);
  }

  .BLOG-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
  }

  img {
    height: 193px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
  }

  .BLOG-Tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      border-radius: 5px;
      margin-right: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      /* identical to box height, or 175% */
      background: #000e1454;
      padding: 2px 10px;
      color: white;
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    /* or 175% */

    color: white;

    &.tp {
      font-weight: 700;
      font-size: 14px;
    }
  }

  .Blog-BOTM {
    display: flex;

    align-items: center;
    justify-content: space-between;

    p {
      &.lp {
        font-weight: 600;
      }

      &.rp {
        opacity: 0.5;
      }
    }
  }
}

.blogBtmWRAP {
  display: flex;

  .rightFilter {
    margin-top: 50px;
    min-width: 200px;
    .filter-card{
      .NewsFilters{
        display: flex;
        flex-direction: column;
      }
    }
  }
}

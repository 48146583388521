.NSecFourWhatMain {
    padding-top: 50px;
    padding-bottom: 40px;
    // height: 523px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000E14;

    .NSecFourWhatMainT {
        h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: capitalize;
            color: #FFFFFF;
        }
    }

    .NSecFourWhatMainM {

        display: flex;
        align-items: center;
        justify-content: center;

        .NSFLine {
            height: 269px;
            width: 2px;
            background-image: radial-gradient(#fff, rgba(255, 255, 255, 0.199), #000e1449, #000e1441);
        }

        .NSecFourWhatCard {
            margin-top: 40px;
            height: 269px;
            // background-color: rgb(240, 119, 119);
            width: 385px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                margin-top: 30px;
                font-family: Archivo;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height, or 150% */

                text-align: center;
                width: 138px;
                color: #FFFFFF;
            }

            p {

                font-family: Lexend;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */

                text-align: center;
                width: 300px;
                color: #FFFFFF;
            }
        }
    }
}

@media only screen and (max-width: 970px) {
    .NSecFourWhatMain {}

    .NSecFourWhatMain .NSecFourWhatMainM .NSFLine {
        &.lh {

            display: none;
        }
    }

    .NSecFourWhatMain .NSecFourWhatMainM {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: center;

        .litem {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            grid-column: 1 / span 3;
        }
    }
}

@media only screen and (max-width: 500px) {
    .NSecFourWhatMain {}

    .NSecFourWhatMain .NSecFourWhatMainM .NSFLine {

        transform: rotate(90deg);
        display: flex;
        margin: -109px 0;

        &.lh {

            display: flex;
        }
    }

    .NSecFourWhatMain .NSecFourWhatMainM .NSecFourWhatCard {
        margin-top: 0;
    }

    .NSecFourWhatMain .NSecFourWhatMainM {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }
}
header.custom-header {
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  transition: 0.3s all;
  border-bottom: 1px solid transparent;
}

.custom-header.is-scrolled {
  padding: 5px 0;
  background-color: #00141f;
  border-bottom-color: rgba(33, 158, 188, 1);
}

.custom-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-header-inner .head-menu {
  display: flex;
  align-items: center;
  list-style: none;
}

.head-menu-link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s all;
  cursor: pointer;
}

.head-menu-link.active {
  color: rgba(33, 158, 188, 1);
}

.head-menu-link:hover {
  color: #9eb8e0;
}

nav.head-menu .head-menu-item {
  position: relative;
  padding: 20px 5px;
}

nav.head-menu .head-menu-item:not(:last-child) {
  margin-right: 36px;
}

nav.head-menu .head-menu-item:not(:last-child)::after {
  content: '';
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: white;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: -18px;
}

.head-menu-item.custom-dropdown {
  position: relative;
}

.head-menu-item.custom-dropdown:hover .head-sub-menu {
  display: block;
}

.head-menu .head-sub-menu {
  display: none;
  /* display: block; */
  position: absolute;
  list-style-type: none;
  /* padding: 0; */
  /* min-width: 65vw; */
  z-index: 1001;
  left: 50%;
  transform: translateX(-50%);
  background-color: #012436;
  top: 60px;
}

.head-menu .head-sub-menu::before {
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #002234 transparent;
}

.head-sub-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 10px 24px;
}

.head-sub-item:not(:last-child) .head-sub-link {
  border-bottom: 1px solid #0f3951;
}

.head-sub-link:hover {
  color: white;
  background-image: linear-gradient(
    180deg,
    #219ebc -40%,
    rgb(33, 158, 188, 0) 100%
  );
}

.head-sub-img {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #219ebc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.rounded-btn {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Lexend';
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid rgba(33, 158, 188, 1);
  color: rgba(33, 158, 188, 1);
  padding: 16px 30px;
  transition: 0.3s all;
}

.rounded-btn:hover {
  background-color: rgb(29 137 163);
  color: white;
  border-color: rgb(29 137 163);
}

.custom-header.is-scrolled .rounded-btn {
  padding-block: 5px;
}

/* HAMBURGER ICON */
.hamburger-btn {
  width: 30px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  flex-shrink: 0;
}

.hamburger-btn span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 4px;
  background: #fff;
  transition: all 0.3s;
  position: relative;
}

.hamburger-btn span + span {
  margin-top: 6px;
}

.ham-active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.ham-not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.ham-active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.ham-not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.ham-active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.ham-not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 13px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 7px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
/* HAMBURGER ICON END */

/* RESPONSIVE */
@media (min-width: 576px) {
  nav.head-menu .head-menu-item:nth-child(4) {
    margin-right: 0;
  }
  nav.head-menu .head-menu-item:nth-child(4)::after {
    content: unset;
  }
}

@media (max-width: 991px) {
  .custom-header-inner .brand-logo {
    margin-right: auto;
  }

  nav.head-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #00141f;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    transform: translateY(-110%);
    transition: 0.3s transform ease-in-out;
    overflow-y: auto;
  }

  .header-menu-open nav.head-menu {
    transform: translateY(0);
  }

  nav.head-menu .head-menu-item:not(:last-child) {
    margin-right: 0;
  }

  nav.head-menu .head-menu-item:not(:last-child)::after {
    content: unset;
  }

  .head-menu-link {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .rounded-btn {
    padding: 10px 15px;
  }

  header.custom-header {
    padding: 20px 0;
  }

  .brand-logo {
    max-width: 150px;
  }
}
/* RESPONSIVE END */

#root > div > div > div > div > div.sectionOneAP > div.sectionOneapproach > div.sectionOneapproachBTM > div.sectionOneapproachBTMR{
  .mvT{
    display: none;
  }
}
.sectionOneAP {


  width: 100vw;
  overflow-x: hidden;
  position: relative;
overflow: hidden;
  // .mvT {
  //   display: none;
  // }

  .bgCircleCon {
    position: absolute;
    right: 0;
    top: 45vh;
    z-index: 10;
  }


  .sectionOneapproach {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: rgb(165, 109, 109);





    .sectionOneapproachBTM {

      display: flex;
      width: 100vw;
      position: absolute;
      align-items: center;
      bottom: 9.375vh;

      .sectionOneapproachBTML {
        width: 50%;
      }

      .sectionOneapproachBTMR {
        width: 60%;
        display: flex;


        h1 {
          margin: 0;
          padding: 0;

          color: rgba(255, 255, 255, 0);
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 72px;
          line-height: 78px;
          letter-spacing: 0.01em;
          text-transform: capitalize;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #219EBC;
          -webkit-background-clip: text;
          background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.603), rgba(53, 47, 47, 0));
        }
      }

      h1 {
        margin: 0;
        padding: 0;
        margin-left: 75px;
        width: 545px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 58px;
        /* or 161% */

        letter-spacing: 0.01em;
        text-transform: capitalize;

        color: #FFFFFF;



        span {
          color: #219EBC;

        }
      }

      h2 {
        width: 520px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */


        color: #FFFFFF;

      }
    }








    .backgroundBuilding {
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translatex(-50%);
        pointer-events: none;
        height: 100vh;
        min-width: 100vw;
        object-fit: cover;
      }
    }
  }

}

@media only screen and (width: 1024px),
screen and (height:1366px) {}

@media only screen and (max-width: 1195px) {}



@media only screen and (max-width: 780px) {
  #root>div>div>div>div>div.sectionOneAP>div.sectionOneapproach>div.sectionOneapproachBTM {
    flex-direction: column;
  }

  #root>div>div>div>div>div.sectionOneAP>div.sectionOneapproach>div.sectionOneapproachBTM>div.sectionOneapproachBTML {
    width: 100%;

    h1 {
      margin-left: 5vw;
    }
  }

  #root>div>div>div>div>div.sectionOneAP>div.sectionOneapproach>div.sectionOneapproachBTM>div.sectionOneapproachBTMR {
    width: 100%;
    display: flex;
    margin-top: 20px;

    img {
      margin-left: 5vw;
    }
  }
}


@media only screen and (max-width: 500px) {
  .sectionOneAP .sectionOneapproach {
    height: 130vh;
  }

  .sectionOneAP .sectionOneapproach .sectionOneapproachBTM {
    bottom: 0;
  }


  .dV {
    display: none;
  }

  #root > div > div > div > div > div.sectionOneAP > div.sectionOneapproach > div.sectionOneapproachBTM > div.sectionOneapproachBTMR{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .mvT{
      display: flex;
      &:last-child{

        margin-top: 20px;
      }
      
    }
  }

  #root>div>div>div>div>div.sectionOneAP>div.sectionOneapproach>div.sectionOneapproachBTM>div.sectionOneapproachBTML {
    width: 100%;

    h1 {

      width: 90vw;
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 58px;
      /* or 161% */

      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #FFFFFF;
    }
  }

}
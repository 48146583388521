html {
    scroll-behavior: smooth;
  }

.TermsAndConditionPageMain {
    display: flex;
    width: 100vw;
    min-height: 100vh;
  
   
    .TermsAndConditionPageMainWrapper {
        display: flex;
        padding-top: 10vh;
    }

    .leftTAC {
        flex: 1;
        display: flex;
        justify-content: center;
        position: relative;
        background-color: #00141F;
       

        // background: rgb(192, 236, 156);
        .leftTACWrapper {
            position: fixed;
            top: 10vh;
            width: 302px;
            height:80vh;
            transition: all 0.8s ease;

            &.active{
                transition: all 0.8s ease;
                top: 0vh;
            }
            a{
                text-decoration: none;
            }
        }


        h4 {

            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #fff;
            opacity: 0.5;
        }

        h3 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                margin: 10px 0;
                list-style: none;
                transition: all 0.5s ease;
                &:hover{
                    transform: scale(1.1);
                }

                a {
                    text-decoration: none;
                    font-family: Archivo;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: capitalize;
                    color: #fff;
                }
            }
        }
    }

    .rightTAC {
        flex: 1;
        // background: rgb(253, 157, 157);
    
        background-color: #00141F;
       

        .TacRComp {
            width: 75%;
            // text-align: justify;

            &:last-child {
                padding-bottom: 5vh;
            }

            &:first-child {}

            h3 {

                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 24px;
                color: #fff;
            }

            span {
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                color: #fff;
            }
        }
    }
}
.section5 {
  width: 100vw;
  padding: 7vh 0;
  // min-height: 70vh;
  background-color: #00141f;
  color: white;
  // padding-bottom: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .top {
    display: none;
    width: 100vw;
    justify-content: space-between;
    padding-top: 10vh;
    height: 40vh;

    .left {
      flex: 1;
      display: flex;

      .leftwrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        // text-align: justify;
        // margin: 20px 10%;
        // align-items: center;


        h2 {
          margin: 0;
          padding: 0px 10%;
          font-family: Archivo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 17px;
          font-weight: 700;
        }

        .leftinner {
          display: flex;
          flex-direction: column;
          width: 300px;
          height: 150px;

          .getStartedCon {
            margin-top: 10px;
            display: flex;
            align-items: center;
            width: fit-content;
            transition: all 0.3s ease;

            .fiar {
              font-size: 17px;
              margin-left: 5px;
              color: #219EBC;
            }

            span {
              font-family: Lexend;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #219EBC;
              transition: all 0.2s ease;

              &:hover {
                cursor: pointer;
              }
            }

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .right {
      flex: 1;

      // width: 50vw;
      .imgConClients {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        align-items: center;
        justify-content: center;
      }


      img {
        width: 150px;
        max-width: 650px;
        max-height: 70px;
      }
    }
  }

  .bottom {
    display: flex;
    width: 90vw;
    // height: 50vh;
    justify-content: space-between;
    // padding-top: 5%;
    position: relative;
    margin-top: 7vh;

    .left {
      flex: 1;

      //   border: 1px solid green;
      .imgCon {
        display: flex;
        align-items: center;
        justify-content: center;

        .iL {
          width: 270px;
          height: 576px;
          padding-right: 3%;
          transition: all 0.7s ease;

          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }

        .iR {
          width: 231.56px;
          height: 495px;
          // padding-right: 3%;
          transition: all 0.5s ease;

          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      //   border: 1px solid red;
      .rightWrapper {
        // text-align: justify;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .getStartedCon {
          margin-top: 10px;
          display: flex;
          align-items: center;
          width: fit-content;
          transition: all 0.3s ease;

          .fiar {
            font-size: 17px;
            margin-left: 5px;
            color: #219EBC;
          }

          span {
            font-family: Lexend;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #219EBC;
            transition: all 0.2s ease;

            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            transform: scale(1.3);
          }
        }

        .imgCon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 110px;
          margin-bottom: 20px;

          span {
            padding: 0;
            margin: 0;
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
          }
        }

        span {
          font-family: Lexend;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }

    .imgconBottom {
      position: absolute;
      bottom: 0;
      right: -10vw;
      height: 318px;
      width: 769px;
      pointer-events: none;
      //   opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 1195px) {
  .section5 {
    padding-top: 0;

    .bottom {
      .left {
        .imgCon {
          padding-bottom: 30px;

          .iL {
            width: 23vw;
            height: auto;
            padding-left: 5%;
            padding-right: 3%;
          }

          .iR {
            width: 20vw;
            height: auto;
          }
        }
      }

      .right {
        flex: 1;

        //   border: 1px solid red;
        .rightWrapper {
          width: 70%;

          .imgCon {
            img {
              width: 70px;
            }
          }
        }
      }

      .imgconBottom {
        position: absolute;
        bottom: 0;
        right: 0;
        height: auto;
        width: 769px;
        //   opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .section5 {

    // min-height: 40vh;
    .bottom {
      .left {
        .imgCon {
          padding-bottom: 30px;

          .iL {
            width: 20vw;

          }

          .iR {
            width: 17vw;

          }
        }
      }

      .right {
        flex: 1;

        //   border: 1px solid red;
        .rightWrapper {
          width: 90%;

          .imgCon {
            img {
              width: 70px;
            }
          }
        }
      }

      .imgconBottom {
        position: absolute;
        bottom: 0;
        right: 0;
        height: auto;
        width: 769px;
        //   opacity: 0.5;
      }
    }
  }
}



@media only screen and (max-width: 500px) {

  .section5 {
    .top {

      width: 100vw;

      padding-top: 0;
      height: fit-content;
      flex-direction: column;

      .left {
        z-index: 10;
        width: 100%;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .leftwrapper {
          flex-direction: column;
          width: 80%;

          h2 {
            padding: 3vh 0;
          }

          .leftinner {


            .getStartedCon {


              .fiar {}

              span {}


            }
          }
        }
      }

      .right {
        padding-top: 5vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90vw;
        }
      }
    }

    .bottom {
      flex-direction: column;

      .left {
        padding-top: 5vh;

        .imgCon {
          padding-bottom: 3vh;
          position: relative;
          right: -40px;

          .iL {
            height: 460px;
            width: auto;


          }

          .iR {
            height: 395px;
            width: auto;

          }
        }
      }

      .right {
        flex: 1;

        //   border: 1px solid red;
        .rightWrapper {
          width: 90%;

          .imgCon {
            img {
              width: 90px;
              padding-bottom: 10px;
            }
          }

          .getStartedCon {
            padding-bottom: 5vh;
          }
        }
      }

      .imgconBottom {
        display: none;
        //   opacity: 0.5;
      }
    }
  }
}
.sectionOneCL {
  // height: 100vh;
  width: 100%;

  padding-bottom: 100px;

  .sectionOneCLW {
    padding-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .CardSection {
      display: flex;

    }
  }

  h3 {
    width: 630px;

    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    /* identical to box height, or 167% */

    text-align: center;

    color: #FFFFFF;
    margin-bottom: 30px;
  }
}


@media only screen and (max-width: 1195px) {



  .sectionOneCL .sectionOneCLW .CardSection {
    transform: scale(0.9);
    margin-bottom: -50px;
  }

}

@media only screen and (max-width: 790px) {

  .sectionOneCL .sectionOneCLW .CardSection {
    transform: scale(1);
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 90px;
  }

  .sectionOneCL .sectionOneCLW {
    margin-bottom: 40px;

  }
}

@media only screen and (max-width: 500px) {
  .sectionOneCL .sectionOneCLW {
    margin-bottom: 80px;
    font-size: 24px;
    line-height: 40px;
  }

  .sectionOneCL .sectionOneCLW .CardSection {
    grid-template-columns: auto;
    grid-row-gap: 90px;
  }

  .sectionOneCL h3 {
    width: 300px;
  }
}

.SecOneCLNew {
  margin-top: 200px;

  padding-bottom: 100px;
  position: relative;

  .SecOneCLNew-MT {
    position: relative;
  }

  .topBtmSvg {
    position: absolute;
    bottom: 100px;
    left: -80px;
    pointer-events: none;

    svg {}
  }

  .rightSvg {
    position: absolute;
    top: -190px;
    right: -80px;
    pointer-events: none;
  }

  .SecOneCLNew-MB {
    background-color: #000E14;
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 151px;

    h1 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      text-transform: capitalize;

      color: #FFFFFF;
    }

    h2 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */

      max-width: 735px;
      color: #FFFFFF;
    }
  }

  .carrerCardNew {
    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 96px;
      margin: 0;
      padding: 0;
      margin-bottom: -35px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      background: -webkit-linear-gradient(-86deg, #219ebc 40%, #0e4463 55%, #0b3852 31%);
      -webkit-background-clip: text;
      -webkit-text-stroke: 3px transparent;
      color: #00141f;
      position: relative;
      // opacity: 0.2;
    }

    .outerBox {
      position: relative;
      z-index: 10;
      width: 220px;
      height: 316px;
      border-radius: 20px;
      background: linear-gradient(0.23deg, #219dbc27 23.64%, #219EBC 116.98%);
      padding: 2px;
      padding-bottom: 0;

      .innerBox {
        width: 100%;
        height: 100%;
        background: #000E14;
        border-radius: 20px;
        padding: 39px 23px;

        svg {
          margin-bottom: 20px
        }

        h2 {

          font-family: 'Archivo';
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 26px;
          /* or 159% */

          text-transform: capitalize;

          color: #00A5CC;
          width: 73px;
        }

        h3 {

          width: 170px;
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          /* or 220% */


          color: #FFFFFF;

        }
      }
    }
  }

  .scDown {
    position: absolute;
    bottom: -100px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 10px;
    }
  }

  .divd {
    margin-top: 30px;
  }

  .SecOneCLNew-btm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .cone {
      margin-left: 300px;
      position: relative;
      top: 55px;
    }

    .cthree {
      // margin-left: 300px;
      position: relative;
      top: 55px;
    }

  }

}


@media only screen and (max-width: 1195px) {

  .SecOneCLNew .SecOneCLNew-btm .cone {
    margin-left: 100px;
  }

}

@media only screen and (max-width: 995px) {
  .SecOneCLNew .SecOneCLNew-MT .SecOneCLNew-top {
    position: relative;
    z-index: 5;
  }

  .SecOneCLNew .SecOneCLNew-MB {
    flex-direction: column;
    padding: 20px;

    svg {
      margin-top: 20px;
    }
  }

  .SecOneCLNew .SecOneCLNew-btm {
    flex-direction: column;

    .cone {
      top: auto;
      margin: 20px 0;
    }

    .cthree {
      top: auto;
      margin: 20px 0;
    }
  }

  .SecOneCLNew .SecOneCLNew-btm .cone {
    margin-left: 0px;
  }

  .SecOneCLNew .scDown {
    margin-left: 20px;
  }

  .SecOneCLNew .SecOneCLNew-MT {
    .SecOneCLNew-top {
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px){
  .SecOneCLNew-btm {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center !important;
    .ConeItem{
      width: 40%;
    }
  }
}
@media screen and (max-width: 1200px){
  .SecOneCLNew{
    padding: 20px;
  }
}
.features-section,
.solutions-section {
  background-image: url("./../../assets/images/backgrounds/hand-bg.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 700px;
  min-height: 750px;
}

.intro-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
}

.lined-subtitle {
  line-height: 1.8;
  max-width: 70%;
  position: relative;
  padding-left: 80px;
}

.lined-subtitle::before {
  content: "";
  left: 0;
  top: 15px;
  height: 2px;
  width: 40px;
  background-image: radial-gradient(
    circle,
    rgba(33, 158, 188, 1) 0%,
    rgba(33, 158, 188, 0) 100%
  );
  margin-right: 0px;
  position: absolute;
}

.accordion-item:not(:first-of-type) {
  border-top: 0 !important;
}

.accordion-item:first-of-type,
.accordion-item:last-of-type {
  border-radius: 0;
}

.accordion-button::after {
  content: unset;
}

.accordion-button:focus,
.accordion-button:not(.collapsed) {
  border-color: transparent;
  box-shadow: unset;
}

.feat-acc-wrap .accordion-item {
  background-color: transparent;
  border: solid rgba(255, 255, 255, 0.1);
  border-width: 2px 0 2px 0;
  color: white;
  padding-block: 40px;
}

.feat-acc-wrap .accordion-button {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  align-items: flex-start;
}

.feat-acc-wrap .accordion-button:not(.collapsed) {
  color: #00a5cc;
}

.feat-acc-wrap .accordion-body {
  padding-inline: 3.5rem;
  line-height: 1.8;
}

@media screen and (max-width: 1599px) {
  .features-section,
  .solutions-section {
    background-size: 550px;
    min-height: 700px;
  }
}

@media screen and (max-width: 1439px) {
  .features-section,
  .solutions-section {
    background-size: 450px;
    background-position: center left;
    min-height: 800px;
  }
}

@media screen and (max-width: 1199px) {
  .lined-subtitle {
    max-width: 100%;
  }

  .features-section,
  .solutions-section {
    background-size: 350px;
  }
}

@media screen and (max-width: 991px) {
  .feat-hidden-bg {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
  }

  .feat-hidden-bg img {
    width: 100%;
  }

  .features-section {
    min-height: auto;
    background-image: unset;
  }

  .intro-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .solutions-section {
    background-image: none;
  }
}

@media screen and (max-width: 767px) {
  .solutions-section {
    min-height: auto;
  }

  .solutions-section {
    padding-inline: 15px !important;
  }
}
@media screen and (max-width: 500px) {
  .solutions-section .solutions-inner .sols-intro {
    text-align: left;
  }
  .solutions-section {
    .sols-tab-wrap {
      .sols-tab-content {
        .tab-pane {
          position: relative;
          margin-top: 20px;
          .sol-number {
            position: absolute;
            z-index: 1;
            bottom: -50px;
            right: 0;
            text-align: end;
            font-size: 20vh;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

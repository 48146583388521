.SecTwoNDMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .titleRR {
    border-top: 1.5px solid grey;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    text-align: left;
    padding-top: 30px;
    width: 90%;
  }
  .SecTwoNDMainCon {
    margin-top: 0.5vh;
    border-top: 1px solid black;
    padding-top: 2vh;
    display: flex;

    width: 90%;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 770px) {
  .SecTwoNDMain {
    display: none;
  }
}

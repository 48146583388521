.HowDigitrust-Main {
  display: flex;
  flex-direction: column;

  .HowDigitrust-T {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .Tcontainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 40px;
        /* identical to box height, or 111% */

        text-transform: capitalize;

        color: #ffffff;
      }

      h2 {
        width: 100%;
        max-width: 641px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        /* or 225% */

        text-align: center;

        color: #ffffff;
      }
    }
  }

  .HowDigitrust-B {
    margin-top: 36px;
    margin-bottom: 89px;
    display: flex;
    // align-items: center;
    justify-content: center;
  }
}

.DBHolder {
  &:hover {
    .Cwraper {
      padding: 0;
    }

    .circle {
      border: 1px solid #219ebc;
    }

    .DigiTrustBar {
      position: relative;
      z-index: 10 !important;
      flex-direction: column-reverse;
      overflow: visible;
    }

    p {
      display: flex !important;
    }
  }

  &.one {
    .DigiTrustBar {
      width: 313px;
    }

    h1 {
      position: relative;
      right: -40px;
    }

    .Cwraper {
      position: relative;
      right: -40px;
    }
  }

  &.two {
    .DigiTrustBar {
      // width: 313px;
      // border: none !important;
      padding-top: 20px;
      box-sizing: content-box;
      // position: relative;
      // right: -40px;
    }
  }

  &.four {
    .DigiTrustBar {
      width: 313px;
      border: none !important;
      padding-top: 20px;
      box-sizing: content-box;
      // position: relative;
      // right: -40px;
    }

    h1 {
      // margin-top: 400px;
      position: relative;
      left: -40px;
    }

    .Cwraper {
      position: relative;
      left: -40px;
    }
  }
}

.DigiTrustBar {
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border-left: #219EBC solid 1px;
  border-right: #219ebc solid 1px;
  width: fit-content;
  width: 213px;
  overflow: hidden;
  // transition: all 0.3s ease-in-out;

  h1 {
    font-family: Archivo;
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;

    // color: rgba(0, 0, 0, 0);

    letter-spacing: 0.02em;
    text-transform: capitalize;
    background: -webkit-linear-gradient(
      -86deg,
      #219ebc 40%,
      #0e4463 55%,
      #0b3852 31%
    );
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
    color: #00141f;
  }

  .Cwraper {
    position: relative;
    z-index: 9;
    width: 335px;
    height: 335px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 3px;
    box-sizing: content-box;
    background: linear-gradient(180deg, #219ebc 0%, rgba(33, 158, 188, 0) 100%);

    .circle {
      width: 335px;
      height: 335px;
      border-radius: 50%;
      background: #002133;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .innerC {
        width: 100px;
        height: 100px;
        border: 1px solid #219ebc;
        border-radius: 50%;
        background: #002133;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        width: 205px;
        margin-top: 16px;
        font-family: "Archivo";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: 0.035em;
        text-transform: capitalize;

        color: #ffffff;
      }

      p {
        width: 228px;

        display: none;
        margin-top: 16px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        /* or 200% */

        text-align: center;

        color: #ffffff;
      }
    }
  }
}

.digiHero {
  left: 0 !important;
  top: -120px !important;
}
.securex{
  .SecuritiesOne-Main.WL .digiHero{
height: auto;
  }
}

.SecuritiesOne-Main {
  &.DigiTrust {
    .srbsvg {
      left: 152px !important;
      top: -370px !important;
    }

    .svgkey {
      // display: none !important;
      position: relative;
      left: -200px;
    }

    .secTitle {
      h1 {
        width: 714px !important;
        display: flex;
        flex-direction: column;

        span {
          margin-left: 0;
        }
      }
    }
  }
}

.DigiTrustTaxes-main {
  width: 1440px;
  display: flex;
  padding-top: 99px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: rgba(0, 33, 51, 0.5);
  overflow: hidden;

  .Blur1 {
    position: absolute;
    // top: 50%;
    pointer-events: none;
    left: -400px;
  }

  .Blur2 {
    position: absolute;
    top: -400px;
    pointer-events: none;
    left: 600px;
  }

  .backI {
    position: absolute;
    top: 0;
    left: 0px;
    pointer-events: none;
  }

  .DigiTrustTaxes-T {
    h1 {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      text-transform: capitalize;

      color: #ffffff;
    }
  }

  .DigiTrustTaxes-B {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: red;
    width: 100%;
    padding: 0 6%;
    padding-bottom: 300px;
    flex-wrap: wrap;

    .TaxComp {
      width: 300px;
    }

    .taxCircle {
      border: 1px solid #219ebc;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin-top: 24px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 52px;
      /* identical to box height, or 219% */

      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #ffffff;
    }

    h3 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */

      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 1000px) and (min-width: 600px){
  .DBHolder.one .DigiTrustBar, .DBHolder.four .DigiTrustBar{
    width: 250px;
  }
  .DBHolder.four:hover .Cwraper{
    left: -100px;
  }
  .DBHolder.one:hover .Cwraper{
    right: -100px;
  }
}

@media only screen and (max-width: 330px) {
  .VerticalTabsMain {
    .VerticalTabs-r {
      .VerticalTabs-Component {
        .VerticalTabs-Component-t {
          margin-bottom: 0px !important;
          h1 {
            font-size: 18px;
            margin-left: 0px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  //Test
  .VerticalTabsMain {
    display: block;
    .VerticalTabs-r {
      display: block;
      .VerticalTabs-Component {
        height: 100px;
        width: auto !important;
        margin-bottom: 20px;
        .VerticalTabs-Component-t {
          h1 {
            transform: unset;
            min-width: auto;
            position: unset;
          }
        }
        &.active {
          height: auto;
        }
      }
    }
    .VerticalTabs-l {
      width: auto;
      height: auto;
      svg {
        opacity: 0.3;
        left: 0;
        bottom: -40%;
      }
    }
  }

  //Original

  // .DigiTrustTaxes-main {
  //   width: 100%;
  // }

  // .VerticalTabsMain .VerticalTabs-r .VerticalTabs-Component {
  //   height: auto !important;
  // }

  // .VerticalTabsMain .VerticalTabs-r .VerticalTabs-Component .VerticalTabs-Component-b p {
  //   opacity: 1 !important;
  // }

  // .VerticalTabsMain {
  //   flex-direction: column;
  // }

  // .VerticalTabsMain .VerticalTabs-l {
  //   width: 100%;
  //   // align-items: center;
  //   // justify-content: center;
  // }

  // .VerticalTabsMain .VerticalTabs-r {
  //   margin-top: -400px;
  //   flex-direction: column;
  //   width: 100%;
  //   align-items: center;
  //   justify-content: center;

  // }

  // .VerticalTabsMain .VerticalTabs-r .VerticalTabs-Component .VerticalTabs-Component-t h1 {
  //   transform: rotate(0deg) !important;
  //   top: 0px !important;
  //   margin-left: 10px;
  //   color: #00A5CC !important;
  //   margin-left: 5px !important;
  // }

  // .VerticalTabsMain .VerticalTabs-r .VerticalTabs-Component {
  //   width: 100% !important;
  //   margin: 20px 0;

  //   .VerticalTabs-Component-t {
  //     flex-direction: row !important;
  //   }

  // }
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
  .VerticalTabsMain {
    .VerticalTabs-r {
      .VerticalTabs-Component {
        height: 200px;
      }
    }
  }
}

.SecuritiesOne-Main {
  &.WL {
    .srbsvg {
      left: -72px !important;
      top: -90px !important;
    }

    .scrollInd {
      margin-top: 300px;
    }

    .digiHero {
      left: -181px !important;
      top: -200px !important;
      width: 900px;
    height: 900px;
    }

    .svgkey {
      // display: none !important;
      position: relative;
      left: -200px;
    }

    .secTitle {
      h1 {
        width: 522px !important;
        // display: flex;
        // flex-direction: column;

        span {
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .SecuritiesOne-Main.WL .secTitle h1 {
    width: 100% !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-L svg {
    margin: 20px !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-L h1 {
    font-size: 24px !important;
    line-height: 24px !important;
    width: 90% !important;

    span {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }

  .HowDigitrust-Main .HowDigitrust-T .Tcontainer {
    width: 90%;
    align-items: flex-start;

    h1 {
      text-align: center;
    }

    h2 {
      max-width: auto !important;
    }
  }

  .DigiTrustBar .Cwraper .circle p {
    display: flex;
  }

  .DBHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;

    &:hover {
      .DigiTrustBar {
        flex-direction: column;
      }
    }

    &.one {
      .DigiTrustBar {
        // width: 313px;
      }

      h1 {
        position: relative;
        right: 0px;
      }

      .Cwraper {
        position: relative;
        right: 0px;
      }
    }

    &.two {
      .DigiTrustBar {
        // width: 313px;
        // border: none !important;
        padding-top: 20px;
        box-sizing: content-box;
        // position: relative;
        // right: -40px;
      }
    }

    &.four {
      .DigiTrustBar {
        // width: 313px;
        border: none !important;
        padding-top: 20px;
        box-sizing: content-box;
        // position: relative;
        // right: -40px;
      }

      h1 {
        // margin-top: 400px;
        position: relative;
        left: 0px;
      }

      .Cwraper {
        position: relative;
        left: 0px;
      }
    }
  }

  .DigiTrustBar {
    border: none !important;
    overflow: visible;

    h1 {
    }

    .Cwraper {
      .circle {
        .innerC {
        }

        h2 {
        }

        p {
        }
      }
    }
  }

  .HowDigitrust-Main .HowDigitrust-B {
    flex-direction: column;
  }

  .svgkey {
    display: none !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-R .digiHero {
    left: 0 !important;
  }

  .SecuritiesOne-Main.DigiTrust .secTitle h1 {
    width: 90% !important;
    font-size: 28px !important;
    line-height: 34px !important;

    span {
      font-size: 28px !important;
      line-height: 34px !important;
    }
  }

  .SecuritiesOne-Main .SecuritiesOne-L svg {
    // margin: 20px 20px !important;
  }

  .SecuritiesOne-Main.DigiTrust .svgkey {
    margin-left: -300px;
    left: 0;
  }
}

@media screen and (max-width: 576px) {
  .HowDigitrust-Main .HowDigitrust-T .Tcontainer h2 {
    // max-width: 300px;
  }
  #root
    > div
    > div
    > div.mainCompWhole
    > div
    > div.VerticalTabsMain
    > div.VerticalTabs-r
    > div
    > div.VerticalTabs-Component-t
    > svg {
    display: none;
  }
}

.SectionOneND {
    min-height: 100vh;

    .NDTitle {
        padding: 0vh 5vw;
        padding-top: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
            text-transform: capitalize;
            color: #fff;
        }
    }

    .NDcontent {
        padding: 1vh 25vw;
        display: flex;
        align-items: center;
        justify-content: center;
        // text-align: justify;

        figure {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 515px;
                // border-radius: 8px;
            }

            figcaption {
                display: none;
            }
        }

        h1{
            color: #fff;
        }
        h2{
            color: #fff;
        }
        h5{
            color: #fff;
        }
        h6{
            color: #fff;
        }

        h3{
            margin: 0;
            padding: 0;
            text-align: left;
            color: #fff;
        }
        h4{
            color: #fff;
        }
        p {
            
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            color: #fff;
            a{
                color: #3f92be;
            }
        }
        li{
            color: #fff;
        }
    }
}


@media only screen and (max-width: 1250px) {
    .SectionOneND .NDTitle{
        padding: 150px 15vw 0 15vw;
    }
    .SectionOneND .NDcontent{
        padding: 1vh 15vw;
    }
    .SectionOneND .NDcontent figure img{
        height: 415px;
    }
   
}

@media only screen and (max-width: 800px) {
    // .SectionOneND .NDTitle{
    //     padding: 150px 15vw 0 15vw;
    // }
    .SectionOneND .NDcontent{
        padding: 1vh 15vw;
    }
    .SectionOneND .NDcontent figure img{
        height: 315px;
    }
    .SectionOneND .NDTitle h2{
        // text-align: justify;
    }
}

@media only screen and (max-width: 500px) {
    .SectionOneND .NDTitle{
        padding: 0vh 5vw;
        padding-top: 10vh;
    }
    .SectionOneND .NDcontent{
        padding: 1vh 5vw;
    }
    .SectionOneND .NDcontent figure img{
        height: 215px;
    }
    .SectionOneND .NDTitle h2{
        // text-align: justify;
    }
}
.NSecOneWhatMain {
  position: relative;
  padding-bottom: 5vh;
  .mobC {
    display: none;
  }
  .mobCoreFundamanetals {
    display: none;
  }
  .NSecOneWhatB .wbrm {
    display: none;
  }

  .bgc {
    right: -530px;
    // top: -26vh;
    top: -29vh;
    position: absolute;
    height: 170.375vh;
  }
  .imageCOnBgWM {
    right: 10vw;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    .imageCOnBgWMWrapper {
      position: relative;
      //   background-color: #fff;
    }
  }
  .bgWM {
    height: 62.5vh;

    // &::after{
    //     content: '';
    //     position: absolute;

    // }
  }

  .tcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 5vh;
    left: 111px;
    font-weight: 500;

    i {
      color: #fff;
      margin-left: 10px;
    }

    h4 {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
    }
  }

  .NSecOneWhatT {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .RippleHolder {
      //   position: absolute;
      //   // right: -530px;
      //   right: -430px;
      //   // top: -26vh;
      //   top: -350px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //   width: 1768px;
      //   height: 1770px;

      background-color: rgb(180, 35, 35);
      display: flex;
      // display: none;
      align-items: center;
      justify-content: center;
      // background-color: rgb(240, 135, 135);
    }

    .rippleCon {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      .rippleCB {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0;
      }
      .rCircle {
        position: absolute;
        box-sizing: border-box;
        border: 2px solid rgba(255, 255, 255, 0.637);
        background-color: #00141f;
        opacity: 0;
        border-radius: 50%;
        animation: rippleAnimation 7s linear infinite;
        // animation-delay: calc(0.5s * var(--i));
        transform: scale(0.85);
      }
      .s1 {
        animation-delay: 1s;
      }

      .s2 {
        animation-delay: 2s;
      }

      .s3 {
        animation-delay: 3s;
      }

      .s4 {
        animation-delay: 4s;
      }

      .s5 {
        animation-delay: 5s;
      }

      .s6 {
        animation-delay: 3s;
      }

      .s7 {
        animation-delay: 3.5s;
      }

      .s8 {
        animation-delay: 4s;
      }

      @keyframes rippleAnimation {
        0% {
          width: 568px;
          height: 568px;
          opacity: 0.3;
        }

        30% {
        }
        50% {
        }
        70% {
          opacity: 0.2;
        }
        100% {
          width: 1768px;
          height: 1770px;
        //   height: 1870px;
          opacity: 0;
        }
      }
    }

    h1 {
      margin-left: 111px;
      width: 602px;
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 72px;
      /* or 150% */

      letter-spacing: 0.01em;

      color: #ffffff;
    }

    p {
      margin-left: 111px;
      width: 410px;
      font-family: Lexend;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #ffffff;
    }
  }

  .NSecOneWhatB {
    display: flex;
    align-items: center;
    justify-content: center;

    .cAimgCOn {
      position: relative;

      .cAimgCOnLWin1 {
        height: 2px;
        width: 0px;

        background-color: #219ebc;
        top: -10px;
        right: 205px;
        transform: rotate(270deg);
        position: absolute;
        transform-origin: right;
        border-radius: 15px;
      }

      .cAimgCOnLWin2 {
        height: 2px;
        width: 0px;
        background-color: #219ebc;
        top: 190px;
        right: 205px;
        transform: rotate(183.5deg);
        position: absolute;
        transform-origin: right;
        border-radius: 15px;
      }

      .cAimgCOnLWin3 {
        height: 2px;
        width: 0px;
        background-color: #219ebc;
        top: 199px;
        right: 39px;
        transform: rotate(90deg);
        position: absolute;
        transform-origin: right;
        border-radius: 15px;
      }

      .cAimgCOnL1 {
        height: 2px;
        width: 0px;
        background-color: #219ebc;
        top: 302px;
        right: 390px;
        transform: rotate(341deg);
        position: absolute;
        transform-origin: right;
      }

      .cAimgCOnL2 {
        height: 2px;
        width: 0px;
        background-color: #219ebc;

        top: 346px;
        left: 221px;
        transform: rotate(15deg);
        position: absolute;
        transform-origin: left;
      }

      .cAimgCOnL3 {
        height: 2px;

        width: 0px;
        background-color: #219ebc;
        top: 412px;
        left: 466px;
        transform: rotate(-31.5deg);
        position: absolute;
        transform-origin: left;
        // transition: all 0.5s ease;
      }

      .cAimgCOnL4 {
        height: 2px;
        width: 0;
        background-color: #219ebc;
        top: 269px;
        left: 700px;
        transform: rotate(186deg);
        position: absolute;
        transform-origin: left;
        // transition: all 0.5s ease;
      }
    }

    .NSecOneWhatBWrapper {
      width: 90%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .customAccordianCon {
        width: 550px;
        // background-color: rgba(194, 82, 82, 0.651);

        .tText {
          h1 {
            font-family: Archivo;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 40px;
            /* identical to box height, or 167% */

            text-transform: capitalize;

            color: #ffffff;
          }

          p {
            width: 520px;

            font-family: Lexend;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */

            color: #7f898f;
          }
        }

        .aMain {
          display: flex;
          margin-top: 30px;

          .aNumberCon {
            display: flex;
            flex-direction: column;
            position: relative;
          }

          .acordianCardMScroll {
            margin-left: 59px;
            height: 395px;
            width: 4px;
            background-color: #192b35;
            margin-right: 20px;
            border-radius: 8px;
            position: absolute;

            transition: all 0.5s ease;

            &.one {
              .inScroll {
                top: 0px;
              }
            }

            &.two {
              .inScroll {
                top: 85px;
              }
            }

            &.three {
              .inScroll {
                top: 167px;
              }
            }

            &.four {
              .inScroll {
                top: 255px;
              }
            }

            .inScroll {
              height: 139px;
              width: 4px;
              border-radius: 8px;
              background-color: #219ebc;
              position: relative;
              transition: all 0.5s ease;
            }
          }

          .acordianCardRCON {
            display: flex;
            align-items: center;
            flex-direction: column;

            transition: all 0.5s ease-in-out;

            &:hover {
              cursor: pointer;
            }

            .acordianCardR {
              position: relative;
              transition: all 0.5s ease-in-out;
              height: 80px;
              // background-color: rgb(116, 51, 51);
              // border: 1px solid rgb(0, 0, 0);
              width: 400px;
              display: flex;
              align-items: center;

              &.active {
                height: 140px;

                h3 {
                  top: 20%;
                  color: #219ebc;
                }

                p {
                  bottom: -0px;
                  color: #ffffff;
                }

                .acordianNum {
                  top: 10%;
                  transform: translateY(0%);
                  border: 1px solid #219ebc;

                  h4 {
                    color: #219ebc;
                  }
                }
              }

              h3 {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 28px;
                /* identical to box height, or 175% */

                text-transform: capitalize;
                color: #ffffff;
                transition: all 0.5s ease-in-out;
              }

              .acordianNum {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background: #00141f;
                border: 1px solid #4c5a62;
                border-radius: 50%;
                margin-right: 37px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                transition: all 0.5s ease;

                h4 {
                  font-family: Lexend;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  /* identical to box height */

                  text-align: center;
                  transition: all 0.5s ease-in-out;
                  color: #4c5a62;
                }
              }

              .aTcon {
                margin-left: 82px;
              }

              .pCon {
                position: absolute;
                top: 25%;
                // bottom: -20px;
                overflow: hidden;
              }

              p {
                width: 443px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */
                transition: all 0.5s ease-in-out;
                // background-color: rgb(82, 212, 31);
                position: relative;
                bottom: -100px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1195px) {
  .NSecOneWhatMain .NSecOneWhatT h1 {
    width: 500px;
    font-size: 40px;
    line-height: 55px;
  }

  .NSecOneWhatMain .NSecOneWhatT p {
    width: 360px;

    font-size: 13px;
    line-height: 23px;
  }

  .NSecOneWhatMain .bgWM {
    height: 53.5vh;
  }

  .NSecOneWhatMain .bgc {
    top: -23vh;

    height: 157.375vh;
  }
}

@media only screen and (max-width: 770px) {
  .NSecOneWhatMain .bgc {
    display: none;
  }

  .NSecOneWhatMain .NSecOneWhatT {
    height: auto;
    padding-bottom: 60px;
  }
  .NSecOneWhatMain .imageCOnBgWM{
    top: 7%; 
    transform: translateY(0%);
  }
  #root
    > div
    > div
    > div
    > div
    > div.NSecOneWhatMain
    > div.NSecOneWhatT
    > div.bgWMC {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    margin-top: 14vh;
    height: 50vh;
  }

  .NSecOneWhatMain .bgWM {
    position: relative;
    height: auto;
    transform: translateY(0%);
    right: auto;
    height: 525px;
    width: 500px;
    left: -15px;
  }

  .NSecOneWhatMain .NSecOneWhatT h1 {
    margin-left: 10vw;
    width: 582px;
    font-size: 35px;
    margin-top: 40px;
  }

  .NSecOneWhatMain .NSecOneWhatT p {
    margin-left: 10vw;
    width: 520px;
    font-size: 14px;
  }

  #root
    > div
    > div
    > div
    > div
    > div.NSecOneWhatMain
    > div.NSecOneWhatT
    > div.tcon {
    position: initial;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  #root
    > div
    > div
    > div
    > div
    > div.NSecOneWhatMain
    > div.NSecOneWhatT
    > div.bgWMC {
    margin-top: 12vh;
    height: 50vh;
  }
  .NSecOneWhatMain .imageCOnBgWM {
    top: 10%;
    transform: translateY(0%);
  }
  .NSecOneWhatMain .bgWM {
    height: 325px;
    width: 300px;
    // margin-top: 8vh;
  }

  .NSecOneWhatMain .NSecOneWhatT h1 {
    margin-top: 20px;
    width: 90vw;

    font-size: 28px;
    line-height: 50px;
  }

  .NSecOneWhatMain .NSecOneWhatT p {
    width: 90vw;

    font-size: 14px;
  }
}

@media only screen and (max-width: 1195px) {
  .NSecOneWhatMain .NSecOneWhatB .cAimgCOn {
    transform: scale(0.7);
    position: relative;
    left: -50px;
  }
}

@media only screen and (max-width: 770px) {
  .NSecOneWhatMain .NSecOneWhatB .NSecOneWhatBWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .NSecOneWhatMain .NSecOneWhatB .cAimgCOn {
    transform: scale(0.8);
    position: relative;
    left: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .NSecOneWhatMain
    .NSecOneWhatB
    .NSecOneWhatBWrapper
    .customAccordianCon
    .aMain
    .acordianCardRCON {
    display: none;
  }

  .NSecOneWhatMain .NSecOneWhatB .NSecOneWhatBWrapper .customAccordianCon {
    width: 90%;
  }

  .NSecOneWhatMain
    .NSecOneWhatB
    .NSecOneWhatBWrapper
    .customAccordianCon
    .tText {
    p {
      width: 100%;
    }
  }

  .NSecOneWhatMain
    .NSecOneWhatB
    .NSecOneWhatBWrapper
    .customAccordianCon
    .aMain {
    display: none;
  }

  .NSecOneWhatMain .NSecOneWhatB .cAimgCOn {
    transform: scale(0.4);
    display: none;
  }

  .NSecOneWhatMain .NSecOneWhatB .wbrm {
    display: flex !important;
  }

  // CoreFundamental Card
  .NSecOneWhatMain .bmCFCon {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
    margin-top: 30px;

    &.one {
      .posiS {
        .posiSi {
          left: 0;
        }
      }
    }

    &.two {
      .posiS {
        .posiSi {
          left: 25px;
        }
      }
    }

    &.three {
      .posiS {
        .posiSi {
          left: 50px;
        }
      }
    }

    &.four {
      .posiS {
        .posiSi {
          left: 76px;
        }
      }
    }

    .posiS {
      width: 153px;
      height: 6px;
      border-radius: 13px;
      background-color: #023047;

      .posiSi {
        width: 76.5px;
        height: 6px;
        border-radius: 13px;
        background-color: #219ebc;
        position: relative;
        left: 0;
        transition: all 0.5s ease;
      }
    }
  }

  .NSecOneWhatMain .mobCoreFundamanetals {
    height: 210px;
    display: flex;
    // background-color: rgba(158, 54, 54, 0.623);
    margin-top: 40px;
    width: 1200px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    left: 140px;
    transition: all 0.5s ease;

    &.one {
      left: 450px;
    }

    &.two {
      left: 140px;
    }

    &.three {
      left: -180px;
    }

    &.four {
      left: -500px;
    }

    .mcfC {
      display: flex;
    }

    .mcfCR {
      min-height: 210px;
      width: 300px;
      display: flex;
      flex-direction: column;

      justify-content: center;
    }

    .mcfLINE {
      margin-right: 13px;
      width: 4px;
      height: 210px;

      border-radius: 13px;
      background-color: #219ebc;
    }

    .numCo {
      margin-bottom: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      background: #00141f;
      border: 1px solid #219ebc;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: Lexend;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      text-align: center;

      color: #219ebc;
    }

    .headT {
      margin-bottom: 10px;
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-transform: capitalize;

      color: #219ebc;
    }

    .paraT {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #ffffff;
    }
  }
}

// @media only screen and (max-width: 1445px) {
//   .NSecOneWhatMain .NSecOneWhatT .RippleHolder {
//     right: -530px;
//     top: -42vh;
//   }
// }

// @media only screen and (max-width: 1190px) {
//   .NSecOneWhatMain .NSecOneWhatT .RippleHolder {
//     right: -580px;
//     top: -42vh;
//   }
// }

// @media only screen and (max-width: 770px) {
//   .NSecOneWhatMain .NSecOneWhatT .RippleHolder {
//     display: none;
//   }
// }

.SectionSevenLP {

    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #002133;

    .SectionSevenLPWrapperM {
        display: none;
    }

    .SectionSevenLPWrapper {

        display: flex;
        width: 95%;
        height: 100%;
        background-color: #002133;
        justify-content: space-between;

        a {
            text-decoration: none;
        }

        .getStartedCon {
            margin-top: 10px;
            display: flex;
            align-items: center;
            width: fit-content;
            transition: all 0.3s ease;

            .fiar {
                font-size: 17px;
                margin-left: 5px;
                color: #fb8500;
            }

            span {
                text-decoration: none;
                font-family: Lexend;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #fb8500;
                transition: all 0.2s ease;

                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                transform: scale(1.2);
            }
        }

        .cardSseven {
            width: clamp(320px, 28.472vw, 500px);
            height: 66.8vh;

            background: #002133;
            border: 1px solid #FFFFFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;


            &:hover {
                cursor: pointer;
                transform: scale(1.02);
            }

            .cardNlWrapper {
                width: 90%;
                height: 95%;
                position: relative;
              
                .topNL {
                    width: 100%;
                    height: 30%;
                    background: #F3F4F4;
                    // background-color: rgb(255, 226, 226);
                    border-radius: 20px;


                    img {
                        transition: all 0.5s ease;
                        object-fit: cover;
                        overflow: hidden;
                        height: 100%;
                        width: 100%;
                        // border-radius: 20px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 15px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                    }

                }

                .textNl {
                    .time {
                        h6 {
                            margin: 0;
                            padding: 0;
                            margin-top: 1vh;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            color: #00141F;
                            opacity: 0.5;
                        }
                    }

                    .content {
                        // text-align: justify;


                        h2 {
                            text-align: left;
                            display: block;
                            margin: 0;
                            padding: 0;
                            margin-top: 1.5vh;
                            font-family: Archivo;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 24px;
                            line-height: 40px;
                            text-transform: capitalize;
                            color: #FFFFFF;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                                    line-clamp: 2; 
                            -webkit-box-orient: vertical;
                        }

                        p {




                            margin: 0;
                            padding: 0;
                            margin-top: 2vh;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 25px;
                            color: #FFFFFF;


                            opacity: 0.5;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 8; /* number of lines to show */
                                    line-clamp: 8; 
                            -webkit-box-orient: vertical;
                        }
                    }

                    .footerNL {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h5 {
                            margin: 0;
                            padding: 0;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            /* identical to box height */


                            color: #219EBC;
                        }

                        h3 {
                            margin: 0;
                            padding: 0;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 17px;
                            /* identical to box height */


                            color: #fff;
                        }

                        .footerNLLeft {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: space-between;


                        }

                        .propertyBox {
                            width: 73px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background: #032E41;

                            border-radius: 4px;
                            margin-bottom: 1vh;
                        }

                        .footerNLRight {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            background-color: rgb(255, 158, 158);

                            img {
                                transition: all 0.5s ease;
                                object-fit: cover;
                                overflow: hidden;
                                height: 100%;
                            }
                        }
                    }


                }
            }







        }

        .ssevenleft {

            flex: 1;
            // background-color: rgb(241, 127, 127);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            .topBlock {
                // position: absolute;
                // top: 0;
                width: clamp(320px, 28.472vw, 500px);
                height: 103px;
                background: #023047;
                position: relative;

                h2 {
                    position: absolute;
                    top: 10%;
                    left: 25%;

                    font-family: Archivo;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 40px;
                    /* identical to box height, or 167% */

                    text-transform: capitalize;

                    color: #FFFFFF;
                }

                // transform: matrix(1, 0, 0, -1, 0, 0);
            }




            .bottomBlock {
                // position: absolute;
                // bottom: 0;
                width: clamp(320px, 28.472vw, 500px);
                height: 70px;
                background: #023047;
                // border-radius: 15px 15px 0px 0px;
            }
        }

        .ssevenmid {

            flex: 1;
            // background-color: rgb(241, 127, 127);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            .topBlock {
                // position: absolute;
                // top: 0;
                width: clamp(320px, 28.472vw, 500px);
                height: 23px;
                background: #023047;
                // border-radius: 15px 15px 0px 0px;
                transform: matrix(1, 0, 0, -1, 0, 0);
            }


            .bottomBlock {
                // position: absolute;
                // bottom: 0;
                width: clamp(320px, 28.472vw, 500px);
                height: 195px;
                background: #023047;
                // border-radius: 15px 15px 0px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }


        }

        .ssevenright {

            flex: 1;
            // background-color: rgb(241, 127, 127);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            .topBlock {
                // position: absolute;
                // top: 0;
                width: 28.472vw;
                height: 108px;
                background: #023047;
                // border-radius: 15px 15px 0px 0px;
                transform: matrix(1, 0, 0, -1, 0, 0);
            }



            .bottomBlock {
                // position: absolute;
                // bottom: 0;
                width: 28.472vw;
                height: 136px;
                background: #023047;
                // border-radius: 15px 15px 0px 0px;
            }
        }
    }


}



@media only screen and (max-width: 900px) {
    .SectionSevenLP {
        position: relative;

    }

    .SectionSevenLP .SectionSevenLPWrapper {
        // position: absolute;
        // left: 50%;
        // transform: translateX(-70%)
    }

    .SectionSevenLP .SectionSevenLPWrapper .ssevenright {
        margin-left: 70px;
        display: none;
    }

    .SectionSevenLP .SectionSevenLPWrapper .ssevenleft {
        margin-right: 10px;
    }

    .SectionSevenLP .SectionSevenLPWrapper .cardSseven .cardNlWrapper .textNl .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 11;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}


@media only screen and (max-width: 500px) {

    .SectionSevenLP .SectionSevenLPWrapper .ssevenleft,
    .SectionSevenLP .SectionSevenLPWrapper .ssevenmid,
    .SectionSevenLP .SectionSevenLPWrapper .ssevenright {
        display: none;
        // transform: translateX(-70%)
    }

    .SectionSevenLPWrapperM {
        height: 90vh;

        width: 100%;

        display: flex !important;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;

        .cardSseven {
            margin-right: 15px;
            &:hover {
                // display: none;
                transform: scale(1) !important ;
            }
        }

        .scrollWrap {
            // max-height: 10%;
            // border: 1px solid #ddd;
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                width: 0;
            }
        }


        a {
            width: 100%;
            display: flex;

            justify-content: center;
        }

        .blogsBtnM {
            width: 300px;
        }

        h4 {
            margin-left: 10vw;
            width: 85%;
            text-align: left;
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 58px;
            /* identical to box height, or 161% */

            letter-spacing: 0.01em;
            text-transform: capitalize;

            color: #FFFFFF;
        }
    }



    .SectionSevenLP .SectionSevenLPWrapper .cardSseven .cardNlWrapper .textNl .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}
.sectionOneWhoWhole {
  // height: 100vh;

  .sectionOneWho {
    min-height: 100vh;

    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 2vh;
    position: relative;
    overflow: hidden;

    .staticImgCon {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      margin-top: 15vh;
    }

    .hTR {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(160%, -50%);
      // height: 200px;
      // background-color: rgb(39, 214, 112);

      width: 370px;
      transition: all 0.9s ease;
      // display: none;

      &.active {
        right: 0px;
        transform: translate(0, -50%);

        // padding-left: 70px;
        // margin-left: -00px;
        .Hmtcon {
          cursor: pointer;

          h3 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            text-transform: capitalize;
          }

          h4 {
            width: 300px;
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 31px;
            /* or 172% */

            color: #ffffff;
          }
        }
      }

      .Hmtcon {
        h3 {
          // transition: all 0.9s ease;
          text-align: left;
          // display: flex;
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 40px;
          /* identical to box height, or 167% */

          text-transform: capitalize;

          color: #ffffff;
        }

        h4 {
          // transition: all 0.9s ease;
          width: 370px;
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */

          text-align: left;

          color: #ffffff;
        }
      }
    }

    .hoverNCL {
      width: 200px;
      height: 400px;
      overflow: hidden;
      object-fit: cover;
      border-radius: 0 250px 250px 0;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50.5%;
      transform: translate(0%, -50%);
      transition: all 0.9s ease;
      z-index: 12;
      transform-origin: left;

      .rIMG {
        position: relative;
        // left: -40px;
      }

      &.active {
        z-index: 14;
        width: 90vw;
        height: 90vw;
        border-radius: 50%;
        left: 50%;
        transform: translate(-75%, -50%);
        cursor: pointer;

        .rightTI {
          width: 90vw;
          height: 90vw;
        }
      }

      .rightTI {
        // display: none;
        transition: all 0.9s ease;
        width: 200px;
        height: 400px;
        object-fit: cover;
        object-position: 60% 50%;
        // position: relative;
        // left: -40px;
      }
    }

    .hoverNCR {
      width: 200px;
      height: 400px;
      overflow: hidden;
      object-fit: cover;
      border-radius: 250px 0 0 250px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 50.5%;
      transform: translate(0%, -50%);
      transition: all 0.9s ease;
      z-index: 12;
      transform-origin: right;

      &.active {
        z-index: 14;
        width: 90vw;
        height: 90vw;
        border-radius: 50%;
        right: 50%;
        transform: translate(75%, -50%) !important;
        cursor: pointer;

        .rightTI {
          width: 90vw;
          height: 90vw;
        }
      }

      .rightTI {
        // display: none;
        transition: all 0.9s ease;
        width: 200px;
        height: 400px;

        object-fit: cover;
      }
    }

    .hTL {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-160%, -50%);
      // height: 200px;
      // background-color: rgb(230, 94, 94);

      width: 370px;
      // display: none;
      transition: all 0.5s ease;

      &.active {
        left: 0%;
        transform: translate(0%, -50%);

        .Hmtcon {
          cursor: pointer;

          h3 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            text-transform: capitalize;
          }

          h4 {
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 31px;
            /* or 172% */

            color: #ffffff;
          }
        }
      }

      .Hmtcon {
        h3 {
          text-align: right;
          // display: flex;
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 40px;
          /* identical to box height, or 167% */

          text-transform: capitalize;

          color: #ffffff;
        }

        h4 {
          // transition: all 0.5s ease;
          width: 370px;
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */

          text-align: right;

          color: #ffffff;
        }
      }
    }

    .sectionOneWhoBTMMOB {
      display: none;
    }

    .sectionOneWhoMid {

      // display: none !important;
      // anind
      .hoverIMGCON {
        width: 200px;
        height: 400px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 0 250px 250px 0;
        background-color: #fff;

        &:hover {
          width: 1171px;
          height: 1171px;
          cursor: pointer;
        }
      }

      .mIMG {
        &.leftTI {}

        &.rightTI {
          // display: none;
          width: 200px;
          height: 400px;
          object-fit: cover;
        }
      }
    }

    .sectionOneWhoBTM {
      display: flex;
      // display: none;
      // anind
      position: absolute;
      bottom: -3vh;
      width: 80vw;
      margin: 0 10vw;
      opacity: 1;
      // margin-top: 25vh;
      transition: all 0.5s ease;

      &.active {
        bottom: -6vh;
        opacity: 0;
      }

      .sectionOneWhoBTML {
        width: 50%;
      }

      .sectionOneWhoBTMR {
        width: 50%;
      }

      h1 {
        width: 300px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 40px;
        /* or 167% */
        text-transform: capitalize;
      }

      h2 {
        width: 520px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        color: #ffffff;
      }
    }

    .backgroundBuilding {
      position: absolute;
      bottom: 0;
      pointer-events: none;

      img {
        width: 100vw;
      }
    }

    .sectionOneWhoMid {
      margin-top: 30vh;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 40px;
        text-transform: capitalize;

        color: #ffffff;
      }

      h4 {
        width: 370px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        text-align: right;

        color: #ffffff;
      }

      .mLeft {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        margin-right: 15px;

        .mtcon {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          margin: 0 20px;
        }
      }

      .mRight {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 15px;

        .mtconR {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin: 0 20px;

          h4 {
            text-align: left;
          }
        }
      }
    }

    .uMHcon {
      display: none;
      margin-left: 10vw;
      margin-bottom: 5vh;
      width: 80vw;
      margin-top: 50px;

      h3 {
        margin-top: 20px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        text-transform: capitalize;

        color: #ffffff;
      }

      h4 {
        // width: 370px;
        margin-top: 5px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        // text-align: right;

        color: #ffffff;
      }
    }
  }
}


.SecOneWhoNew {
  height: 100vh;
  // background-color: red;
  // margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .monImGRoc {
    display: none;
  }

  .scroolInD {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 20px;
    left: 100px;

    svg {
      margin-left: 5px;
    }
  }

  svg {
    margin: 20px 0;
  }

  .SecOneWhoNew-M {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    .backCsvg {
      position: absolute;
      top: -100px;
      left: -100px;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .SecOneWhoNew-L {
    .SecOneWhoNewTCON-L {

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .SecOneWhoNew-R {
    .SecOneWhoNewTCON-R {

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        text-align: left;
      }
    }
  }

  h1 {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-align: right;
    letter-spacing: 0.01em;

    color: #00A5CC;
  }

  p {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    /* or 225% */
    max-width: 315px;
    text-align: right;
    color: #FFFFFF;

  }

  .SecOneWhoNew-M {
    margin: 0 -150px;
  }
}


@media only screen and (max-width: 1190px) {
  .SecOneWhoNew .SecOneWhoNew-M {
    margin: 0 -100px;
    position: relative;
    left: 40px;
  }

  .SecOneWhoNew .SecOneWhoNew-M img {
    width: 80%;
    margin: 0;
  }

  .sectionOneWhoWhole .sectionOneWho .hoverNCR.active {
    right: 44% !important;
  }

  .sectionOneWhoWhole .sectionOneWho .hoverNCL.active {
    left: 44% !important;
  }

  #root>div>div>div>div>div>div.sectionOneWho>div.sectionOneWhoMid {
    transform: scale(0.9);
  }
}


@media only screen and (max-width: 770px) {
  .monImGRoc {
    display: flex !important;

    img {
        
      width: 400px;
    }
  }

  .SecOneWhoNew svg {
    margin: 5px 0;
  }

  .SecOneWhoNew {
    h1 {
      font-size: 30px;
      margin-bottom: 0;
    }
    p{
      font-size: 14px;
    }
  }

  .SecOneWhoNew .SecOneWhoNew-L .SecOneWhoNewTCON-L {
    align-items: flex-start;

    p {
      text-align: left;
    }
  }

  .SecOneWhoNew .SecOneWhoNew-R .SecOneWhoNewTCON-R {
    padding-bottom: 60px;
  }

  .SecOneWhoNew .scroolInD {
    bottom: 10px;
    left: 10%;
  }

  .SecOneWhoNew {
    height: auto;
    flex-direction: column;
  }

  .SecOneWhoNew .SecOneWhoNew-M {
    display: none;
  }



  .sectionOneWhoWhole .sectionOneWho .uMHcon {
    display: block;
  }

  .sectionOneWhoWhole .sectionOneWho .staticImgCon {
    display: flex;

    .staticIR {
      margin-left: 5px;
    }

    .staticIL {
      margin-right: 5px;
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM {
    position: relative;

  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM .sectionOneWhoBTML {
    width: auto;

    h1 {
      width: 70vw;
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM .sectionOneWhoBTMR {
    width: auto;

  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM h2 {
    width: auto;
  }

  .sectionOneWhoWhole .DESKV {
    display: none;
  }

  .sectionOneWhoWhole {
    // height: 123vh;
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM {
    margin-top: 0;
    padding-bottom: 5vh;
    // position: relative;
    // bottom: auto;
  }

  #root>div>div>div>div>div>div.sectionOneWho {
    justify-content: flex-start;
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM {
    flex-direction: column;
    width: 80vw;
    margin-left: 10vw;

    h1 {
      margin-left: 0;
      padding-bottom: 20px;
    }
  }

  #root>div>div>div>div>div>div.sectionOneWho>div.sectionOneWhoMid {
    margin-top: 15vh;

    .mLeft {
      .mtcon {
        display: none;
      }

      img {
        transform: scale(1.2);
        margin-right: 15px;
      }
    }

    .mRight {
      .mtconR {
        display: none;
      }

      img {
        transform: scale(1.2);
        margin-left: 15px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .monImGRoc img{
    width: 50vh;
  }
  .NSecOneWhatMain .NSecOneWhatT .RippleHolder {
    display: none !important;
  }

  .sectionOneWhoWhole .sectionOneWho {
    padding-bottom: 0;
  }

  .sectionOneWhoWhole .sectionOneWho .staticImgCon .staticIL {
    width: 40%;
  }

  .sectionOneWhoWhole .sectionOneWho .staticImgCon .staticIR {
    width: 40%;
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTMMOB {
    position: relative;
    display: block;

    .sectionOneWhoBTMMOB_BG_IMG {
      right: 0;
      position: absolute;
    }

    .sectionOneWhoBTMMOB_BG_IMG_R {
      left: -100px;
      top: 610px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      position: absolute;
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTMMOBBTM {
    margin-top: 70px;
    width: 80vw;
    padding: 0 10vw;
    background: #0041646b;
    padding-top: 30px;
    padding-bottom: 40px;
    position: relative;

    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      /* or 167% */

      text-transform: capitalize;

      color: #ffffff;
    }

    p {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #7f9099;
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTMMOBCARDS {
    .soBC {
      width: 80vw;
      padding: 0 10vw;
      margin-top: 30px;

      h1 {
        margin-top: -5px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 28px;
        /* identical to box height, or 175% */

        text-transform: capitalize;

        color: #ffffff;
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        color: #ffffff;
      }
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTMMOBTOp {
    // background-color: rgb(224, 28, 28);
    width: 80vw;
    padding: 0 10vw;

    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      /* or 167% */

      text-transform: capitalize;

      color: #ffffff;
    }

    p {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #ffffff;
    }
  }

  #root>div>div>div>div>div.sectionOneWhoWhole {
    // height: 160vh;
  }

  #root>div>div>div>div>div>div.sectionOneWho>div.sectionOneWhoBTM {
    .sectionOneWhoBTML {
      width: 100%;
    }

    .sectionOneWhoBTMR {
      width: 100%;

      h2 {
        width: auto;
      }
    }
  }

  .sectionOneWhoWhole .sectionOneWho .sectionOneWhoBTM {
    display: none;
  }

  #root>div>div>div>div>div>div.sectionOneWho>div.sectionOneWhoMid {
    margin-top: 15vh;

    .mLeft {
      img {
        transform: scale(0.85);
        margin-right: -25px;
      }
    }

    .mRight {
      img {
        transform: scale(0.85);
        margin-left: -25px;
      }
    }
  }

  .sectionOneWhoWhole .sectionOneWho .uMHcon {
    margin-top: 0px;

    h3 {
      margin-top: 0px;
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      /* identical to box height, or 167% */

      text-transform: capitalize;

      color: #ffffff;
    }

    h4 {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #ffffff;
    }
  }
}
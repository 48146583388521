.TEsecOne {
  width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: red;

  padding-bottom: 100px;
  flex-direction: column;
  padding-top: 140px;
  .dividers {
    margin: 28px 0;
  }

  .spacer {
    margin-top: 43px;
  }

  p {
    width: 780px;

    font-family: "Lexend";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    /* or 225% */
    text-align: center;

    color: #ffffff;
  }

  .scrollInd {
    margin-top: 53px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }
}

.TEsecTwo {
  padding: 0 100px;
  padding-bottom: 00px;
  .TEsecTwo-t {
    h1 {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      text-transform: capitalize;

      color: #ffffff;
    }

    h2 {
      width: 834px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */

      color: #ffffff;
    }
  }

  .TEsecTwo-b {
    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 96px;
      margin: 0;
      padding: 0;
      margin-bottom: -35px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      background: -webkit-linear-gradient(
        -86deg,
        #219ebc 40%,
        #0e4463 55%,
        #0b3852 31%
      );
      -webkit-background-clip: text;
      -webkit-text-stroke: 3px transparent;
      color: #00141f;
      position: relative;
    }

    .itemTe {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;
      .itemTe-r{
        img{
          width: 600px;
        }
      }
    }

    .itcon {
      width: 520px;
      height: 218px;
      display: flex;
      // align-items: center;
      justify-content: center;
      padding: 0 54px;
      background: #002133;
      border-radius: 0px 50px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 5;

      h2 {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        /* or 138% */
        text-transform: capitalize;
        color: #00a5cc;
      }

      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        /* or 200% */
        color: #ffffff;
      }
    }
  }
}

.TEsecFeatures {
  padding: 0 100px;
  padding-bottom: 46px;
  .TEsecFeatures-t {
    h1 {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */
      text-transform: capitalize;
      color: #ffffff;
    }
    h2 {
      padding-top: 36px;
      width: 412px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      color: #ffffff;
    }
  }
  .TEsecFeatures-b {
    h1 {
      color: #00141f;
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 108px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      // margin: 0;
      // padding: 0;
      // margin-bottom: -35px;
      background: -webkit-linear-gradient(
        -86deg,
        #219ebc 40%,
        #0e4463 55%,
        #0b3852 31%
      );
      -webkit-background-clip: text;
      -webkit-text-stroke: 3px transparent;
    }

    .itemTe {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;
    }

    .itemTe-l {
      display: inline-flex;
    }

    .itcon {
      justify-content: center;
      padding: 0 54px;
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        text-transform: capitalize;
        color: #00a5cc;
      }

      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}
.TOneImg{
  width: 70%;
  margin-top: -10%;
  margin-bottom: -12%;
}
.SectionFourAP {
    height: 500px;
    position: relative;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .bgI {
        position: absolute;
       
            width: 100vw;
            height: 500px;
            object-fit: cover;
        
    }

    .SectionFourAPCon {
        width: 70vw;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 12;
        .SectionFourAPConL {
            h1 {
                font-family: Archivo;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 40px;
                /* identical to box height, or 167% */
                
                text-transform: capitalize;
                
                color: #FFFFFF;
                
            }

            p {
                width: 520px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */

                width: 520px;
                color: #FFFFFF;

            }
        }
    }
    .SectionFourAPConR{
        display: flex;
        height:100%;
        align-items: flex-end;
        img{
            height:90%;
        }

    }

}



@media only screen and (max-width: 1195px) {
    .SectionFourAP .SectionFourAPCon{
        width:80vw;
    }
}


@media only screen and (max-width: 775px) {
    .SectionFourAP{
        height:auto;
    }
    .SectionFourAP .SectionFourAPCon{
        flex-direction: column;
    }
    #root > div > div > div > div > div.SectionFourAP > div > div.SectionFourAPConR{
        margin-top: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .SectionFourAP .SectionFourAPCon .SectionFourAPConL p{
        width: 100%;
    }
    .SectionFourAP .SectionFourAPConR img{
        width: 100%;
    }
}
.SecOneContactMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  justify-content: space-between;
  margin-top: 50px;
  // align-items: center;
  .SecOneContactMainWrapper {
    padding-top: 10vh;
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 95%;
    // margin-bottom: 200px;
  }

  .leftContact {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: #ffff;
      opacity: 0.5;
    }

    h2 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      color: #ffff;
    }
  }

  .rightContact {
    flex: 2;
  }
}

@media only screen and (max-width: 1325px) {
  .SecOneContactMain .rightContact form .formItemCon {
    width: 350px;
  }
}

@media only screen and (max-width: 1025px) {
  .SecOneContactMain .rightContact form .formItemCon {
    width: 300px;
  }
}

@media only screen and (max-width: 770px) {
  .SecOneContactMain .SecOneContactMainWrapper {
    flex-direction: column;
  }

  .SecOneContactMain .leftContact {
    flex: none;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 500px) {
  .SecOneContactMain .SecOneContactMainWrapper {
    width: 90%;
  }
  .SecOneContactMain .rightContact form .formItemCon {
    // width: 100%;
    width: auto;
    padding: 0.3vh 0;
  }
  .SecOneContactMain .rightContact form {
    display: flex;
    flex-direction: column;
    padding-bottom: 5vh;
   
  }
  .SecOneContactMainWrapper{
    form{
      button{
        width: 50% ;
      }
    }
  }
}

form {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  button {
    width: 300px;
    height: 50px;
    outline: none;
    border: none;
    background: #219ebc;
    border-radius: 10px;
    font-family: Lexend;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease;

    &:hover {
      background: #217a91;
      cursor: pointer;
    }
  }
  .SubmitButton{
    margin: 1vh 0;
    display: flex;
    align-items: flex-end;
    justify-content: end;
  }

  .messageCon {
    grid-column: 1/3;
    // grid-row: 3/4;
    width: 100% !important;
    height: 150px !important;

    textarea {
      width: 100%;
      height: 100% !important;

      outline: none;
      border: 1px solid #fff;
      background: #00141f;
      border-radius: 10px;
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      resize: none;
    }
  }

  .formItemCon {
    margin: 1vh 0;
    display: flex;
    flex-direction: column;
    width: 410px;
    position: relative;

    span {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      margin-bottom: 1%;
      color: #ffffff;
      opacity: 0.5;
    }

    input {
      height: 50px;
      border: none;
      outline: none;

      border-radius: 10px;
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      border: 1px solid #fff;
      background: #00141f;
    }
  }
}

.NSecFiveWhatMain {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    // align-items: center;
    justify-content: center;

    .NSecFiveWhatWrapper {
        display: flex;
        width: 80vw;
        margin: 0 10vw;

        .NSecFiveWhatT {

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                font-family: Archivo;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 40px;
                /* identical to box height, or 167% */

                text-transform: capitalize;

                color: #FFFFFF;

            }

            p {
                width: 543px;


                font-family: Lexend;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */


                color: #FFFFFF;
            }
        }
    }

    .CSlider {
        margin-top: 40px;
        // background-color: rgb(61, 28, 28);
        margin-left: 5vw;
        width: fit-content;

        .CSliderT {
            display: flex;
            position: relative;
            transition: all 0.6s ease-in-out;
            left: 0;

            &.one {
                left: 0vw;

            }

            &.two {
                left: -30vw;
            }

        }

        .CSliderB {
            margin-top: 20px;
            width: 85vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 50px;

            .PosiScrollI {
                width: 200px;
                height: 6px;
                background-color: #023047;
                position: relative;
                border-radius: 4px;

                &.one {
                    .PosiScrollIinside {
                        left: 0px;

                    }
                }

                &.two {
                    .PosiScrollIinside {
                        left: 100px;

                    }
                }


                .PosiScrollIinside {
                    transition: all 0.4s ease-in-out;
                    width: 100px;
                    height: 6px;
                    background-color: #219EBC;
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    // right: auto;
                }
            }

            .BtnCons6n {
                .aLimg {
                    margin-right: 20px;
                    transform: rotate(180deg);
                    opacity: 0.5;
                    transition: all 0.2s ease;

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }

                .aRimg {
                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1195px) {


    .NSecFiveWhatMain .CSlider .CSliderB .PosiScrollI {
        &.one {}

        &.two {
            .PosiScrollIinside {
                left: 50px;
            }
        }

        &.three {
            .PosiScrollIinside {
                left: 100px;
            }
        }
    }

    .NSecFiveWhatMain .CSlider .CSliderT {
        &.two {
            left: -300px;
        }

        &.three {
            left: -600px;
        }
    }

}



@media only screen and (max-width: 770px) {

    .NSecFiveWhatMain .CSlider .CSliderB .PosiScrollI {

        &.two {
            .PosiScrollIinside {
                left: 30px;
            }
        }

        &.three {
            .PosiScrollIinside {
                left: 60px;
            }
        }

        &.four {
            .PosiScrollIinside {
                left: 100px;
            }
        }
    }

    .NSecFiveWhatMain .CSlider .CSliderT {
        &.two {
            left: -440px;
        }

        &.three {
            left: -880px;
        }

        &.four {
            left: -1310px;
        }

    }

}


@media only screen and (max-width: 500px) {


    .NSecFiveWhatMain .CSlider .CSliderT {
        &.one{
            left:20px;
        }
        &.two {
            left: -355px;
        }

        &.three {
            left: -730px;
        }

        &.four {
            left: -1105px;
        }

    }







    .hiB{
        display: none;
    }
    .NSecFiveWhatMain .NSecFiveWhatWrapper .NSecFiveWhatT {
        flex-direction: column;
        align-items: flex-start;
        p{
            width: 100%;
        }
    }
  
    .NSecFiveWhatMain .CSlider .CSliderB{
        justify-content: center;
        .BtnCons6n{
            display: flex;
        }
        .PosiScrollI{
            display: none;
        }
    }
}
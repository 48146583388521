.SecOneApplicationMain {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120vh;
    justify-content: space-between;

    .SecOneApplicationMainWrapper {
        margin-top: 10vh;
        display: flex;
        // align-items: center;
        justify-content: center;
        width: 95%;
        // background: rgb(224, 182, 182);
    }

    .leftApplication {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        // background: rgb(210, 243, 168);
        span {
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */


            color: #FFFFFF;

            opacity: 0.5;
        }

        h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */


            color: #FFFFFF;
        }
    }

    .rightApplication {
        flex: 2;
        // background: rgb(168, 194, 243);

        form {
            display: grid;
            grid-template-columns: auto auto;

            justify-content: space-between;

            button {
                width: 300px;
                height: 50px;
                outline: none;
                border: none;
                background: #219EBC;
                border-radius: 10px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #FFFFFF;
                transition: all 0.3s ease;

                &:hover {
                    background: #228faa;
                    cursor: pointer;

                }
            }

            .file {
                border: 1px dashed #219EBC;

                display: none;
                align-items: center;
                justify-content: center;
            }
            .fileT {
                height: 50px;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
                background: rgba(33, 158, 188, 0.1);
                border: 1px dashed #219EBC;
                box-sizing: border-box;
                border-radius: 10px;
                p{
                    margin: 0 5px;
                    color: #219EBC;
                    text-decoration: underline;
                }
            }

            .filecon {}

            .messageCon {
                grid-column: 1/3;
                grid-row: 4/4;
                width: 100% !important;
                height: 150px !important;


                textarea {
                    width: 100%;
                    height: 100% !important;

                    border: 1px solid #fff;
                    outline: none;
                    background: #00141F;
                    border-radius: 10px;
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #fff;
                    resize: none;
                }
            }

            .expCon {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .formItemCon {
                    width: 190px;
                }
            }

            .formItemCon {
                margin: 1vh 0;
                display: flex;
                flex-direction: column;
                width: 410px;

                span {
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    /* identical to box height */
                    margin-bottom: 1%;
                    color: #FFFFFF;
                    opacity: 0.5;
                }

                input {
                    height: 50px;
                    border: none;
                    outline: none;

                    border-radius: 10px;
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #fff;
                    border: 1px solid #fff;
                    background: #00141F;
                }

           

                .dropdown {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 190px;
                    position: relative;

                    &:hover {
                        cursor: pointer;
                    }

                    &.active {

                        .dropdown-select {
                            border-end-start-radius: 0px;
                            border-end-end-radius: 0px;
                        }

                        .dropdown-list {
                            display: flex;
                        }
                    }

                    .dropdown-select {
                        padding: 0 5%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 90%;
                        height: 50px;
                        background-color: #00141F;
                        border-radius: 10px;
                        border: 1px solid #fff;


                    }

                    .dropdown-list {

                        // padding-top: 2%;
                        // background-color: #F2F3F3;
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        width: 190px;
                        transition: all 0.5 ease;
                        position: absolute;
                        top: 50px;
                        z-index: 10;


                    }

                    .dropdown-list-item {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 1% 0;
                        width: 100%;
                        background-color: #00141F;
                        border: none;
                        outline: none;


                        /* identical to box height */

                        border-right: 1px solid #fff;
                        border-left: 1px solid #fff;
                        color: #fff;



                        &:last-of-type {

                            border-end-start-radius: 10px;
                            border-end-end-radius: 10px;
                            border-bottom: 1px solid #fff;
                        }

                        &:hover {
                            // background-color: #0a6da3;
                            background-color: #219EBC;
                            // #219EBC
                            cursor: pointer;
                        }

                    }
                }


            }
        }
    }
}


@media only screen and (max-width: 1325px) {

    .SecOneApplicationMain .rightApplication form .formItemCon {
        width: 350px
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 160px
    }

}

@media only screen and (max-width: 770px) {
    .SecOneApplicationMain .SecOneApplicationMainWrapper {
        flex-direction: column;
    }

    .SecOneApplicationMain .leftApplication {
        flex: none;
        align-items: flex-start;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 20vw;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown .dropdown-select {
        width: 90%;

    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        align-items: flex-start;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown .dropdown-list {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .SecOneApplicationMain .SecOneApplicationMainWrapper {
        width: 90%;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon {
        width: 100%;
        padding: 0.3vh 0;
    }

    .SecOneApplicationMain .rightApplication form {
        display: flex;
        flex-direction: column;
        padding-bottom: 5vh;
    }

    .SecOneApplicationMain .rightApplication form .formItemCon .dropdown {
        width: 95%;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800&display=swap");

body {
  background-color: #00141f;
  font-family: "Lexend";
  color: white;
  font-size: 14px;
  font-weight: 500;
  overflow-x:hidden ;
}

.text-prim-blue {
  color: #00a5cc;
}

/* SOLUTIONS */
.solutions-section {
  padding-left: 292px;
}

.sols-intro {
  width: 50%;
}

.solutions-inner {
  display: flex;
}

.sols-tab-wrap {
  margin-left: auto;
}

.sols-tab-inner {
  display: flex;
}

.sols-tab-content {
  margin-right: 25px;
}

.sol-number {
  font-family: "Archivo";
  font-size: 125px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
  background-image: linear-gradient(
    180deg,
    rgba(33, 158, 188, 1),
    rgba(33, 158, 188, 0)
  );
  user-select: none;
  color: #00141f;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
}

.sol-card-wrap {
  background-image: linear-gradient(
    180deg,
    rgba(33, 158, 188, 1),
    rgba(33, 158, 188, 0)
  );
  padding: 2px;
  max-width: 400px;
  min-height: 445px;
  border-radius: 0px 50px 0px 50px;
  overflow: hidden;
}

.sol-card-wrap .sol-card {
  width: 100%;
  height: 100%;
  min-height: inherit;
  border-radius: 0px 50px 0px 50px;
  overflow: hidden;
  padding: 60px 55px;
  background-image: radial-gradient(rgba(0, 33, 51, 0.5), rgba(0, 33, 51, 1));
}

.sol-card h4 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 40px;
}

.sol-card p {
  font-size: 14px;
  font-weight: 500;
  line-height: 2.4;
}

.sols-tab-nav .nav-link {
  background-color: #000e14;
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  border-radius: 0 0 0 25px;
  padding: 34px;
  border: 2px solid transparent;
}

.sols-tab-nav .nav-link.active {
  background-color: #000e14;
  border-color: #219ebc;
}
/* SOLUTIONS END */

/* STRATEGY */
.swiper-slide:not(.swiper-slide-active) .strat-card {
  transform: rotate(90deg);
}

.swiper-slide:not(.swiper-slide-active) .strat-content {
  display: none;
}

.strat-card {
  border: 2px solid #023047;
  border-radius: 20px;
  padding: 36px;
  min-width: 574px;
}

.strat-title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.strat-img {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #219ebc;
  margin-right: 30px;
  flex-shrink: 0;
}

.strat-title h4 {
  font-size: 24px;
  font-weight: 800;
}

.strat-content p {
  line-height: 1.8;
}
/* STRATEGY END */

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}

@media screen and (min-width: 1700px) {
  .container {
    max-width: 1550px;
  }
}

@media screen and (max-width: 1439px) {
  .sol-card-wrap .sol-card {
    padding: 30px 25px;
  }

  .sol-card h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1365px) {
  .solutions-section {
    padding-left: unset;
  }
}

@media screen and (max-width: 1199px) {
  .sols-intro {
    padding-inline: 15px;
  }
}

@media screen and (max-width: 1023px) {
  .sols-tab-nav .nav-link {
    padding: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .solutions-section {
    padding-inline: 15px;
  }

  .sols-intro {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .sols-intro .lined-subtitle {
    padding: 0;
  }

  .sols-intro .lined-subtitle::before {
    content: unset;
  }

  .solutions-inner {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .sols-tab-inner {
    flex-direction: column-reverse;
  }

  .sols-tab-wrap {
    margin-left: 0;
  }

  .sols-tab-content {
    margin-right: 0;
  }

  .sols-tab-nav > .nav-pills {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .sols-tab-nav .nav-item {
    flex-shrink: 0;
    padding-bottom: 10px;
  }

  .sols-tab-nav .nav-link {
    margin: 0 5px 0px 0;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;
  }

  .sol-number {
    text-align: center;
    font-size: 70px;
    margin-bottom: -20px;
  }

  .sol-card-wrap {
    margin: 0 auto;
    min-height: auto;
    position: relative;
  }
}

.NSecThreeWhatMain {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 72.91vh;

    .NSecThreeWhatTMOB {
        display: none;
    }

    .bhWimg {
        position: absolute;
        width: 100vw;
        height: 72.91vh;
        object-fit: cover;
        z-index: -1;
    }

    .bhWimgMOB {
        display: none;
    }

    .NSecThreeWhatT {
        p {
            margin-top: 82px;
            margin-left: 10vw;
            font-family: Lexend;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            /* or 175% */
            width: 410px;

            color: #FFFFFF;
        }
    }

    .NSecThreeWhatM {
        margin-left: 75px;
        margin-top: 90px;
        margin-right: 27px;
        display: flex;
        position: relative;
        left: 0;

        transition: all 0.5s ease-in-out;

        &.two {
            left: -34.5vw;
        }



    }

    .NSecThreeWhatB {

        width: 76vw;
        margin: 0 12vw;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .PosiScrollI {
            width: 300px;
            height: 6px;
            background-color: #023047;
            position: relative;
            border-radius: 4px;

            &.two {
                .PosiScrollIinside {
                    left: 150px;

                }
            }

            .PosiScrollIinside {
                transition: all 0.4s ease-in-out;
                width: 150px;
                height: 6px;
                background-color: #219EBC;
                border-radius: 4px;
                position: absolute;
                left: 0;
                // right: auto;
            }
        }
    }
}


@media only screen and (max-width: 1195px) {
    .NSecThreeWhatMain .NSecThreeWhatM {
        &.two {
            left: -350px;
        }

        &.three {
            left: -700px;
        }
    }

    .NSecThreeWhatMain .NSecThreeWhatB .PosiScrollI {

        &.two {
            .PosiScrollIinside {

                left: 80px;
            }
        }

        &.three {
            .PosiScrollIinside {

                left: 150px;
            }
        }
    }
}

@media only screen and (max-width: 770px) {
    .NSecThreeWhatMain .NSecThreeWhatM {
        &.two {
            left: -440px;
        }

        &.three {
            left: -880px;
        }

        &.four {
            left: -1300px;
        }

    }

    .NSecThreeWhatMain .NSecThreeWhatB .PosiScrollI {

        &.two {
            .PosiScrollIinside {

                left: 50px;
            }
        }

        &.three {
            .PosiScrollIinside {

                left: 90px;
            }
        }

        &.four {
            .PosiScrollIinside {

                left: 150px;
            }
        }

    }
}

@media only screen and (max-width: 500px) {
    .NSecThreeWhatMain {
        height: auto;
    }

    .NSecThreeWhatMain .NSecThreeWhatM {
        display: none;
    }

    .NSecThreeWhatMain .bhWimg {
        height: auto;
        display: none;
    }

    .NSecThreeWhatMain .NSecThreeWhatT p {
        margin-top: 40px;
    }

    .NSecThreeWhatMain .bhWimgMOB {
        display: flex;
        position: absolute;
        z-index: -1;
        height: 100%;
    }

    .NSecThreeWhatMain .NSecThreeWhatT p {
        width: 90%;
        margin-left: 5vw;
    }

    .NSecThreeWhatMain .NSecThreeWhatM.three {
        display: none;
    }

    .NSecThreeWhatMain .NSecThreeWhatB {
        display: none;
    }

    .NSecThreeWhatTMOB {
        margin-top: -10px;
        display: flex !important;
        flex-direction: column;
        // background-color: #fff;
        width: 90%;
        margin-left: 5vw;
        margin-bottom: 92px;

        .nstwmC {
            margin-top: 40px;
            display: flex;

            .nstwmCL {
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                /* identical to box height, or 156% */


                color: #219EBC;
            }

            .nstwmCR {
                margin-left: 10px;

                .headTC {

                    margin-top: -10px;
                    font-family: Archivo;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 36px;
                    line-height: 58px;
                    /* or 161% */

                    letter-spacing: 0.01em;
                    text-transform: capitalize;

                    color: #FFFFFF;
                }

                .contentTC {

                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 25px;
                    /* or 179% */


                    color: #809199;

                }
            }
        }
    }
}
.CanimationMain {
  display: flex;
  align-items: center;
  justify-content: center;
//   height: 100vh;
padding: 100px 0;
  width: 100vw;
  background-color: #00141f;
  .CaniMimg {
    width: 537.81px;
    height: 600px;
    // background-color: #fff;
  }
  .CaniMimgCon {
    position: relative;
    left: -70px;
    // background-color: #fff;
  }
  .LineAniCOn {
      opacity: 0;//gsap
    width: 60px;
    height: 60px;
    border: 2px solid #219ebc;
    border-radius: 50%;
    position: absolute;

    .cAline {
      width: 0px; //gsap 69px
      height: 2px;
      background-color: #219ebc;
      position: absolute;
      top: 50%;
      transform-origin: left;
      opacity: 0; //gsap
      left: 60px;
      .cAline-tip {
        opacity: 0; //gsap
        .l1 {
          width: 9px;
          height: 2px;
          background-color: #219ebc;
          position: absolute;
          right: -4.5px;
          top: -3.7px;
          transform: rotate(45deg);
        }

        .l2 {
          width: 9px;
          height: 2px;
          background-color: #219ebc;
          position: absolute;
          right: -4.5px;
          bottom: -3.7px;
          transform: rotate(-45deg);
        }

        .Co {
            opacity: 0; //gsap
          height: 213px;
          width: 300px;
          position: absolute;
          top: -130.5px;
          right: -290px;//gsap -320px
        }

        .Co4 {
            opacity: 0; //gsap
          width: 300px;
          height: 163px;
          position: absolute;
          top: -110.5px;
          right: -290px;//gsap -320px
        }
      }
    }

    &.top1R {
      opacity: 0;//gsap
      top: 63px;
      right: 5px;
    }
    &.top1L {
        opacity: 0;//gsap
      top: 173px;
      right: 90px;
    }

    &.BtmR {
      top: 460px;
      right: 5px;
    }
    &.BtmL {
        opacity: 0;//gsap
      top: 340px;
      right: 75px;
    }
  }
}

.cAline2 {
  width: 0px;//gsap 244px
  height: 2px;
  background-color: #219ebc;
  position: absolute;
  top: 50%;
  transform-origin: right;
  right: 60px;
  .cAline-tip {
    opacity: 0; //gsap
    .l3 {
      width: 9px;
      height: 2px;
      background-color: #219ebc;
      position: absolute;
      left: -4.5px;
      top: -3.7px;
      transform: rotate(-45deg);
    }

    .l4 {
      width: 9px;
      height: 2px;
      background-color: #219ebc;
      position: absolute;
      left: -4.5px;
      bottom: -3.7px;
      transform: rotate(45deg);
    }

    .Co3 {
        opacity: 0; //gsap
      width: 300px;
      height: 163px;
      position: absolute;
      top: -100.5px;
      left: -250px;//gsap 320px
    }
    .Co2 {
        opacity: 0; //gsap
      width: 300px;
      height: 163px;
      position: absolute;
      top: -130.5px;
      left: -250px; //gsap -310px
    }
  }
}

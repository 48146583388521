.card-slider .swiper-slide {
  height: auto;
}

.card-slider .swiper-slide-active {
  min-width: 350px;
}

.swiper-slide-active .card-slide-outline {
  background: linear-gradient(
    171deg,
    rgba(33, 158, 188, 1) 0%,
    rgba(12, 107, 130, 0) 100%
  );
}

.swiper-slide-active .card-slide {
  background-color: #002133;
  border-radius: 22px;
}

.card-slide-outline {
  border-radius: 20px;
  overflow: hidden;
  padding: 1px;
  height: 100%;
}

.card-slide {
  padding: 35px 25px;
  width: 100%;
  height: 100%;
  background-color: #000e14;
  color: white;
}

.card-slide .card-slide-img {
  margin-bottom: 25px;
  user-select: none;
}

.card-slide-content .card-slide-title {
  margin-bottom: 10px;
}

.card-slide-title {
  color: #00a4cc;
  font-size: 16px;
  user-select: none;
}

.card-slide-desc {
  font-size: 12px;
  line-height: 1.8;
  user-select: none;
}

.custom-card-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 100px;
}

.custom-card-nav .swipe-card-btn {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002133;
  border: 2px solid #4c5a62;
  color: #4c5a62;
  transition: 0.3s all;
}

.custom-card-nav .swipe-card-btn:hover {
  color: white;
  border-color: white;
}

@media screen and (max-width: 1365px) {
  .custom-card-nav {
    margin-top: 50px;
  }

  .card-slider .swiper-slide-active {
    min-width: auto;
  }
}

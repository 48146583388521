.BenchmarkSection-Main {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 6%;
  position: relative;
  // background-color: red;
  padding-bottom: 187px;
  gap: 15px;

  &.safegaurd {
    padding-top: 200px;
    background-color: #000e14;

    .BenchmarkSection-L {
      margin-right: -6.7% !important;
    }

    .keysvg {
      position: absolute;
      bottom: -20%;
      left: -240px;
    }
  }

  .BenchmarkSection-L {
    position: relative;

    .blrImgCon {
      position: absolute;
      top: -250px;
      left: -60px;
      pointer-events: none;
    }
  }

  .BenchmarkSection-R {
    padding-top: 80px;
    position: relative;

    p {
      /* T2 */

      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */

      margin-top: 51px;
      margin-bottom: 54px;

      color: #ffffff;
      width: 545px;
    }
  }

  .AccelerateOpportunitiesCon {
    position: absolute;
    width: 1081px;
    height: 219px;

    overflow: hidden;
    background: #002133;
    border-radius: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    bottom: -108px;
    // display: none;
    z-index: 19;
    left: 50%;
    transform: translateX(-50%);

    .blrImgConAA {
      position: absolute;
      top: -250px;
      left: -60px;
      pointer-events: none;
    }

    p {
      width: 541px;
    }
  }
}

.OurProduct-main {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 120px 0;
  padding-bottom: 400px;
  // background-color: red;

  .OurProduct-L {
    position: relative;

    svg {
      position: absolute;
      top: 220px;
    }

    .spanT {
      position: absolute;
      right: 190px;
      margin-top: 52px;

      span {
        margin-left: 0 !important;
      }
    }

    // background-color: red;
    border-radius: 0 20px 20px 0;
    width: 30%;
    height: 400px;
    border-right: 2px solid #219ebc;
    border-top: 2px solid #219ebc;
    position: relative;

    .phoneL {
      width: 238px;
      position: absolute;
      right: -119px;
      top: 180px;
    }
  }

  .OurProduct-M {
    width: 293px;
  }

  .OurProduct-R {
    // background-color: red;
    border-radius: 20px 0 0 20px;
    width: 50%;
    height: 400px;
    border-left: 2px solid #219ebc;
    border-top: 2px solid #219ebc;
    position: relative;

    .mobSpanT {
      display: none;
      margin-left: 20px;
    }

    .OurProduct-R-web {
      margin-top: 169px;
      margin-left: 171px;
      width: 330px;
      height: 493px;

      padding: 0 31px;
      background: linear-gradient(180deg, #002133 0%, #00141f 100%);
      border: 2px solid #023047;
      border-radius: 20px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .websvg {
        position: absolute;
        top: 30px;
        right: 30px;
      }

      h1 {
        margin-top: 10px;
        font-family: "Archivo";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        /* or 150% */

        color: #ffffff;
      }

      P {
        margin-top: 12px;
        font-family: "Lexend";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 32px;
        /* or 262% */

        color: #ffffff;
      }
    }

    .phoneR {
      width: 283px;
      position: absolute;
      left: -141px;
      top: 82px;
    }
  }
}

@media screen and (max-width: 500px) {
  .BenchmarkSection-Main {
    position: relative;
    top: 30vh;
    padding-bottom: 50px;
    .BenchmarkSection-L {
      .bulbimage {
        height: 300px;
      }
    }
    .BenchmarkSection-R {
      padding-top: 150px;
      p {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
      }
    }
    .BenchmarkSection-L {
      position: absolute;
      top: 20vh;
      opacity: 0.3;
    }
    .AccelerateOpportunitiesCon {
      p {
        width: auto !important;
      }
    }
    &.Tokenized{
      margin-bottom: 50vh;
    }
    &.safegaurd{
      padding-top: 0;
    }
  }
  .LandingCardSlider {
    .intro-title,
    .intro-sub {
      text-align: left;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 700px){
  .BenchmarkSection-Main {
    .BenchmarkSection-R{
      padding-top: 150px;
      p{
        margin-top: 0;
      }
    }
    .bulbimage{
      display: none;
    }
  }
  .sectionOneMainTopInnner{
    .sectionOneMainTopInnnerLeft{
      width: auto !important;
    }
  }
}
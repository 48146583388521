.App {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mainBody {
  position: relative;
}

.STTOPCON {
  width: 100vw;
  height: 100vh;
  // background-color: rgba(250, 114, 114, 0.37);
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  pointer-events: none;

  .scroll-tracker {
    &.active {
      img {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  img {
    opacity: 0;
    position: absolute;
    right: 40px;
    // z-index: 100;
    // bottom: 50px;
    transition: all 0.5s ease;
    animation-name: scrollTT;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:hover {
      cursor: pointer;
    }
  }

  @keyframes scrollTT {
    0% {
      bottom: 50px;
    }

    50% {
      bottom: 70px;
    }

    100% {
      bottom: 50px;
    }
  }
}

html {
  background-color: #00141f;
  // background-color:#f56b34;
}

h1 {
  margin: 0;
  padding: 0;
  // color: #fff !important;
}

h2 {
  margin: 0;
  padding: 0;
  color: #fff;
}

h3 {
  margin: 0;
  padding: 0;
  color: #fff;
}

h4 {
  margin: 0;
  padding: 0;
  color: #fff;
}

h5 {
  margin: 0;
  padding: 0;
  color: #fff;
}

.umhcL {
  height: 2px;
  width: 80vw;
  background-image: linear-gradient(269deg, #219dbc3d, #219ebc);
}

.fwb {
  width: 300px !important;
}

// GLOBAL
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
}

.container,
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.container {
  max-width: 1440px;
}

.section-spacing-top {
  padding-top: 75px;
}

.section-spacing-bottom {
  padding-bottom: 75px;
}

.f-lexend {
  font-family: "Lexend", sans-serif;
}

.f-archivo {
  font-family: "Archivo", sans-serif;
}

@media screen and (min-width: 1440px) {
  .container {
    color: white;
    max-width: 1440px;
  }
}
@media screen and (max-width: 1200px){
  .MultiSignatureWallet{
    .SecuritiesOne-Main {
      position: relative;
      .SecuritiesOne-L{
        flex: none;
      }
      .SecuritiesOne-R{
        position: unset;
        img{
          opacity: 0.2;
        }
      }
    }
  }
  .whitelabel{
    .SecuritiesOne-Main{
      .SecuritiesOne-R{
        display: none;
      }
      .SecuritiesOne-L{
        .secTitle {
          display: grid;
          justify-content: center;
      
        }
      }
    } 
  }
}

// GLOBAL END

.mainBody {
  max-width: 1440px;
  width: 100%;
}

//all responsive

@media screen and (max-width: 1240px) {
  .BenchmarkSection-Main .AccelerateOpportunitiesCon {
    width: 90% !important;
    padding: 20px !important;
  }

  .DigiTrustTaxes-main .DigiTrustTaxes-B {
    align-items: center;
    justify-content: center !important;

    .TaxComp {
      margin-bottom: 20px;
    }
  }

  .BenchmarkSection-Main {
    flex-direction: column;
    width: 100%;

    .HandWithShield {
      width: 100%;
      max-width: 400px;
    }

    .BenchmarkSection-L,
    .BenchmarkSection-R {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1,
      p {
        text-align: center;
        width: 100% !important;
      }
    }
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner {
    flex-direction: column !important;

    h1 {
      margin-top: 100px;
      line-height: 30px !important;

      span {
        font-size: 28px !important;
        line-height: 30px !important;
      }
    }
  }

  .TEsecOne {
    width: 100% !important;

    img {
      width: 100%;
      max-width: 700px;
    }
  }

  .TEsecTwo {
    padding: 5%;

    h2 {
      width: auto !important;
    }
  }

  .SecuritiesOne-Main {
    // flex-direction: column !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-R img {
    // position: relative !important;
    // width: 100%;
    // left: auto ;
    // right: auto ;
    // top: auto ;
    // bottom: auto ;
  }

  .SecuritiesOne-Main.DigiTrust .secTitle h1 {
    width: auto !important;
  }

  .mainBody {
    width: 100% !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-R .srbsvg {
    display: none;
  }

  .multitextCon {
    // display: none;
    width: 90% !important;

    h1 {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }

  .TEsecOne p {
    width: 90% !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .TEsecTwo .TEsecTwo-b .itemTe {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .OurProduct-main .OurProduct-L {
    display: none;
  }

  .OurProduct-main .OurProduct-R {
    width: 100% !important;

    .mobSpanT {
      display: flex !important;
    }
  }

  .section-spacing-top {
    padding-top: 50px;
  }

  .section-spacing-bottom {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .ContactPage{
    .footer-top-new-r{
      width: 80%;
    }
    .formItemCon{
      min-width: 100%;
    }
  }
  .OurProduct-main .OurProduct-R {
    display: flex;
    flex-direction: column;
    border-left: none !important;
    border-top: none !important;
    align-items: center;
    height: auto !important;
  }

  .OurProduct-main {
    padding-top: 40px !important;
    padding-bottom: 100px !important;
  }

  .OurProduct-main .OurProduct-R .OurProduct-R-web {
    margin-top: 30px !important;
    margin-left: 0px !important;
    width: 330px;
    height: 493px;
    padding: 0 31px;
    background: linear-gradient(180deg, #002133 0%, #00141f 100%);
    border: 2px solid #023047;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .mobSpanT > div {
    display: contents !important;

    span {
      margin-left: 3px !important;
    }
  }

  .OurProduct-main .OurProduct-M {
    display: none !important;
  }

  .OurProduct-main .OurProduct-R .phoneR {
    position: relative !important;
    left: auto !important;
    top: auto !important;
  }

  .BenchmarkSection-Main .AccelerateOpportunitiesCon {
    flex-direction: column;

    p {
      width: 90% !important;
    }
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner {
    flex-direction: column !important;

    h1 {
      margin-top: 30px;
      line-height: 30px !important;

      span {
        font-size: 28px !important;
        line-height: 30px !important;
      }
    }
  }

  .SecuritiesOne-Main.DigiTrust .secTitle h1 {
    width: 90% !important;
  }

  .NSecFourAPM .NSecFourAPWB {
    margin-top: -200px !important;
  }

  .TEsecTwo .TEsecTwo-b .itemTe {
    img {
      display: none;
    }
  }

  .TEsecTwo .TEsecTwo-b .itcon {
    width: 90% !important;
    height: auto !important;
    padding: 20px 20px !important;
  }

  .NSecFourAPM {
    position: relative !important;
    top: -70px;
  }

  .section2-New {
    padding-bottom: 0 !important;
  }

  .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon .bgImg.mob {
    width: 100vw !important;
  }

  .SecuritiesOne-Main .SecuritiesOne-L {
    h1,
    p {
      width: auto !important;
    }
  }

  .section2-New .left .sliderConBOX {
    width: 100% !important;
    height: 550px !important;
  }

  .section2-New .left {
    width: 90% !important;
  }

  .svgline {
    display: none;
  }

  .section2-New .left .sliderConBOX .smidCon {
    margin-top: 850px !important;
  }

  .BoxItem {
    transform: scale(0.8);
    scale: 0.8;
  }

  .section2-New {
    .left {
      margin-bottom: 100px !important;
    }

    .right {
      width: 90% !important;
    }
  }
}

.svgkey,
.keysvg {
  display: none !important;
}

.footer-top-new {
  // background-color: red;
  width: 100%;
  display: flex;
  background-image: url("fotterTop.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-top: 140px;
  flex-wrap: wrap;
  padding-bottom: 40px;

  // background-color: #002133;
  .SecOneContactMainWrapper {
    padding-top: 0 !important;

    form {
      gap: 20px;
    }

    input,
    textarea, select {
      padding: 10px;
      background-color: #00141f00 !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 12px !important;
    }
    select{
      height: 100%;
    }
    select option {
      background-color: #ffffff;
      color: #333333;
      font-size: 16px;
      padding: 5px;
    }
    .SelectButton{
      height: 100%;
      padding: 10px;
      background-color: #00141f00 !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 12px !important;
    }
    .List{
      border:  1px solid rgba(255, 255, 255, 0.2) ;
      position: absolute;
      top: 70px;
      width: inherit;
      border-radius: 12px;
      padding: 5px;
      z-index: 1;
      background: #000;
      height: 300px;
      overflow: auto;
      &::-webkit-scrollbar{
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #000;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #012436;
        border-radius: 5px;
      }
      .item{
        padding:  10px 5px;
        border-bottom:1px solid rgba(255, 255, 255, 0.2);
        cursor: pointer;
      }
    }

    span {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      /* identical to box height, or 17px */

      letter-spacing: 0.004em;
      text-transform: capitalize;

      color: #ffffff !important;
      opacity: 1 !important;
    }
  }

  .footer-top-new-r {
    // margin-top: 50px;
  }

  .footer-top-new-l {
    display: flex;
    flex-direction: column;
    align-self: start;
    justify-content: flex-start;
    margin-top: 50px;

    h1 {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 130%;
      /* or 57px */

      text-transform: capitalize;
      width: 410px;
      color: #ffffff;
    }

    h2 {
      width: 410px;

      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      color: #ffffff;

      opacity: 0.5;
    }

    h3 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      /* identical to box height, or 21px */

      // text-align: center;
      letter-spacing: 0.004em;

      /* Secondary 2 */

      color: #219ebc;

      text-shadow: 4px 4px 10px rgba(33, 158, 188, 0.12);

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
}

//media q
@media (max-width: 1100px) {
  .footer-top-new .footer-top-new-l {
    margin-top: 80px;
  }

  .footer-top-new .footer-top-new-r {
    margin-top: 0;
  }
}
@media (max-width: 1200px){
  
  .footer-top-new {
    .footer-top-new-l {


      h1 {
        font-size: 30px;
        width: 90%;
      }

      h2 {
        width: 700px;
      }
    }
  }
}
@media (max-width: 768px) {
  .footer-top-new .SecOneContactMainWrapper {
    .rightContact {
      form {
        display: flex;
        flex-direction: column;
        // grid-template-columns: auto;
      }
    }
  }

  .VerticalTabsMain .VerticalTabs-l svg {
    // display: none !important;
  }

  .footer-top-new {
    .footer-top-new-l {
      width: 90%;

      h1 {
        font-size: 30px;
        width: 90%;
      }

      h2 {
        width: 90%;
      }
    }
  }
}

.tokenize,
.section2-New,
.TEsecTwo,
.shbDealStructure,
.SectionFiveWho {
  padding-bottom: 200px !important;
}

.DigiTrustTaxes-main .DigiTrustTaxes-B {
}

.footerM {
  margin-top: -150px;

  &.footerGap {
    margin-top: 0;
  }
}

.backCsvg {
  display: none !important;
}

.head-sub-menu {
  //new-css
  padding: 43px 45px;
  // padding: 25px 45px;

  .newHeaderT {
    max-width: 447px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.01em;

    color: #ffffff;
    margin-bottom: 35px;

    span {
      color: #00a5cc;
    }
  }

  .newHeaderB {
    display: flex;
    flex-direction: column;

    // flex-wrap: wrap;
    width: 100%;
    // background-color: red;
    justify-content: space-between;
    gap: 25px;

    .sepLine {
      position: relative;
      top: 10px;
      margin: 0 5px;
    }

    .newHeaderB-T,
    .newHeaderB-B {
      display: flex;
    }

    .newHeaderB-B {
      border-top: 1px solid #0f3951;
      padding-top: 20px;
    }

    .newHeaderItem {
      // width: 245px;
      background-color: #012436;
      transition: all 0.5s ease;

      border-radius: 12px;
      text-decoration: none !important;
      padding: 20px;

      &:hover {
        background-color: #0f384d;

        // text-decoration: none !important;
      }

      .newHeaderItem-T {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        color: white;

        .imgheadCon {
          margin-right: 10px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: #002133;
          border: 1px solid #219ebc;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .newHeaderItem-B {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 25px;
        /* or 208% */
        width: 245px;

        color: #ffffff;
      }
    }
  }
}

.head-sub-menu.mob {
  display: none !important;
}

@media only screen and (max-width: 325px) {
  .BenchmarkSection-Main .AccelerateOpportunitiesCon {
    // bottom: -60vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .MultiSignatureWallet{
    .SecuritiesOne-Main {
      .SecuritiesOne-L{
      }
      .SecuritiesOne-R{
        img{
       display: none;
        }
      }
    }
  }
  form .formItemCon {
    max-width: 90vw !important;
  }
  .multitextCon {
    width: auto !important;
  }
  .BenchmarkSection-Main .AccelerateOpportunitiesCon {
    height: 310px !important;
    bottom: -60vh !important;
    height: fit-content !important;
  }
  .footer-top-new {
    margin-top: -30vh;
  }
  .BlueBlockNet {
    .TEsecOne {
      img{
        width: 500px !important;
      }
    }
   
  }
}
.mt100 {
  margin-top: 100px;
}

@media only screen and (max-width: 990px) {
  .head-sub-menu.pc {
    display: none !important;
  }
  .mt100 {
    margin-top: 00px;
  }

  .head-sub-menu.mob {
    // display: block !important;
  }

  .head-menu-item.custom-dropdown:hover .head-sub-menu.mob {
    display: block !important;
  }

  .head-sub-menu {
    padding: 0 !important;
    min-width: 230px !important;
  }
}

.footerM.nobg {
  .footer-top-new {
    background-image: none !important;
    margin-top: 50px;
  }
  .footer-top-new-l {
    margin-top: 0;
  }
}

.OurProductMobileCon {
  padding: 40vh 7vw 10vh 7vw;
  .head {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 30px;
  }
  .image {
    position: relative;
    .main {
      width: 240px;
      // height: 460px;
    }
    .side {
      position: absolute;
      top: 10%;
      width: 200px;
      margin-left: 10px;
    }
  }
  .det {
    margin-top: 40px;
    h1 {
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 24px;
      line-height: 36px;
      font-family: "Archivo", sans-serif;
    }
    p {
      line-height: 25px;
    }
  }
  .link {
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    color: #219ebc;
    display: flex;
    gap: 10px;
  }
}

@media screen and (min-width: 600px) {
  .OurProductMobileCon {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .KycAml {
    .KycHero {
      display: none;
    }
    .shbSectionDealHero{
      .shbHeroHeading{
       
      }
      .col-lg-6{
        width: 100%;
        .shbHeroHeading{
          max-width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .OurProduct-main {
    display: none !important;
  }
  .OurProductMobileCon {
    display: block;
  }
  .whitelabel {
    .tokenize {
      padding-bottom: 50px !important;
      .tokenize-row {
        .modifiedInner {
          padding: 30px;
          h3 {
            margin-bottom: 0px !important;
          }
        }
      }
    }
    .modifiedInner {
      text-align: left;
      align-items: unset !important;
    }
    
    .Securix{
      margin: 0 7%;
      padding: 0;
      width: fit-content;
    }
    .SecuritiesOne-Main {

      margin-top: 50px;
      position: relative;
      .SecuritiesOne-L {
        z-index: 1;
        .secTitle {
          h1,
          span {
            font-size: 36px !important;
            line-height: 40px !important;
          }
        }
      }
      .SecuritiesOne-R {
        position: absolute;
        // top: 10%;
        opacity: 0.4;
        .digiHero {
          width: auto;
        }
      }
    }
    .HowDigitrust-Main {
      .HowDigitrust-T {
        .Tcontainer {
          h1 {
            font-size: 28px;
          }
          h2 {
            text-align: left;
          }
        }
      }
      .Feature {
        .Feature-col {
          margin-top: 0px;
          .Feature-img {
            img {
              height: 150px;
            }
          }
          .Feature-col-inner {
            h3 {
              font-size: 18px;
            }
            p {
              font-size: 12px;
              line-height: 24px;
            }
          }
        }
        .custom-card-nav {
          margin-top: 0px;
          justify-content: center !important;
        }
      }
    }
  }
}
.SecureKey {
  margin-top: -20%;
}
.DTKey {
  height: 45vh;
  display: grid;
  align-items: center;
}
.App.WithLine {
  background: url("./assets/WithLine.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.App.WithoutLine {
  background: none;
}
.BlueBlockNet {
  .TEsecOne {
    padding-top: 0;
    
    img {
      width: 900px;
      height: auto;
      position: relative;
    left: 100px;
    }
  }
}
@media screen and (max-width: 600px) {
  .MultiSignatureWallet {
    .SecuritiesOne-Main {
      margin-top: 50px;
      .SecuritiesOne-L {
        width: 90%;
      }
    }
    .DigiTrustTaxes-main {
      padding-top: 0;
      .DigiTrustTaxes-B {
        margin-top: 0;
        display: block;
        padding: 0 2%;
        margin-bottom: 10%;
      }
    }
  }
  .Tokenization {
    .TEsecOne {
      padding: 50px 20px;
      p {
        width: auto !important;
        text-align: left;
      }
    }
    .TEsecTwo {
      padding: 0 20px;
    }
  }
  .section2-New .left .sliderConBOX .smidCon {
    height: auto !important;
  }
  .App.WithLine {
    background-size: contain;
  }
  .BlueBlockNet {
    .TEsecOne {
      .multitextCon {
        width: 90% !important;
      }
      p {
        text-align: left;
      }
    }
    .TEsecTwo {
      padding: 0 20px;
    }
    .TEsecFeatures {
      padding: 0px 20px;
      .TEsecFeatures-t {
        h2 {
          width: auto;
        }
      }
      .TEsecFeatures-b {
        .itemTe {
          .itemTe-l {
            display: block;
            .itcon {
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }
  .KycAml {
    .KycHero {
      display: none;
    }
    .shbBodyContainer {
      .shbDealStructureUnity {
        .shbBodySecondaryHeader {
          max-width: none;
        }
      }
    }
    .shbSectionDealStructure {
      margin-bottom: 0;
    }
  }
  .securex {
    .SecuritiesOne-Main .SecuritiesOne-R .digiHero {
      display: none;
    }
    .HowDigitrust-T {
      .Tcontainer {
        h1 {
          text-align: left !important;
        }
        h2 {
          width: auto !important;
        }
      }
    }
  }
  .SecuritiesOne-Main.WL .scrollInd {
    margin-top: 100px !important;
  }
}
@media screen and (max-width: 1200px) and (min-width: 600px) {
  .MultiSignatureWallet {
    .DigiTrustTaxes-main {
      width: auto;
      .DigiTrustTaxes-B {
        justify-content: unset !important;
      }
    }
  }
  .BlueBlockNet{
    .TEsecTwo {
      padding: 0 30px;
      .TEsecTwo-b{
        .itcon{
          width: auto;
        }
        .itemTe{
          flex-direction: row;
          align-items: flex-start;
          .itemTe-r{
            img{
              width: 500px;
            }
          }
        }
      }
    }
  }
  
}
@media screen and (max-width: 800px) and (min-width: 600px) {

  .securex {
    .SecuritiesOne-R {
      display: none;
    }
    .Tcontainer {
      width: 90% !important;
    }
  }
  .BlueBlockNet{
    .TEsecTwo {
      .TEsecTwo-b{
        .itcon{
        }
        .itemTe{
          .itemTe-r{
            img{
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .MultiSignatureWallet{
    .SecuritiesOne-Main {
      .SecuritiesOne-L{
        .DTKey{
          height: 500px;
          img{
            margin-top: 100px;
          }
        }
      }
      
    }
  }
  .head-menu .head-sub-menu {
    left: -100% !important;
  }
  .Tokenization{
    .TEsecTwo{
      padding: 0 50px;
      .TEsecTwo-b{
        .itemTe{
          flex-direction: row;
          .itemTe-r{
            img{
              width: 400px;
            }
          }
        }
      }
    }
  }
  .securex{
    .SecuritiesOne-Main{
      .digiHero{
        width: 500px !important;
        
      }
    }
  }
}

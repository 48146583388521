.section2 {
  display: flex;
  flex-direction: column;
  position: relative;
  //   background-color: black;
  // background-image: radial-gradient(circle, #363232, #272525, #020202);
  min-width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #00141f;

  .top {
    margin-top: 36px;
    width: 80vw;
    display: flex;
    align-items: center;
    // background-color: #fb8500;

    .left {
      align-items: center;
      // justify-content: center;
      display: flex;
      flex: 1;

      .leftCon {
        width: 50%;
      }

      h3 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 40px;
        /* or 167% */

        text-transform: capitalize;

        color: #ffffff;
      }
    }

    .right {
      // width: 50vw;
      flex: 1;
      display: flex;
      // align-items: center;
      // justify-content: center;

      .rightcon {
        width: 90%;
        // height: 400px;
        // text-align: justify;

        h3 {
          font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 23px;
          font-weight: 500;

          .innerH3span {
            font-size: 25px;
            font-weight: 800;
          }
        }

        span {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: #ffffff;
        }

        .getStartedCon {
          margin-top: 10px;
          display: flex;
          align-items: center;
          width: fit-content;
          transition: all 0.3s ease;

          .fiar {
            font-size: 17px;
            margin-left: 5px;
            color: #fb8500;
          }

          span {
            font-family: Lexend;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #fb8500;
            transition: all 0.2s ease;

            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
  }

  .bottom {
    // width: 80vw;
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;

    .bMV {
      display: none;
    }

    .right {
      display: flex;

      align-items: center;
      justify-content: flex-end;
      flex: 1;
      position: relative;
      right: -10vw;

      // img {
      //   // height:60vh;
      //   mix-blend-mode: normal;
      //   opacity: 0.6;
      // }
      .imgConBuildingN {
        width: 530px;
        height: 490px;
        // background-color: #fff;
        position: relative;
        left: -30px;

        // opacity: 0.6;
        .b1Img {
          position: absolute;
          left: 0;
          // bottom: 35px;
          bottom: -335px;
        }

        .b2Img {
          position: absolute;
          left: 58px;
          bottom: -395px;
        }

        .b3Img {
          position: absolute;
          left: 100px;
          bottom: -345px;
        }

        .b4Img {
          position: absolute;
          left: 166px;
          bottom: -395px;
        }

        .b5Img {
          position: absolute;
          left: 250px;
          bottom: -505px;
        }

        .b6Img {
          position: absolute;
          left: 385px;
          bottom: -395px;
        }

        .b7Img {
          position: absolute;
          left: 445px;
          bottom: -359px;
        }

        .b8Img {
          position: absolute;
          left: 523px;
          bottom: -379px;
        }

        .b9Img {
          position: absolute;
          left: 570px;
          bottom: -349px;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex: 1;

      .g {
        width: 260px;
        border-bottom: 1px solid #00141f;
      }

      .gridCon {
        display: grid;
        grid-column-gap: 10px;
        grid-row-gap: 40px;
        grid-template-columns: auto auto;

        h1 {
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 96px;
          margin: 0;
          padding: 0;

          // color: rgba(0, 0, 0, 0);

          letter-spacing: 0.02em;
          text-transform: capitalize;
          background: -webkit-linear-gradient(-86deg,
              #219ebc 40%,
              #0e4463 55%,
              #0b3852 31%);
          -webkit-background-clip: text;
          -webkit-text-stroke: 3px transparent;
          color: #00141f;

          span {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 96px;
            margin: 0;
            padding: 0;
          }
        }

        span {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }

        .btmSpan {
          position: relative;
          top: -20px;
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }

      .leftcon {
        // width: 410px;
        // height: 450px;
        // text-align: justify;

        h3 {
          font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;

          .innerH3span {
            font-size: 24px;
            font-weight: 700;
          }
        }

        span {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 25px;
        }
      }
    }

    .right {
      .imgConBuilding {}

      img {
        padding-right: 10px;
      }
    }
  }

  .sTwop2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5vh;

    h2 {
      margin: 0;
      padding: 0;

      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      /* identical to box height, or 167% */

      text-align: center;
      text-transform: capitalize;

      color: #ffffff;
    }

    h3 {
      margin: 0;
      padding: 0;
      margin-top: 10px;
      font-family: Lexend;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */

      text-align: center;

      color: #ffffff;
      width: 60%;
    }
  }
}

@media only screen and (max-width: 905px) {
  .sectionOneMain .sectionOneMainBotm {
    align-items: flex-start;
    margin-left: 10vw;
    width: 80vw;
  }

  .sectionOneMain .sectionOneMainBotm .sectionOneMainBotmL {
    width: 100%;
    // align-items: center;
    margin-bottom: 30px;
  }

  .sectionOneMain>div.sectionOneMainBotm {
    // flex-direction: column;

    .sectionOneMainBotmL {
      .animatedImgCon {
        transform: scale(0.9);
      }

      img {
        // height: 400px;
      }
    }

    .sectionOneMainBotmR {
      width: 400px;
      flex: none;

      .sectionOneMainBotmRInner {
        width: 90%;

        h1 {
          font-size: 30px;
          line-height: 48px;
        }

        h3 {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 505px) {
  .section2 .bottom {
    .left {
      display: none;
    }

    .right {
      display: none;
    }

    .bMV {
      display: flex;
      margin-left: -10vw;
      margin-bottom: 80px;
      position: relative;

      button {
        // display: none !important;
      }

      .bgBuildingMob {
        width: 100vw;
      }

      .mainSi {
        margin-left: 10vw;
        width: 80vw;
        // height: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 10;

        .slick-arrow {
          display: none !important;
        }

        .slick-dots {
          position: relative;
          top: -10px;

          // display: none !important;
          button {
            // display: none;
            width: 12px !important;
            height: 12px !important;
            background-color: rgba(255, 255, 255, 0);
            border-radius: 50%;

            border: 1px solid #ffffff;

            &:before {
              display: none;
            }
          }
        }

        .slick-active {
          button {
            background-color: #219ebc;

            border: 1px solid #219ebc;
          }
        }

        .SlsCon {
          width: 100%;
          height: 500px;
          // background-color: #fff;
          display: flex !important;
          align-items: flex-end;
          justify-content: center;

          .sImg {
            // width: 182px;
            height: 157px;
          }
        }
      }
    }
  }

  .sectionOneMain .sectionOneMainBotm .sectionOneMainBotmR .sectionOneMainBotmRInner h1 {
    width: 100%;
    font-size: 24px !important;
    line-height: 40px;
  }

  .sectionOneMain .sectionOneMainBotm .sectionOneMainBotmR .sectionOneMainBotmRInner h3 {
    width: 100%;
  }

  .sectionOneMain .sectionOneMainBotm .sectionOneMainBotmR .sectionOneMainBotmRInner {
    width: 100% !important;
  }

  .sectionOneMainBotm .sectionOneMainBotmR .sectionOneMainBotmRInner {
    h1 {
      margin-top: 20px;
      font-size: 36px;
      line-height: 58px;
    }

    h3 {
      font-size: 14px;
      line-height: 25px;
    }
  }

  #root>div>div>div>div>div.sectionOneMain>div.sectionOneMainBotm {
    flex-direction: column;

    .sectionOneMainBotmL {
      .animatedImgCon {
        position: relative;
        z-index: 1;
        margin-top: -30px;
        margin-bottom: -100px;
        transform: scale(0.75);
      }

      img {
        // margin-top: 30px;
        // height: 400px;
      }
    }

    .sectionOneMainBotmR {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      width: 80vw;

      .sectionOneMainBotmRInner {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .MainGetStartedBTN {
          width: 300px;
        }
      }
    }
  }
}

// SECTION 2 MQ

@media only screen and (max-width: 1195px) {
  .section2 .sTwop2 h3 {
    width: 70%;
  }
}

@media only screen and (max-width: 770px) {
  .section2 .sTwop2 h3 {
    width: 80%;
    // text-align: justify;
  }

  #root>div>div>div>div>div.section2>div.top {
    .left {
      .leftCon {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .section2 .top .right .rightcon {
    margin-top: 10px;
    text-align: left;
  }

  #root>div>div>div>div>div.section2>div.top>div.right>div {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
    }
  }

  #root>div>div>div>div>div.section2>div.top {
    flex-direction: column;

    .left {
      .leftCon {
        width: 100%;

        h3 {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
  }

  #root>div>div>div>div>div.section2>div.sTwop2 {
    justify-content: left;

    h2 {
      text-align: left;
      font-size: 24px;
      line-height: 40px;
      width: 80%;
    }

    h3 {
      text-align: left;
      font-size: 14px;
      line-height: 25px;
      width: 80%;
    }
  }
}

@media only screen and (max-height: 716px) {
  .sectionOneMain .sectionOneMainBotm {
    margin-top: 13vh;
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopBTMline {
    bottom: -170px !important;
  }
}

@media only screen and (max-height: 711px) {
  .sectionOneMain .sectionOneMainBotm {
    margin-top: 18vh;
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopBTMline {
    bottom: -200px !important;
  }
}
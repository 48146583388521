.SecFourNewMain {
    // background-color: rgba(226, 99, 99, 0.747);
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    // // height: 70vh;
    // width: 80vw;
    // margin: auto;


}

.sfnCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0.8vw;
    padding-bottom: 100px;

    .CImg {
        // height: 390px;
        width: 300px;
        // padding-bottom: 100px;
    }

    .txtConSfn {

        height: 20.83vh;
        list-style: none;
        color: white;
        position: absolute;
        bottom: -3.5vh;

        img {
            width: 35%;
            height: fit;
        }

        h5 {
            margin: 0;


            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 28px;
            /* identical to box height, or 175% */

            text-transform: capitalize;

            color: #FFFFFF;
        }

        p {
            padding: 10px 0 0 0;
            margin: 0;
            font-family: Lexend;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */


            color: #7F8A8F;

        }

    }


}


@media only screen and (max-width: 1195px) {
    #root>div>div>div>div>div.SecFourNewMain {
        display: grid;
        grid-template-columns: auto auto;

        .sfnCard {
            margin-bottom: 7vh;
        }
    }
}

@media only screen and (max-width: 525px) {
    #root>div>div>div>div>div.SecFourNewMain {
        display: grid;
        grid-template-columns: auto;

        .sfnCard {
            margin-bottom: 7vh;
        }
    }
}


.SecFourNewMain {
    margin-top: 150px;
    padding: 0 5%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .SecFourNewCard {
        width: 300px;

        p {
            font-family: 'Lexend';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */


            color: #FFFFFF;

            opacity: 0.5;

        }

        h1 {

            margin: 0;
            padding: 0;
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 56px;
            /* or 154% */

            letter-spacing: 0.01em;
            text-transform: capitalize;
            background: -webkit-linear-gradient(-86deg,
                    #219EBC 40%,
                    #219EBC 55%,
                    #219EBC 31%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 3px transparent;
            color: #00141f;
            width: 230px;
        }
    }

}
.sectionThreeWho {
    // min-height: 90vh;
    padding-bottom: 10vh;
    background: #00141F;
    display: flex;
    flex-direction: column;
    align-items: center;
    .TimeLineSliderNEWMOB  {
        display: none;
    }
    .mobBtnCONS  {
        display: none;
    }
    .sectionThreeWhoWrapper {
        width: 80%;

   
        .sectionThreeWhoWrapperTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 100px;


            h1 {
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 40px;
                /* or 167% */

                text-transform: capitalize;
                width: 300px;
                color: #FFFFFF;
                margin: 0;
                padding: 0;
            }

            p {
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */

                width: 520px;
                color: #FFFFFF;
            }


        }
    }

    .TimeLineSliderNEW {
        height: 50vh;
        width: 100%;
        // background-color: rgb(180, 41, 41);
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;



        .BtnCons {
            display: flex;
            width: 100%;
            position: absolute;
            align-items: center;
            bottom: 0vh;
            background-color: #fff;

            .aLimg {
                position: absolute;

                left: 5vw;

                transform: rotate(180deg);
                opacity: 0.5;
                transition: all 0.2s ease;


                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }

            .aRimg {
                position: absolute;

                right: 5vw;

                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }


        .MAINmidLine {
            width: 100%;
            height: 3px;
            background-color: #33434C;
            position: absolute;
        }

        .TLSCard {
            display: flex;
            flex-direction: column;
            align-items: center;

            height: 367px;
            width: 1vw;

            padding: 0 5vw;
            transition: all 0.5s ease-in-out;
            position: relative;


            &.active {
                width: 30vw;
                padding: 0 0vw;
                height: 507px;


                .midLine {
                    height: 7px;
                    transform: rotate(0deg) translateY(-50%);
                    background-image: radial-gradient(#219EBC, rgba(0, 0, 0, 0));

                    width: 100%;

                    &:before {
                        display: none;
                        opacity: 0;
                    }

                    .MAINmidCircle {
                        width: 348px;
                        height: 348px;

                    }



                    .midCircleO {
                        width: 32px;
                        height: 32px;
                        border: 2px solid #219EBC;

                        .midCircleI {
                            width: 20px;
                            height: 20px;
                            background-color: #219dbc;
                            border: 0px solid rgb(255, 255, 255);

                        }
                    }



                }

                .imgCon {

                    height: 50%;
                    width: 100%;


                    img {

                        display: flex;
                        bottom: 0px;
                    }
                }



                .txtCon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;
                    height: 50%;

                    .i1 {
                        top: 20px;

                    }

                    .i2 {

                        top: 0px;
                    }


                    .p1 {

                        top: 0px;
                    }

                    .p2 {

                        top: 0px;
                    }
                }



            }

            &.Y2013 {
                .midLine {
                    &:before {
                        content: "2013";
                    }
                }
            }

            &.Y2016 {
                .midLine {
                    &:before {
                        content: "2016";
                    }
                }
            }

            &.Y2018 {
                .midLine {

                    &:before {
                        content: "2018";
                    }
                }
            }



            &.Y2021 {
                .midLine {

                    &:before {
                        content: "2021";
                    }
                }
            }

            .midLine {
                width: 367px;
                height: 3px;
                background-image: radial-gradient(#293A43, rgba(0, 0, 0, 0));
                transform: rotate(90deg);
                transition: all 0.5s ease-in-out;
                position: absolute;
                top: 50%;


                &:before {

                    position: absolute;
                    top: -8px;
                    transform: rotate(180deg);
                    right: -50px;
                    transition: all 0.5s ease-in-out;
                    opacity: 1;
                    font-family: Archivo;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 20px;
                    /* identical to box height */

                    text-align: center;
                    text-transform: capitalize;

                    color: #7F898F;
                }

                .MAINmidCircle {

                    width: 0px;
                    height: 0px;
                    background: radial-gradient(50% 50% at 50% 50%, #219EBC 0%, rgba(33, 158, 188, 0) 100%);
                    opacity: 0.2;
                    // filter: blur(20px);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s ease-in-out;
                }

                .midCircleO {

                    width: 0px;
                    height: 0px;

                    background-color: #219dbc00;
                    border: 0px solid #219EBC;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    position: relative;
                    transition: all 0.5s ease-in-out;

                    .midCircleI {

                        width: 12px;
                        height: 12px;
                        background-color: #219dbc00;
                        border: 2.5px solid #fff;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        border-radius: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 0.5s ease-in-out;
                    }
                }
            }

            .imgCon {
                overflow: hidden;
                height: 50%;
                width: 0%;
                display: flex;
                align-items: flex-end;
                justify-content: center;

                transition-delay: 1.5s;
                transition: all 0.5s ease-in-out;

                img {
                    position: relative;
                    bottom: -100px;
                    transition: all 0.5s ease-in-out;
                    transition-delay: 0.5s;
                }
            }

            .txtCon {
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                height: 40%;
                // background-color: #fff;
                transition: all 0.5s ease-in-out;

                .i1 {
                    position: relative;
                    top: 20px;
                    transition: all 0.5s ease-in-out;
                    top: -100px;
                }

                .i2 {
                    position: relative;

                    transition: all 0.5s ease-in-out;
                    top: -190px;
                }

                p {
                    width: 266px;
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 25px;
                    /* identical to box height, or 179% */
                    text-align: center;
                    color: #FFFFFF;
                    transition: all 0.8s ease-in-out;
                }

                .p1 {
                    position: relative;
                    top: -200px;
                }

                .p2 {
                    position: relative;
                    top: -350px;
                }
            }
        }
    }

}


@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 770px) {
    .sectionThreeWho .sectionThreeWhoWrapper {
        width: 90%;
    }

    .sectionThreeWho .TimeLineSliderNEW .TLSCard .imgCon img {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .sectionThreeWho {
        padding-bottom: 5vh;
    }
  
    .sectionThreeWho .sectionThreeWhoWrapper .sectionThreeWhoWrapperTop {
        flex-direction: column;
        padding-top: 0;
        p {
            width: 300px;
        }
    }




    // .sectionThreeWho .TimeLineSliderNEW .BtnCons {
    //     z-index: 30;
    // }
    // .sectionThreeWho .TimeLineSliderNEW .TLSCard.active{
    //     width:300px;
    // }
    .sectionThreeWho .TimeLineSliderNEW {
        display: none;
    }

    .mobBtnCONS {
        width: 90vw;
        // height: 100px;
        // background-color: #fff;

        display: flex !important;
        align-items: center;
        justify-content: space-between;

        &.posiOne {
            .posiSM {
                .posiSMI {
                    left: 0px;
                }
            }
        }

        &.posiTwo {
            .posiSM {
                .posiSMI {
                    left: 25px;
                }
            }
        }

        &.posiThree {
            .posiSM {
                .posiSMI {
                    left: 50px;
                }
            }
        }

        &.posiFour {
            .posiSM {
                .posiSMI {
                    left: 75px;
                }
            }
        }


        .posiSM {
            width: 153px;
            height: 6px;
            background-color: #023047;
            border-radius: 6px;
            transition: all 0.5s ease-in-out;
            position: relative;

            .posiSMI {
                width: 76.5px;
                height: 6px;
                background-color: #219EBC;
                border-radius: 6px;
                left: 0;
                transition: all 0.5s ease-in-out;
                position: relative;

            }

        }


        .BtnCons {
            display: flex;

            align-items: center;


            .aLimg {


                margin-right: 20px;


                transform: rotate(180deg);
                opacity: 0.5;
                transition: all 0.2s ease;


                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }

            .aRimg {



                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }

    }

    .TimeLineSliderNEWMOB {
        display: flex !important;

        position: relative;
        width: 400vw;
        left: 00vw;
        align-items: flex-start;
        justify-content: flex-start;
        transition: all 0.5s ease-in-out;

        &.posiOne {
            left: 150vw;
        }

        &.posiTwo {
            left: 50vw;
        }

        &.posiThree {
            left: -50vw;
        }

        &.posiFour {
            left: -150vw;
        }




        .mCMob {
            width: 32px;
            height: 32px;
            background-color: #219dbc00;
            border: 2px solid #219EBC;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .miCmob {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #219dbc;
            }
        }

        .mainMcMob {
            width: 348px;
            height: 348px;
            background: radial-gradient(50% 50% at 50% 50%, #219EBC 0%, rgba(33, 158, 188, 0) 100%);
            opacity: 0.2;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s ease-in-out;
        }

        .mainMlMob {
            width: 100%;
            height: 7px;
            transform: rotate(0deg) translateY(-50%);
            background-image: radial-gradient(#219EBC, rgba(0, 0, 0, 0));
            width: 100%;
            position: absolute;
            // left: 50%;
            top: 50%;
        }

        .tsCMobM {
            width: 100vw;
            height: 430px;
            // background-color: rgb(109, 73, 73);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            top: -30px;

            &.activeM {
                .inactiveC {
                    opacity: 1;
                }
            }

            .inactiveC {
                width: 12px;
                height: 12px;
                border: 1.5px solid #FFFFFF;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                opacity: 0;
                transform: translateY(-50%);
                transition: all 0.5s ease-in-out;

                &.Lef {
                    left: -6px;
                }

                &.Rig {
                    right: -6px;
                }
            }





            .tsCMobMtopS {
                // margin-top: 100px;
                height: 50%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                img {

                    width: 243px;
                    height: 108px;

                }
            }

            .tsCMobMbtmS {
                height: 50%;

                .txtCon {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                }
            }


            .p1m,
            .p2m {
                width: 80%;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* identical to box height, or 179% */

                text-align: center;

                color: #FFFFFF;
            }

        }
    }

}
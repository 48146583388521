.secTwoWho {
  .bottomMOB {
    display: none;
  }

  // height: 100vh;
  .secTwoWhoTop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .secTwoWhoTopIMOB {
    display: none;
  }

  .bottom {
    height: 62.5vh;
    overflow: hidden;

    .bSlider {
      width: 117.71%;
      height: 99%;
      display: flex;
      align-items: center;

      .bSControl {
        width: 26.042%;
        height: 100%;

        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #002133;

        // border: 2px solid #00141F;
        padding: 5px 0;

        .bSControlCon {
          width: 90%;
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .bsctcon {
            margin-left: 4%;
          }
        }

        h2 {
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 40px;
          /* or 167% */

          text-transform: capitalize;

          color: #ffffff;
        }

        h3 {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */

          color: #7f9099;

          // opacity: 0.5;
        }

        .BtnCons {
          .aLimg {
            margin-right: 20px;
            transform: rotate(180deg);
            opacity: 0.5;
            transition: all 0.2s ease;
            margin-left: 4%;

            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }

          .aRimg {
            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
      }

      .bSildeCon {
        width: 91.668%;
        // width: calc(100%-26.042%);
        height: 100%;
        position: relative;
        transition: all 0.5s ease;
        left: 0;
        display: flex;

        &.one {
          left: 0;
        }

        &.two {
          left: -17.917%;
        }

        &.three {
          left: -35.834%;
        }

        &.four {
          left: -53.751%;
        }
      }

      .sCard {
        width: 22.917%;
        height: 100%;
        border: 2px solid #02304700;
        background: linear-gradient(180deg, #002133 0%, #00141f 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;

        &:hover {
          cursor: pointer;
        }

        &.active {
          border: 2px solid #023047;
          background: linear-gradient(
            165.96deg,
            rgba(33, 158, 188, 0.3) 0%,
            rgba(2, 48, 71, 0) 100%
          );

          .sCardB {
            h3 {
              opacity: 1;
            }
          }
        }

        .sCardCon {
          width: 85%;
          height: 90%;
          display: flex;
          flex-direction: column;
          // align-items: center;
          // justify-content: center;
        }

        .sCardT {
          height: 52%;
          display: flex;
          align-items: flex-end;
        }

        .sCardB {
          margin-top: 20px;

          h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* or 150% */

            color: #ffffff;
            width: 80%;

            span {
              font-weight: 700;
            }
          }

          h3 {
            transition: all 0.5s ease;
            opacity: 0;
            margin-top: 10px;
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */

            color: #ffffff;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .CanimationMain {
    .CaniMimgCon {
      .CaniMimg {
        width: 400px;
        height: 450px;
      }
    }
    .top1L {
      top: 130px !important;
      right: 70px !important;
    }
    .BtmL {
      top: 250px !important;
      right: 50px !important;
    }
    .BtmR {
      top: 330px !important;
    }
    .cAline2{
        width: 100px !important;
    }
  }
}
@media only screen and (max-width: 1195px) {
  #root > div > div > div > div > div.secTwoWho > div.secTwoWhoTop > img {
    width: 90%;
  }
  .secTwoWho .secTwoWhoTop{
    display: none;
  }
}

@media only screen and (max-width: 770px) {
  .secTwoWho .bottom .bSlider {
    width: 200%;
  }

  .secTwoWho .bottom .bSlider .bSControl .bSControlCon {
    height: 70%;
  }

  .secTwoWho .bottom .bSlider .bSControl {
    width: 400px;
  }

  .secTwoWho .bottom .bSlider .bSildeCon {
    width: 191%;

    &.one {
      left: 0;
    }

    &.two {
      left: -15%;
    }

    &.three {
      left: -30%;
    }

    &.four {
      left: -45%;
    }
  }

  .secTwoWho .bottom .bSlider .sCard {
    width: 40%;
  }
}

@media only screen and (max-width: 800px) {
  .sectionThreeWho .TimeLineSliderNEW{
    height: 400px !important;
  }
  .secTwoWho .bottom {
    display: none;
  }

  .imgConSTW {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 1300px;
    display: flex;
    flex-direction: column;
    // overflow: hidden;

    .ti {
      position: absolute;
      right: 0;
    }

    .bi {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      position: absolute;
      top: 650px;
      left: -110px;
    }
  }

  .secTwoWho .secTwoWhoTop .secTwoWhoTDESK {
    display: none;
  }

  .dividerC {
    margin-top: 10px;
  }

  .secTwoWho .secTwoWhoTop .deskI {
    display: none;
  }

  .secTwoWhoTopIMOB {
    display: flex !important;
    flex-direction: column;
    width: 80%;
    min-height: 1250px;
    position: relative;

    .secTwoWhoTopIMOBB {
      // height: 403px;
      padding: 30px 0;
      background: #002133a1;
      position: relative;
      left: -10%;
      padding-left: 10%;
      width: 90%;
      margin-top: 70px;

      h2 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 40px;
        /* or 167% */

        text-transform: capitalize;

        color: #ffffff;
      }

      p {
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        color: #7f9099;
      }
    }

    .tText {
      h1 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 40px;
        /* or 167% */

        text-transform: capitalize;

        color: #ffffff;
      }

      h2 {
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .secTwoWho .secTwoWhoTop {
    display: none;
  }

  .secTwoWho .bottomMOB {
    display: flex !important;
    flex-direction: column;
    width: 100%;

    .bmCa {
      height: 140px;
      padding-left: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 2px solid #023047;
      // background-color: rgb(137, 209, 54);
      transition: all 0.5s ease;
      background: linear-gradient(97.98deg, #002133 0%, #00141f 100%);

      &.active {
        height: 376px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .contentT {
          margin-top: 10px;
          display: flex;
          transition: all 0.5s ease;

          p {
            bottom: 0px;
          }
        }

        .headT {
          margin-top: 20px;
          margin-left: 0px;
          transition: all 0.5s ease;
        }
      }

      img {
        width: 100px;
        height: 100px;
      }

      .headT {
        margin-left: 10px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        width: 300px;
        /* identical to box height, or 150% */

        color: #ffffff;
      }

      .contentT {
        display: none;

        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */
        overflow: hidden;

        color: #ffffff;
        // background-color: rgb(230, 36, 36);

        p {
          // right: -1000px;
          position: relative;
          bottom: -300px;
          transition: all 0.9s ease;
        }
      }
    }
  }
}

.clientCard {

    width: 400px;
    height: 188px;
    background-color: #00141F;
    border: 1px solid #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;

    .clientCardW {
        height: 80%;
        width: 90%;

        .clientCardT {
            display: flex;
            align-items: center;

            .imgCon {
                background-color: #fff;
                padding: 5px 7px;

                img {
                    width: 96px;
                    height: 50px;
                    object-fit: cover;
                }
            }
        }

        .topTCon {
            margin-left: 10px;

            h2 {
                margin: 0;
                padding: 0;
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height */


                color: #FFFFFF;
            }

            h3 {
                margin: 0;
                padding: 0;

                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height, or 150% */


                color: #FFFFFF;
            }
        }

        h4 {
            margin-top: 10px;
            font-family: Lexend;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */


            color: #FFFFFF;
        }

    }
}


.whatCtgCard {
    display: flex;
    align-items: flex-start;

    .whatCtgCardL {

        // width: 75px;
        h4 {
            margin-right: 20px;
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            /* identical to box height, or 117% */


            color: #219EBC;
        }
    }


    // FOR WHAT CARD
    .whatCtgCardR {
        width: 375px;

        h1 {
            margin: 0;
            padding: 0;
            margin-top: -14px;
            font-family: Archivo;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 72px;
            /* or 150% */

            letter-spacing: 0.01em;
            text-transform: capitalize;

            color: #FFFFFF;

        }

        p {
            margin: 0;
            padding: 0;
            margin-top: 20px;
            font-family: Lexend;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */


            color: #808E95;


        }
    }
}

// SecTwoWhoCard
.SecTwoWhoCardMain {
    margin-top: 40px;
    position: relative;


    .txtConWCM {
        h5 {
            margin: 0;
            padding: 0;
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 28px;
            /* identical to box height, or 175% */

            text-transform: capitalize;

            color: #FFFFFF;
        }

        p {
            margin: 0;
            padding: 0;
            margin-top: 10px;
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */


            color: #FFFFFF;
        }
    }
}


// MQ


@media only screen and (max-width: 1195px) {}

@media only screen and (max-width: 500px) {

    .clientCard {
        margin-left: -55px;
        transform: scale(0.85);
    }
}
.filter-card {
  padding: 17px;
  background: #00141f;
  border: 1px solid #209bb9;
  border-radius: 16px;
  margin: 20px 0 12px;
}

.filter-card>p svg {
  color: white;
}

.filter-card>p span {
  color: #209bb9;
  font-weight: 800;
}

.filter-card .contact-chip span {
  padding: 8px 16px 8px 35px;
  color: white;
}

.filter-check-icn svg {
  width: 16px;
  height: 16px;
}

label.contact-chip {
  color: white;
}

.contact-chip span {
  position: relative;
  display: block;
  cursor: pointer;
}

label.contact-chip input {
  position: absolute;
  opacity: 0;
}

.contact-chip span::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #1a2d38;
  position: absolute;
  left: 0;
  border-radius: 5px;
  transition: 0.3s all;
}

label.contact-chip input:checked+span::before {
  background-image: linear-gradient(50.4deg, #219ebc 19.29%, #002133 87.92%);
}

// .contact-chip span {
//   position: relative;
//   background-color: #f2effa;
//   font-size: 16px;
//   font-weight: 500;
//   color: var(--purple);
//   padding: 8px 20px;
//   margin-right: 10px;
//   border-radius: 30px;
//   cursor: pointer;
//   display: inline-flex;
//   transition: 0.3s all;
//   margin-bottom: 10px;
// }
// .contact-chip input {
//   opacity: 0;
//   position: absolute;
// }
// .contact-chip input:checked + span {
//   background-color: var(--purple);
//   color: var(--white);
// }
// .contact-chip input:checked + span .filter-check-icn {
//   background: #fff;
// }


.jobList {

  display: flex;
  flex-wrap: wrap;
  gap: 60px;

  // display: none;
  .jobSContainer {
    width: 253px;
    height: 366px;
    position: relative;
    background: #000E14;
    border-radius: 10px;
    padding: 19px 28px;
    box-sizing: border-box;
    overflow: hidden;

    .top {
      h4 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height, or 165% */

        // text-align: center;
        text-transform: capitalize;

        color: #00A5CC;

      }
    }

    .dsCon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;

        path {
          fill: #00A5CC;
        }
      }

      h5 {
        position: relative;
        top: 3px;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        /* identical to box height, or 183% */


        color: #FFFFFF;
      }
    }

    .bottom {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;

      // display: none;

      margin-bottom: 20px;

      h2,
      h3 {
        display: none;
      }

      p {
        // margin-top: -40px;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 20px;
        /* or 195% */


        color: #FFFFFF;

        span {
          margin-top: 30px !important;

          span {
            span {

              color: #FFFFFF !important;
              font-family: 'Lexend';
              font-style: normal;
              font-weight: 300;
              font-size: 10px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .applyBtn {
      // display: none;
      width: 94px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #000E14;
      border-radius: 10px;
      cursor: pointer;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */


      color: #219EBC;
      margin-bottom: 80px;

    }

    .tR {
      // display: none;
      display: none;
      position: absolute;

      align-items: flex-end;
      justify-content: center;
      // display: flex;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;
      background: linear-gradient(180.23deg, #002133 23.64%, rgba(33, 158, 188, 0) 116.98%);
      border-radius: 10px;
    }

    &:hover {

      .tR {
        display: flex;
        // background: red;
      }
    }
  }
}

.searchBOX {
  width: 689px;
  height: 48px;
  left: 152px;
  top: 1317px;

  background: #000E14;
  border-radius: 10px;
  // display: flex;

  .inputHolder {
    display: flex;
    // width: 100%;
    // width: 689px;
    height: 100%;
    align-items: center;
    padding-right: 20px;

    input {
      width: 689px;
      height: 100%;
      outline: none;
      background: #000E14;
      border: none;
      border-radius: 10px;
      padding-left: 20px;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      /* identical to box height, or 183% */


      color: #FFFFFF;

    }
  }
}

.VerticalTabsMain {
  // background-color: red;
  padding: 10% 5%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .VerticalTabs-l {
    width: 230px;
    position: relative;
    height: 586px;
    // background-color: red;

    svg {
      position: absolute;
      bottom: -10%;

      left: -50%;
    }

    h1 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* or 111% */

      text-transform: capitalize;

      color: #FFFFFF;

      span {
        color: #219EBC;

      }
    }

    p {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */


      color: #FFFFFF;
      margin-top: 36px;
    }
  }

  .VerticalTabs-r {
    display: flex;

    // border-top: 2px solid #023047;
    .VerticalTabs-Component {
      cursor: pointer;
      padding: 36px;
      box-sizing: border-box;
      width: 150px;
      height: 586px;
      background: linear-gradient(180deg, #002133 0%, #00141F 100%);
      border: 2px solid #023047;
      border-radius: 20px;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      z-index: 1;
      padding-left: 20px;

      &.active {
        width: 574px;
        z-index: 5;
        position: relative;
        // left: 25px;

        // border-radius: 20px ;
        .VerticalTabs-Component-t {
          flex-direction: row;

          h1 {
            transform: rotate(0deg);
            top: 0px;
            margin-left: 10px;
            color: #00A5CC;
          }


        }

        .VerticalTabs-Component-b {
          p {

            display: block !important;
            opacity: 1;
          }
        }
      }

      .VerticalTabs-Component-t {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        flex-direction: column;

        h1 {
          min-width: 290px;
          transform: rotate(90deg);
          position: relative;
          top: 150px;
          // margin-left: 10px;
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 33px;
          /* or 138% */
          text-transform: capitalize;
          color: #FFFFFF;

          transition: all 0.3s ease-in-out;

        }
      }

      .VerticalTabs-Component-b {
        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          /* or 225% */


          color: #FFFFFF;
          // display: none;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
.sectionOneMain {
  // height: 200vh;
  background-color: #00141f;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .bgCircleCon {
    position: absolute;
    top: 0;
    right: -1vw;
    pointer-events: none;

    img {
      pointer-events: none;
    }
  }

  h3 {
    margin-top: 13px;
    width: 410px;
    font-family: Lexend;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    /* or 179% */

    color: #ffffff;
  }

  .sectionOneMainTop {
    height: 100vh;
    // width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;

    .socialLinks {
      position: absolute;
      left: 95vw;
      bottom: 50%;
      transform: translateY(-50%);
      z-index: 10;

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          list-style: none;
          transition: all 0.5s ease;

          &:hover {
            transform: scale(1.1);
          }

          a {
            font-family: Lexend;
            font-weight: 500;
            color: white;
            margin: 0px 0px 0px 20px;
            text-decoration: none;
          }
        }

        .circle {
          display: flex;
          margin-left: 20px;
          color: white;
          height: 4px;
          width: 4px;
          border-radius: 50%;
          background-color: white;
        }
      }
    }

    .rotate {
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      transform: translateX(-50%) translateY(100%) rotate(-90deg);
    }

    // background-color: #54b4e7;
    .sectionOneMainTopInnnerLeft {
      // background-color: rgba(236, 122, 122, 0.411);
      width: 520px;
    }

    .sectionOneMainTopBTMline {
      display: flex;

      align-items: center;
      padding: 0 10vw;
      position: relative;

      .tcon {
        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          text-align: center;

          color: #ffffff;
        }

        i {
          color: #fff;
          margin-left: 10px;
        }
      }

      .btmImgConS1 {
        position: absolute;
        right: 8vw;
        bottom: -6vh;
        z-index: 5;
        .LogoSpin {
          animation-name: spinn;
          animation-duration: 50s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .LogoSpinTxt {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @keyframes spinn {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }

    h1 {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 72px;
      /* or 150% */
      
      letter-spacing: 0.01em;
      
      color: #FFFFFF;
      span {
    
font-family: 'Archivo';
font-style: normal;
font-weight: 800;
font-size: 48px;
line-height: 72px;
/* or 150% */

letter-spacing: 0.01em;

color: #219EBC;
      }
    }

    h4 {
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      // text-align: center;

      color: #ffffff;
    }

    .sectionOneMainTopInnner {
      height: 80vh;
      margin: 0 10vw;
      margin-top: 10vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // background-color: #415e6d;
position: relative;

.keysvg {
  position: absolute;
  bottom: -20%;
  left: -300px;
}
      .imgCon {
        height: 596px;
        width: 630px;
        // background-color: #fff;
        position: relative;

        .oneI {
          opacity: 0;
        }

        .twoI {
          opacity: 0;
        }

        .threeI {
          opacity: 0;
        }

        // all position logics
        &.one {
          .oneI {
            opacity: 1;
          }

          .twoI {
            opacity: 0;
          }

          .threeI {
            opacity: 0;
          }
        }

        &.two {
          .oneI {
            opacity: 0;
          }

          .twoI {
            opacity: 1;
          }

          .threeI {
            opacity: 0;
          }
        }

        &.three {
          .oneI {
            opacity: 0;
          }

          .twoI {
            opacity: 0;
          }

          .threeI {
            opacity: 1;
          }
        }
      }

      img {
        height: 596px;
        width: 630px;
        object-fit: cover;
        animation-duration: 5000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      .imO {
        position: absolute;
        clip-path: inset(20% 32% 0 34%);
        transition: all 0.5s ease;

        &:hover {
          clip-path: inset(10% 32% 10% 34%);
        }

        animation-name: aniOne;
      }

      .imT {
        clip-path: inset(5% 70% 25% 0);
        top: 0;
        left: 0;
        position: absolute;
        transition: all 0.5s ease;

        &:hover {
          clip-path: inset(10% 70% 15% 0);
        }

        animation-name: aniTwo;
      }

      .imTh {
        clip-path: inset(0 0 25% 70%);
        top: 0;
        left: 0;
        position: absolute;
        transition: all 0.5s ease;

        &:hover {
          clip-path: inset(10% 0 15% 70%);
        }

        animation-name: aniThree;
      }

      //start
      @keyframes aniOne {
        0% {
          clip-path: inset(20% 32% 0 34%);
        }

        50% {
          clip-path: inset(10% 32% 10% 34%);
        }

        100% {
          clip-path: inset(20% 32% 0 34%);
        }
      }

      @keyframes aniTwo {
        0% {
          clip-path: inset(5% 70% 25% 0);
        }

        50% {
          clip-path: inset(10% 70% 15% 0);
        }

        100% {
          clip-path: inset(5% 70% 25% 0);
        }
      }

      @keyframes aniThree {
        0% {
          clip-path: inset(0 0 25% 70%);
        }

        50% {
          clip-path: inset(10% 0 15% 70%);
        }

        100% {
          clip-path: inset(0 0 25% 70%);
        }
      }

      // end
    }
  }

  .sectionOneMainBotm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    img {
      width: 410px;
      height: 500px;
    }

    .sectionOneMainBotmL {
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        .soBl1 {
          // width: 204px;
        }
      }
      flex: 1;

      .animatedImgCon {
        position: relative;
      }
      .soBl1 {
        position: absolute;
        // width: 205px;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 126px;
        right: 421px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(162deg);
        // border: 2px solid #219EBC;
      }
      .soBl2 {
        position: absolute;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 63px;
        right: 227px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(270deg);

        // border: 2px solid #219EBC;
      }

      .soBl3 {
        position: absolute;
        // width: 203px;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 270px;
        right: 227px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(344deg);

        // border: 2px solid #219EBC;
      }
      .soBl4 {
        position: absolute;
        // width: 199px;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 325px;
        right: 421px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(450deg);

        // border: 2px solid #219EBC;
      }

      .soB2l1 {
        position: absolute;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 375px;
        right: 431px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(167deg);

        // border: 2px solid #219EBC;
      }
      .soB2l2 {
        position: absolute;
        width: 0px;
        height: 2px;
        background-color: #219ebc;
        top: 312px;
        right: 165px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(270deg);

        // border: 2px solid #219EBC;
      }
      .soB3l1 {
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #219ebc;
        top: 337px;
        right: 129px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(89deg);

        // border: 2px solid #219EBC;
      }

      .soB3l2 {
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #219ebc;
        top: 222px;
        right: 132px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(158deg);

        // border: 2px solid #219EBC;
      }

      .soB3l3 {
        position: absolute;
        width: 0;
        height: 2px;
        background-color: #219ebc;
        top: 189px;
        right: 54px;
        transform-origin: right;
        // transition: all 0.5s ease;
        transform: rotate(269deg);

        // border: 2px solid #219EBC;
      }
    }

    .sectionOneMainBotmR {
      flex: 1;

      .sectionOneMainBotmRInner {
        width: 70%;

        h1 {
          width: 520px;
          font-family: Archivo;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 58px;
          /* or 161% */

          letter-spacing: 0.01em;
          text-transform: capitalize;

          color: #ffffff;
        }

        h3 {
          width: 520px;
          font-family: Lexend;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */

          color: #ffffff;
        }
      }
    }

    h1 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 58px;
      /* or 161% */

      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #ffffff;
    }
  }
}

.MainGetStartedBTN {
  margin-top: 30px;
  width: 190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #219ebc;
  border: 1.5px solid #219ebc;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s ease;

  &:hover {
    background-color: #219ebc;
    color: #fff;
    cursor: pointer;
  }
}

.MainGetStartedBTNNB {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #219ebc;
  border: none;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);

  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.1);
    color: #fff;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1185px) {
  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner .imgCon {
    width: 508px;
    height: 487.03px;

    img {
      width: 508px;
      height: 487.03px;
    }
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerRight
    > div
    > img.imO {
    height: 500px;
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerLeft {
    width: 350px;

    h1 {
      font-size: 28px;

      span {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15vh;
  }
  .sectionOneMain .sectionOneMainBotm {
    flex-direction: column;
  }
  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerRight {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopBTMline
    > div.btmImgConS1 {
    .LogoSpin {
      height: 150px;
    }

    .LogoSpinTxt {
      height: 70px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner .imgCon {
    width: 323.35px;
    height: 310px;

    img {
      width: 323.35px;
      height: 310px;
    }
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerRight
    > div
    > img.imO {
    height: 500px;
  }
 

  .sectionOneMain .sectionOneMainTop {
    // height:auto;
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner {
    // height:auto;
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerLeft {
    width: auto;

    h1 {
      font-size: 36px;
      line-height: 48px;
      /* or 161% */

      letter-spacing: 0.01em;

      span {
        font-size: 36px !important;
        line-height: 58px !important;
        /* or 161% */

        letter-spacing: 0.01em;
      }
    }

    h3 {
      margin-top: 10px;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }
  .sectionOneMain h3 {
    width: 100%;
  }
  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopInnner
    > div.sectionOneMainTopInnnerRight
    > div
    > img.imO {
    height: 307px;
  }

  .sectionOneMain .sectionOneMainTop .rotate {
    display: none;
  }

  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner {
    height: 70vh;
    margin: 0 10vw;
    margin-top: 15vh;
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopBTMline {
    bottom: -48vh !important;
  }

  #root
    > div
    > div
    > div
    > div
    > div.sectionOneMain
    > div.sectionOneMainTop
    > div.sectionOneMainTopBTMline
    > div.btmImgConS1 {
    right: -0px;
    bottom: -100px;

    .LogoSpin {
      height: 150px;
    }

    .LogoSpinTxt {
      height: 70px;
    }
  }
}
@media only screen and (max-width: 325px){
  .sectionOneMain .sectionOneMainTop .sectionOneMainTopInnner .imgCon img{
    width: 275px;
  }
}
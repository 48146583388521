.SecSixNewMain {
  height: 62.5vh;
  // background-color: rgba(211, 59, 59, 0.705);
  display: flex;
  align-items: center;
  justify-content: center;

  .inviBtnCon {
    display: none;
  }

  .SecSixNewMainW {
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .SecSixNewMainWL {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .s6tc {
        margin-left: 5vh;
        position: relative;

        .quotesI {
          position: absolute;
          top: 0;
          left: 70%;
          transform: translate(-50%, -50%);
          z-index: 5;
        }

        h3 {
            width: 410px;
          position: relative;
          z-index: 10;
          font-family: Archivo;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 40px;
          /* identical to box height, or 167% */

          text-transform: capitalize;

          color: #ffffff;
        }

        p {
          position: relative;
          z-index: 10;
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */

          color: #ffffff;
        }
      }
    }

    .SecSixNewMainWR {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // background-color: #fff;
      overflow: hidden;
      .NoSS6C {
        position: relative;
        z-index: 10;
        display: flex;
      }
      .topLane {
        // width: 90vw;
        position: relative;
        left: 12vw;
        z-index: 8;
        margin-bottom: 15px;
        display: flex;
        transition: all 0.5s ease-in-out;

        // background-color: #fff;
        &.one {
          left: 12vw;
        }

        &.two {
          left: -16vw;
        }

        &.three {
          left: 12vw;
        }

        &.four {
          // left: -16vw;
        }
      }

      .botmLane {
        margin-top: 15px;
        position: relative;

        z-index: 8;
        margin-bottom: 15px;
        display: flex;
        transition: all 0.7s ease-in-out;

        &.one {
          left: 15vw;
        }

        &.two {
          left: -12vw;
        }

        &.three {
          left: 15vw;
        }

        &.four {
          // left: -12vw;
        }
      }

      .bImg {
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 7;
      }
    }
  }
}

.BtnCons6n {
  .aLimg {
    margin-right: 20px;
    transform: rotate(180deg);
    opacity: 0.5;
    transition: all 0.2s ease;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .aRimg {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1195px) {
  .SecSixNewMainWR {
  }
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWL .s6tc .quotesI{
    left: 40%;
    top: -40px;
  }
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWR .bImg{
      width: 100%;
  }
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWL{
      width: 40%;
  }
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWR{
      align-items: center;
      justify-content: center;
      width: 70%;

  }
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWR .NoSS6C{
    flex-direction: column;
    .divC{
        margin-bottom: 40px;
    }
}
  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWL {
    margin-right: 50px;
  }

  #root
    > div
    > div
    > div
    > div
    > div.SecSixNewMain
    > div
    > div.SecSixNewMainWR {
    // align-items: flex-start;

    .topLane {
      // left: 4vw;
      transition: all 0.7s ease-in-out;

      &.one {
        left: 12vw;
      }

      &.two {
        left: -16vw;
      }

      &.three {
        left: -46vw;
      }
    }

    .botmLane {
      // left: 12vw;
      z-index: 8;

      transition: all 0.9s ease-in-out;

      &.one {
        left: 15vw;
      }

      &.two {
        left: -12vw;
      }

      &.three {
        left: -43vw;
      }
    }
  }

  .bImg {
    width: 80%;
    height: 100%;
  }
}

@media only screen and (max-width: 770px) {


 

  .SecSixNewMain {
    height: auto;

    .inviBtnCon {
      display: flex;
      width: 100vw;
      margin-top: 30px;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    .dvBTNs6 {
      display: none;
    }
  }

  .sliderConMMMM {
    display: flex;
    flex-direction: row;
  }

  #root > div > div > div > div > div > div > div.SecSixNewMainWR > img {
    width: 100%;
    // height: calc("500px");
  }

  .SecSixNewMain .SecSixNewMainW {
    flex-direction: column;
  }

  #root > div > div > div > div > div > div > div.SecSixNewMainWL > div {
    margin-left: 0;
  }

  #root > div > div > div > div > div > div > div.SecSixNewMainWL {
    width: 80%;
    margin-right: 0px;
  }

  .SecSixNewMain .SecSixNewMainW .SecSixNewMainWR {
    width: 100%;
    margin-top: 20px;

    // flex-direction: row;
    // background-color: #fff;
  }

  #root
    > div
    > div
    > div
    > div
    > div.SecSixNewMain
    > div
    > div.SecSixNewMainWR {
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    transition: all 0.5s ease;

    &.one {
      left: 0px;
    }

    &.two {
      // left: -110px;
    }

    &.three {
      // left: -210px;
    }

    .topLane {
      // left: 4vw;
      transition: all 0.7s ease-in-out;
      margin-bottom: 0;
      margin-top: 30px;

      &.one {
        // left: 0px;
      }

      &.two {
        left: -340px;
      }

      &.three {
        left: -710px;
      }

      &.four {
        left: -1080px;
      }

      &.five {
        left: -1470px;
      }

      &.six {
        left: -1840px;
      }
    }

    .botmLane {
      margin-top: 30px;
      // left: 12vw;
      z-index: 8;
      // display: none;
      transition: all 0.7s ease-in-out;

      &.one {
        // left: 0px;
      }

      &.two {
        left: -340px;
      }

      &.three {
        left: -710px;
      }

      &.four {
        left: -1080px;
      }

      &.five {
        left: -1470px;
      }

      &.six {
        left: -1840px;
      }
    }
  }
}



@media only screen and (max-width: 500px) {

    .SecSixNewMain .SecSixNewMainW .SecSixNewMainWL .s6tc .quotesI{
        top: 0;
    }
    .SecSixNewMain .SecSixNewMainW .SecSixNewMainWL .s6tc h3{
        width: 100%;
    }
    .SecSixNewMain .SecSixNewMainW .SecSixNewMainWR .NoSS6C{
        align-items: center;
        justify-content: center;
    }
}

        .cardNls {
            width: 28.472vw;
            // height: 51.7vh;
           

            background: #043047;
            // border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            margin-bottom: 20px;

            &:hover {
                transform: scale(1.02);
                cursor: pointer;
            }

            .cardNlWrapper {
                
                width: 90%;
                height: 95%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                

                .topNL {
                    // margin-top: 2.5vh;
                    width: 100%;
                    height: 30%;
                    background: #F3F4F4;
                    // background-color: rgb(255, 226, 226);
                    // border-radius: 20px;


                    img {
                        transition: all 0.5s ease;
                        object-fit: cover;
                        overflow: hidden;
                        height: 100%;
                        width: 100%;
                        // border-radius: 20px;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 15px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                    }

                }

                .textNl {
                    .time {
                        h6 {
                            margin: 0;
                            padding: 0;
                            margin-top: 1vh;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            color: #00141F;
                            opacity: 0.5;
                        }
                    }

                    .content {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;
                        
                        h2 {
                            display: block;
                            margin: 0;
                            padding: 0;
                            margin-top: 1.5vh;
                            font-family: Archivo;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 24px;
                            line-height: 40px;
                            text-transform: capitalize;
                            color: #fff;
                        }

                        p {
                            display: block;
                            margin: 0;
                            padding: 0;
                            margin-top: 2vh;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 25px;
                            color: #fff;
                            opacity: 0.5;
                        }
                       
                    }

                    .footerNL {
                        // position: absolute;
                        // bottom: 0;
                        padding-top: 3vh;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 1.5vh;

                        h5 {
                            margin: 0;
                            padding: 0;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 15px;
                            /* identical to box height */


                            color: #219EBC;
                        }

                        h3 {
                            margin: 0;
                            padding: 0;
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 17px;
                            /* identical to box height */


                            color: #fff;
                        }
                        
                        .footerNLLeft {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: space-between;
                        }

                        .propertyBox {
                            width: 73px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background: #053B53;

                            border-radius: 4px;
                            margin-bottom: 1vh;
                        }

                        .footerNLRight {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            background-color: rgb(255, 158, 158);

                            img {
                                transition: all 0.5s ease;
                                object-fit: cover;
                                overflow: hidden;
                                height: 100%;
                            }
                        }
                    }


                }
            }

        }

        
@media only screen and (max-width: 1170px)
{
   
    .cardNls{
        width:95%;
    }
}        
@media only screen and (max-width: 700px)
{
   
    .cardNls{
        width:100%;
    }
}
.footerM {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: relative;
  // background-color: #000E14;
  .footerMainWrapperMOBN {
    display: none;
  }

  //   background-color: #ee7f4c;
  .footerMainWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 60px;

    a {
      color: #fff;
    }

    .top {
      display: flex;
      flex-direction: column;

      h2 {
        color: white;
        margin: 0;
        padding: 0;
        //   font-family: Archivo;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
      }

      ul {
        display: flex;
        padding: 0;
        margin: 20px -20px;

        li {
          list-style: none;
          margin: 0px 20px;

          a {
            color: white;
            text-decoration: none;
            //   font-family: Archivo;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
          }
        }

        .line {
          color: white;
        }
      }
    }

    .mid {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        img {
          width: 25vw;
        }
      }

      .right {
        h2 {
          // font-family: Archivo;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 17px;
          color: #ffffff;
        }

        span {
          // font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;

          color: #ffffff;
        }

        .socialLinks {
          margin-top: 10px;
          display: flex;
          color: #ffffff;
          font-size: 20px;
          align-items: center;

          // justify-content: space-between;
          a {
            margin-right: 10%;
          }
        }
      }
    }

    .end {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        ul {
          padding: 0;
          margin: 20px -20px;
          display: flex;

          li {
            list-style: none;
            margin: 0px 20px;

            a {
              color: white;
              text-decoration: none;
              //   font-family: Archivo;
              font-style: normal;

              font-size: 14px;
              line-height: 15px;
              text-align: center;
            }
          }

          .line {
            color: white;
          }
        }
      }

      .right {
        margin-right: -15px;

        span {
          //   font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          text-align: right;

          color: #ffffff;

          opacity: 0.5;
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .footerM {
    padding: 5vh 0;
  }

  .footerM .footerMainWrapper .top ul {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }

  .footerM .footerMainWrapper .top ul li {
    margin: 0;
  }

  .footerM .footerMainWrapper .top ul {
    margin: 20px 0;
  }

  .footerM .footerMainWrapper .mid .left img {
    width: 60vw;
  }

  .footerM .footerMainWrapper {
    width: 90%;
  }

  .footerM .footerMainWrapper .mid {
    flex-direction: column;
    align-items: flex-start;
  }

  .footerM .footerMainWrapper .end {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .footerM .footerMainWrapper {
    display: none;
  }

  .footerM {
    padding-top: 60px;
  }

  .footerMainWrapperMOBN {
    display: flex !important;
    flex-direction: column;
    align-items: center;

    .dL {
      margin-top: 30px;
      width: 90%;
      height: 3px;
      background: radial-gradient(#ffffff94, #3131330c);
      border-radius: 13px;
    }

    .BFotS {
      h6 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        /* identical to box height, or 183% */

        text-align: center;

        color: #ffffff;

        span {
          color: #ff0000;
        }
      }

      p {
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        /* or 183% */

        text-align: center;
        width: 300px;
        color: #809199;
      }

      .bfttcon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .midDIVL {
          height: 15px;
          width: 2px;
          background-color: #fff;
          margin: 0 10px;
        }

        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }

    .MFotS {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-top: 30px;
        width: 85%;
      }

      .socialLinks {
        margin-top: 20px;
        display: flex;
        color: #ffffff;

        font-size: 24px;
        align-items: center;

        // justify-content: space-between;
        a {
          color: #ffffff;
          margin-right: 10%;
        }
      }

      h3 {
        font-family: Archivo;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        margin-top: 30px;

        color: #ffffff;
      }

      h4 {
        margin-top: 10px;
        width: 300px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 23px;
        /* or 192% */

        text-align: center;

        color: #ffffff;
      }
    }

    .TSec {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;

      .TLi {
        font-family: Archivo;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height */

        color: #ffffff;
        margin-bottom: 10px;
      }

      .MLi {
        display: flex;
        width: 60%;
        justify-content: space-between;

        .MLiL {
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            margin-top: 30px;
            font-family: Archivo;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            text-decoration: none;
            color: #809199;
          }
        }

        .MLiR {
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            margin-top: 30px;
            font-family: Archivo;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            text-decoration: none;
            color: #809199;
          }
        }

        p {
          margin: 0;
          padding: 0;
          margin-top: 30px;
          font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          text-align: center;

          color: #809199;
        }
      }

      .BLi {
        margin-top: 30px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        text-align: center;

        a {
          margin-top: 30px;
          font-family: Archivo;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          text-align: center;
          text-decoration: none;
          color: #809199;
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px){
  .footerM{
    .footerMainWrapper{
      .mid{
        .left{
          img{
            width: 410px;
          }
        }
      }
    }
  }
}
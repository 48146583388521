.NSecTwoAPMain {
    margin-top: 20vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;

    .NSecTwoAPMainL {
        display: flex;
        flex-direction: column;

        margin-left: 5vw;

        h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: bold;
            font-size: 42px;
            line-height: 67px;
            /* or 160% */
            width: 300px;
            letter-spacing: 0.01em;
            text-transform: capitalize;

            color: #FFFFFF;
        }

        p {
            font-family: Lexend;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */
            width: 300px;
            color: #7F898F;
        }
    }

    .NSecTwoAPMainR {
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        margin-right: 5vw;

        .CardSAp {
            width: 300px;
            height: 470px;

            background: #023047;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .bgIc {
                position: absolute;
                top: 0;
                z-index: 5;
            }

            .ad {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 10;
            }

            .cTcon {
                position: absolute;
                bottom: 35%;
                transform: translateY(50%);
                z-index: 6;

                h2 {

                    font-family: Archivo;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 36px;
                    /* identical to box height, or 150% */

                    text-transform: capitalize;

                    color: #FFFFFF;
                }

                p {
                    font-family: Lexend;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 25px;
                    /* or 179% */
                    width: 260px;

                    color: #FFFFFF;
                }
            }
        }
    }
}

@media only screen and (max-width: 1195px) {
    .NSecTwoAPMain {
        // transform: scale(0.8);
    }

    .NSecTwoAPMain .NSecTwoAPMainR {}

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp {
        width: 250px;
        height: 450px;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp .bgIc {
        width: 250px;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp .cTcon {
        padding: 0 10px;

        p {
            width: auto;
        }
    }
}

@media only screen and (max-width: 770px) {
    .NSecTwoAPMain {
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .NSecTwoAPMain .NSecTwoAPMainL {
        width: 100vw;
        margin-bottom: 60px;

        h2 {
            width: auto;
        }

        p {
            width: 90vw;
        }

        .MainGetStartedBTN {
            margin-top: 10px;
        }
    }

    .NSecTwoAPMain .NSecTwoAPMainR {
        // flex-direction: column;
        display: grid;
        grid-template-columns: auto auto;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp {
        width: 300px;
        height: 470px;
        top: auto !important;
        margin: 20px 0;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp .bgIc {
        width: 300px;
    }
}

@media only screen and (max-width: 500px) {
    .NSecTwoAPMain .NSecTwoAPMainR {
        grid-template-columns: auto;
        width: auto;
        margin-right: 0;
    }

    .NSecTwoAPMain .NSecTwoAPMainL {
        width: 80vw;
        margin-left: 0;

        h2 {
            width: 300px;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 58px;
            /* identical to box height, or 161% */

            letter-spacing: 0.01em;
            text-transform: capitalize;
        }

        p {
            width: 90%;
        }

        .MainGetStartedBTN {
            width: 100%;
        }
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp {
        width: 325px;
        height: 446px;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp .bgIc {
        width: 325px;
        height: 220px;
    }

    .NSecTwoAPMain .NSecTwoAPMainR .CardSAp {
        &.card2 {
            .cTcon {
                position: relative;
                top: 0px;
            }
        }
    }
}



@media only screen and (min-width: 1445px) {
    .NSecTwoAPMain .NSecTwoAPMainL h2{
        // display: none;
        font-size: 50px;
        width: auto;
    }
    .NSecTwoAPMain .NSecTwoAPMainL p{
        font-size: 18px;
        line-height: 25px;
        width: 400px;
    }
  
    .NSecTwoAPMain{
        justify-content: space-between;
    }
    .NSecTwoAPMain .NSecTwoAPMainR{
        width: 60%;
    }
}

.FounderPhotoMainComp {
  padding: 5vh 0;
  height: 60vh;
  margin-bottom: 19vh;
  background: #00141F;

  .FounderPhotoMainCompCon {
    width: 100%;
    height: 100%;
    display: flex;

    .FPTT {
      display: none;
      margin-left: 10vw;

      h3 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 58px;
        /* identical to box height, or 161% */

        letter-spacing: 0.01em;
        text-transform: capitalize;

        color: #ffffff;
      }
    }

    .left {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 5vw;

      // height: 60vh;
      // position: relative;
      .imgcon {
        display: flex;
        // height: 472px;
        min-height: 472px;
        height: 100%;
        align-items: flex-end;
        max-width: 630px;
        width: 100%;
        padding: 0vh 0.1vw;
        justify-content: space-between;
        // transition: all 5s ease;

        //   bottom: 0;
        //   left: 0;
        .imgInCon {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ImgSC {
          display: flex;
          justify-content: center;
          flex-direction: column;
          // border: 1px solid red;
          position: relative;
          height: 50%;
          width: 190px;
          transition: all 0.5s ease;
          // background: black;
          background-image: linear-gradient(181deg, #00141f, #3d3f41);
          align-items: center;
          overflow: hidden;
          position: relative;

          .blueOverlay {
            // blue overlay
            position: absolute;
            display: none;

            width: 100%;
            height: 100%;
            background: #055f8f7e;
            background-blend-mode: color;
            opacity: 0.8;
            transition: all 0.5s ease;
            pointer-events: none;
          }

          .img {
            // position: absolute;
            // right:5%;
            // top: 10%;
            width: 90%;
            transition: all 0.5s ease;
            object-fit: contain;
            transition: all 0.5s ease;
            // overflow: hidden;
            // height: 100%;
            // width: 120%;

            position: relative;
            animation-play-state: paused;
          }

          .staticImg {
            position: absolute;
            transition: all 0s ease;
            object-fit: contain;
            overflow: hidden;
            height: 100%;
            width: 120%;
            pointer-events: none;
          }

          .imgWdth {
            // right:0%;
            width: 90%;
          }

          .imgS {
            //for signature

            width: 10vw;
            position: absolute;
            bottom: -2%;
            right: -25%;
            opacity: 0;
            z-index: 10;
            pointer-events: none;
          }

          &.active {
            cursor: pointer;
            height: 90%;
            // box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            // overflow: hidden;
            overflow: visible;
            background-image: linear-gradient(180deg,
                rgba(33, 158, 188, 0) 0%,
                #219ebc 100%);

            .imgS {
              opacity: 1;
            }

            .img {
              // position: absolute;
              // right:5%;
              // top: 10%;
              width: 105%;
              object-fit: contain;
            }

            .staticImg {
              opacity: 0;
            }

            .blueOverlay {
              opacity: 0;
            }
          }
        }

        img {
          // height: 250px;
          // width:190px;
          transition: all 0.5s ease;
        }
      }
    }

    .right {
      flex: 1.2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .rightWrapper {
        // text-align: justify;
        width: 60%;

        h2 {
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
        }

        span {
          font-family: Lexend;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          color: #ffffff;
        }

        .socialLinks {
          ul {
            display: flex;
            padding: 0;
            margin: 10px 0px;

            li {
              list-style: none;
              transition: all 0.4s ease;

              a {
                margin-right: 15px;
                color: #219ebc;
                text-decoration: none;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
              }

              &:hover {
                transform: scale(1.3);
                cursor: pointer;
              }
            }
          }
        }
      }

      img {
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: -1;
      }
    }
  }
}

@media only screen and (max-width: 1195px) {
  .FounderPhotoMainComp .FounderPhotoMainCompCon {
    .left {
      flex: 1.4;
    }

    .right {
      // flex: 0.5;

      .rightWrapper {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 1030px) {
  .FounderPhotoMainComp .FounderPhotoMainCompCon {
    .right {
      flex: 0.5;

      .rightWrapper {
        width: 80%;
      }
    }
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC {
    margin: 0 1vw;
  }
}

@media only screen and (max-width: 970px) {
  .FounderPhotoMainComp {
    min-height: 90vh;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon {
    flex-direction: column;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon {
    .right {
      flex: 0.5;
      margin-top: 30px;
    }
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC .imgS {
    transform: scale(2.5);

    &.lastS {
      right: 35%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC {
    transform: scale(0.8);
    // margin-bottom: 30px;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .right .rightWrapper {
    margin-top: 30px;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC.active {
    transform: scale(1.2);
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .right img {
    display: none;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .FPTT {
    display: flex;
  }

  .FounderPhotoMainComp {
    min-height: auto;
    height: auto;
    width: 100vw;
    position: relative;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left {
    padding-left: 0;
    flex: none;
    transition: all 0.5s ease;
    position: relative;
    // left: -30px;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon {
    height: 270px;
    min-height: 270px;
    position: relative;
    left: -0px;
    transition: all 0.5s ease;

    &.three {
      left: -100px;
    }

    &.one {
      left: 80px;
    }

    // overflow-x: visible;
    // overflow-y: visible;
    // width:130%;
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC {
    width: 140px;
    height: 200px;

    &.active {
      width: 140px;
      height: 250px;
    }
  }

  .FounderPhotoMainComp .FounderPhotoMainCompCon .left .imgcon .ImgSC .imgS {
    transform: scale(3.5);

    &.lastS {
      right: 15%;
    }
  }
}
.sectionThreeAP {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 140px;

  .sectionThreeAPCon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80vw;

    .tconS3ap {
      width: 410px;
      position: relative;
      left: -160px;
      // margin-left: 115px;
    }
    .DnaIMGCON {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 410px;
      // background-color: rgb(233, 68, 68);
      position: relative;
      left: -80px;
      .dnaSImg {
        z-index: 2;
        position: absolute;
        animation-name: DNASPIN;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      @keyframes DNASPIN {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    .dnaImg {
      position: relative;
      z-index: 1;
      // width: 100%;
      position: relative;
      // left: -80px;
    }

    h3 {
      font-family: Lexend;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      letter-spacing: 0.1em;

      color: #219ebc;
    }

    h2 {
      width: 410px;
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      /* identical to box height, or 167% */
      text-transform: capitalize;
      color: #ffffff;
    }

    p {
      width: 410px;
      font-family: Lexend;
      // font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* or 179% */
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 1195px) {
  #root
    > div
    > div
    > div
    > div
    > div.sectionOneAP
    > div.sectionThreeAP
    > div
    > div {
    left: auto;
  }
}

@media only screen and (max-width: 775px) {
  .sectionThreeAP {
    padding-bottom: 50px;
  }
  
  .sectionThreeAP .sectionThreeAPCon h2{
    width: auto;
  }
  .sectionThreeAP .sectionThreeAPCon .tconS3ap{
    width: 80vw;
    margin-left: 5vw;
  }
  #root
    > div
    > div
    > div
    > div
    > div.sectionOneAP
    > div.sectionThreeAP
    > div
    > div {
    margin-top: 30px;
  }

  .sectionThreeAP .sectionThreeAPCon {
    flex-direction: column;
  }
  .dnaImg {
    // display: none;
    left: auto !important;
  }
}

@media only screen and (max-width: 500px) {
  #root
    > div
    > div
    > div
    > div
    > div.sectionOneAP
    > div.sectionThreeAP
    > div
    > img {
    width: 320px;
  }
  .sectionThreeAP .sectionThreeAPCon .DnaIMGCON{
    transform: scale(0.74);
    margin-bottom: -20px;
  }
  .sectionOneAP .bgCircleCon{
    // display: none;
  }
  .sectionThreeAP .sectionThreeAPCon .tconS3ap{
    // width: 80vw;
    margin-left: 0vw;
  }
  .sectionThreeAP .sectionThreeAPCon .tconS3ap {
    width: 100%;

    h2 {
      width: 100%;
    }

    p {
      width: 100%;
    }
  }
}

.teamMain {
    // height: 100vh;
    background-color: #012233;

    .teamMainTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;

        .umhcL {
            display: none;
        }

        h3 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 40px;
            /* identical to box height, or 167% */
            margin-left: 10vw;
            text-transform: capitalize;
            color: #FFFFFF;
        }

        h4 {
            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */
            margin-right: 10vw;
            width: 520px;
            color: #FFFFFF;

        }
    }

    .teamMainBtm {
        height: 60vh;

        padding-bottom: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .teamNameCon {

            width: 90vw;

            // background-color: rgb(241, 137, 137);
            .TopLane {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 500px;
            }

            .TCard {
                width: 300px;
                height: 300px;
                border-radius: 50%;
                border: 2px solid #fff;
                position: relative;
                transition: all 0.5s ease;

                &:hover {
                    cursor: pointer;
                    height: 500px;
                    border-radius: 0%;
                    border: 2px solid rgba(255, 255, 255, 0);

                    background: #023047;

                    .bgBlur {
                        opacity: 0;
                    }

                    .TCardIN {
                        .TCcon {
                            opacity: 1;
                        }

                        .Mimg {
                            transform: scale(1);
                            opacity: 1;

                        }

                        .Dimg {
                            opacity: 0;
                        }
                    }
                }

                .bgBlur {
                    z-index: 1;
                    width: 310px;
                    height: 310px;
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    opacity: 1;
                    transition: all 0.5s ease;
                    background-image: linear-gradient(0deg, rgba(255, 0, 0, 0) 10%, #012233);
                }


                .TCardIN {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;

                    .Mimg {
                        position: absolute;
                        top: 0;
                        transform: scale(0.5);
                        opacity: 0;
                        transition: all 0.5s ease;

                    }

                    .Dimg {
                        width: 130px;
                        transition: all 0.5s ease;
                    }

                    .TCcon {
                        width: 275px;
                        position: absolute;
                        bottom: 0;
                        opacity: 0;
                        transition: all 0.5s ease;

                        h2 {
                            font-family: Archivo;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 24px;
                            line-height: 36px;
                            /* identical to box height, or 150% */

                            text-transform: capitalize;

                            color: #FFFFFF;
                        }

                        h3 {
                            font-family: Lexend;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 25px;
                            /* or 179% */


                            color: #FFFFFF;

                        }

                        .sLink {
                            display: flex;
                            align-items: center;

                            &:hover {
                                a {
                                    font-size: 14px;
                                }
                            }

                            // justify-content: center;
                            .circleD {
                                width: 4px;
                                height: 4px;
                                background: #219EBC;
                                border-radius: 50%;
                                margin: 0 5px;
                                margin-bottom: 10px;
                            }

                            a {
                                transition: all 0.5s ease;
                                margin: 0;
                                padding: 0;
                                margin-top: 10px;
                                margin-bottom: 20px;
                                font-family: Lexend;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 15px;
                                /* identical to box height */
                                text-decoration: none;
                                text-align: center;

                                color: #219EBC;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 770px) {
    .teamMain .teamMainTop {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10vw;

        .umhcL {
            margin-top: 5px;
            display: flex;
        }

        h3 {
            margin-left: 0;
            font-size: 30px;
            line-height: 40px;
        }

        h4 {
            width: auto;
            font-size: 14px;
            line-height: 25px;
            margin-top: 10px;
            margin-left: 0;
        }
    }

    .teamMain .teamMainBtm {
        margin-top: 60px;
        height: auto;
        align-items: flex-start;
    }

    .teamMain .teamMainBtm .teamNameCon .TopLane {
        height: auto;
    }

    #root>div>div>div>div>div.teamMain>div.teamMainBtm>div>div {
        display: grid;
        grid-template-columns: auto auto;

        .L-TCard {
            margin-top: 30px;
        }
    }
}

@media only screen and (max-width: 500px) {

    #root>div>div>div>div>div.teamMain>div.teamMainBtm>div>div {
        display: grid;
        grid-template-columns: auto;

        .L-TCard {
            margin-top: 0px;
        }

        .TCard {
            margin-top: 30px;
        }
    }
}
.HeaderMain {
  display: flex;
  position: fixed;
  width: 100%;
  // width: 90vw;
  height: 80px;
  // border-bottom: 1px solid #ffffff31;
  justify-content: space-between;
  z-index: 20;
  align-items: center;
  background: linear-gradient(
    180deg,
    #00141f00 0%,
    rgba(0, 20, 31, 0) 54.87%,
    rgba(0, 20, 31, 0) 100%
  );
  transition: all 0.5s ease;
  &.hoverANI {
    background: linear-gradient(
      180deg,
      #00141f 0%,
      rgba(0, 20, 31, 0.564989) 54.87%,
      rgba(0, 20, 31, 0) 100%
    );
  }
  .LogoB {
    transition: all 0.5s ease;
  }

  &.active {
    height: 40px;
    background-color: rgb(26, 24, 24);

    .LogoB {
      filter: invert(1);
    }

    .left {
      img {
        transform: scale(0.6);
      }
    }

    .mid {
      ul {
        margin: 0;

        li {
          a {
            // color: #fff !important;
          }
        }
      }
    }

    .right {
      a {
        button {
          height: 30px;
          border-radius: 12px;
        }
      }
    }
  }

  &.blankMain {
    position: absolute;
  }

  .left {
    margin-left: 50px;
    z-index: 20;
    transition: all 0.5s ease;
    img {
      transition: all 0.5s ease;
    }
  }

  .mid {
    display: flex;

    ul {
      display: flex;
      text-decoration: none;
      align-items: center;
      margin: 0;
      padding: 0;
      position: relative;
      left: -30px;

      .divCircle {
        margin-top: 3px;
        width: 4px;
        height: 4px;
        background: #ffffff;
        border-radius: 50%;
      }

      li {
        text-decoration: none;
        list-style: none;

        &.active {
          a {
            color: #219ebc;

            &:before {
              width: 100%;
            }
          }
        }

        a {
          color: white;
          text-decoration: none;
          margin: 0 15px;
          position: relative;
          font-family: Lexend;
          font-weight: 500;
          font-size: 15px;
          transition: all 0.2s ease-in-out;

          &:before {
            content: "";
            width: 0%;
            position: absolute;
            bottom: -20%;
            left: 0%;
            background-image: radial-gradient(#219ebc, #1a1c1d);
            border: none;
            height: 2px;
            transition: 0.3s all;
          }

          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .right {
    margin-right: 50px;

    button {
      height: 60px;
      width: 159px;
      border-radius: 30px;
      background-color: rgba(255, 0, 0, 0);
      color: #219ebc;
      border: 1.5px solid #219ebc;
      font-size: 16px;
      font-family: Lexend;
      font-weight: 600;
      transition: all 0.4s ease;

      &:hover {
        color: white;
        background-color: #20839c;
        cursor: pointer;
      }
    }
  }

  .HamMenu {
    width: 56px;
    height: 56px;
    border-radius: 30%;
    background: #002133;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 20;
    margin-right: 20px;
    transition: all 0.5s ease;
    .line {
      margin: 2px 0;
      width: 23px;
      height: 2px;
      border-radius: 50px;
      background-color: white;
      transition: all 0.5s ease;
      position: relative;
    }

    &.active {
      background: #fff;
      .line {
        background-color: #219ebc;
        margin: 0;
        width: 23px;

        &.Two {
          opacity: 0;
        }

        &.One {
          top: 5%;
          transform: rotate(45deg);
        }

        &.Three {
          bottom: 1%;

          transform: rotate(-45deg);
        }
      }
    }
  }

  //mob menu
  .mobHead {
    position: absolute;
    // z-index: 100;
    top: 0%;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #023047;
    transition: all 0.5s ease;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    .bottomMob {
      width: 80%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      h6 {
        font-family: Lexend;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 21px;
        /* or 175% */

        text-align: center;
        color: rgba(255, 255, 255, 0.836);
        margin: 0;
        padding: 1vh 0;
      }

      .socialLinks {
        a {
          font-size: 25px;
          color: #fff;
          margin: 0 20px;
        }
      }
    }

    // .top{
    //   padding-top: 2.5vh;
    //   padding-left: 5vw;
    // }
    ul {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      margin-top: 10vh;
      margin-left: 8vw;
      padding: 0;

      li {
        text-decoration: none;
        list-style: none;
        padding: 1.5vh 0;
        padding-left: 0;
        margin-left: 0;
        text-align: right;
        margin-right: 35px;

        &.active {
          a {
            color: rgb(255, 255, 255);
            &:before {
              width: 100%;
            }
          }
        }
        a {
          font-family: Archivo;
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          line-height: 45px;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          text-decoration: none;
          opacity: 1;
          transition: all 0.2s ease-in-out;
          color: rgba(255, 255, 255, 0);
          font-size: 50px;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #4d6e7e;
          position: relative;
          &:before {
            content: "";
            width: 0%;
            position: absolute;
            bottom: -10%;
            left: 0%;
            background-color: #219ebc;
            height: 4px;
            transition: 0.3s all;
          }

          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// media queries

@media only screen and (max-width: 1025px) {
  .HeaderMain {
    // transform:scale(0.8);
    padding: 0;
    margin: 0;

    .left {
      transform: scale(0.7);
      margin-left: 0px;
    }

    .mid {
      display: flex;

      ul {
        margin: 0px 0px 0px -30px;
        padding: 0;

        li {
          a {
            margin-right: 10px;
            font-size: 15px;
            transition: all 0.3s ease-in-out;

            &:hover {
            }
          }
        }
      }
    }

    .right {
      margin-right: 30px;

      button {
        height: 40px;
        width: 109px;
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .HeaderMain {
    &.active {
      height: 60px;
      background-color: rgb(0, 0, 0);
      .left {
        img {
          transform: scale(0.8);
        }
        &.active {
          margin-top: 30px;
        }
      }
      .HamMenu {
        &.active {
          margin-top: 30px;
        }
      }
    }
    .mid {
      display: none;
    }

    .HamMenu {
      display: flex;
    }

    .right {
      display: none;
    }
  }
}

.TrustToken {
  @media screen and (max-width: 1240px) {
    &.SecuritiesOne-Main .SecuritiesOne-L p {
      width: auto;
    }
  }

  &.SecuritiesOne-Main {
    @media screen and (max-width: 1240px) and (min-width: 800px) {
      flex-direction: row !important;
    }

    .SecuritiesOne-R img {
      top: -100px;

      @media screen and (max-width: 1240px) and (min-width: 800px) {
        position: absolute !important;
        width: 150% !important;
        height: auto !important;
        top: -50px !important;
        left: -99px !important;
      }
    }
  }

  &-Main {
    margin-top: 30px;

    .Feature {
      padding: 0 15px;
      margin-bottom: 250px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      display: flex;
      text-align: center;
      transition: 0.3s all;

      &-col {
        width: 33.33%;
        padding: 0 15px;

        &:not(.sec) {
          margin-top: 77px;
        }

        &-inner {
          max-width: 250px;
          width: 100%;
          margin: 16px auto 0;

          h3 {
            color: #00a5cc;
            font-weight: 700;
            margin-bottom: 25px;
          }
        }
      }

      &-img {
        position: relative;

        .connector1 {
          position: absolute;
          bottom: 3px;
          left: 64%;
          width: 86%;
        }

        .connector2 {
          position: absolute;
          bottom: 3px;
          right: 64%;
          width: 86%;
        }

        @media screen and (max-width: 1299px) {
          .connector1 {
            bottom: 18px;
            width: 78%;
          }

          .connector2 {
            bottom: 18px;
            width: 78%;
          }
        }

        @media screen and (max-width: 1099px) {
          .connector1,
          .connector2 {
            display: none;
          }
        }
      }

      @media screen and (max-width: 1299px) {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 991px) {
        display: block;
        margin-top: 0;

        &-col {
          width: 100%;

          &-inner {
            max-width: 100%;
          }
        }
      }

      @media screen and (max-width: 720px) {
        &-Desktop {
          display: none;
        }
      }

      &-mob {
        @media screen and (min-width: 720px) {
          display: none;
        }
      }
    }
  }
}

.Feature-col.swiper-slide-next,
.Feature-col.swiper-slide-prev {
  opacity: 0.5;
}

.whitelabel {
  .modifiedInner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 251px;
    flex-direction: column;
  }

  .tokenize-col img {
    top: -30px;
  }

  @media screen and (max-width: 991px) {
    .tokenize-col {
      h3 {
        margin-top: 30px;
      }

      margin: 100px 0;
    }
  }

  .Tcontainer {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    h1 {
    }

    h2 {
    }
  }
}

.tokenize {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #000e14;
  text-align: center;

  &-inner {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    margin-top: 100px;
    justify-content: space-around;
  }

  &-col {
    width: 33.33%;
    position: relative;

    &.sec {
      margin-top: 50px;
    }

    &.third {
      margin-top: 100px;
    }

    &-inner {
      padding: 60px 50px 40px;
      position: relative;
      overflow: hidden;
      background: #002133;
      border-radius: 0px 50px;
      width: calc(100% + 8%);
      margin-left: -4%;

      &:after {
        content: "";
        position: absolute;
        top: 60%;
        left: 30%;
        width: 352px;
        height: 352px;
        background: #219ebc;
        opacity: 0.2;
        filter: blur(200px);
      }
    }

    img {
      z-index: 1;
      margin-bottom: 20px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .tokenize-row {
      // margin-top: 0;

      &.one {
        margin-top: 100px;
      }

      .tokenize-col {
        margin-top: 40px;
      }
    }

    &-row {
      display: block;
    }

    &-col {
      width: 100%;
      padding-bottom: 20px;

      &-inner {
        max-width: 100%;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px){
  
}

.TestimonialCard {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 10vw;
  .CardIcon {
    display: flex;
    transition: all 0.2s ease-in-out;
    .Details {
      display: none;
      .head {
        font-weight: 700;
        font-size: 18px;
      }
      .sub {
        font-size: 14px;
      }
    }
    &:hover {
      transition: all 0.2s ease-in-out;
      margin-left: -20px;
      margin-top: 0 !important;
      .Details {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }
  .slick-slider {
    .slick-list {
      height: 100px;
      width: 90%;
      margin: auto;
    }
    .slick-track {
      height: 100%;
    }
    .slick-slide {
      & > div {
        height: 100%;
      }
      & > div > div {
        height: 100%;
      }
      .CardIcon {
        margin-top: 28px;
      }
    }
  }
}
.TooltipCard {
  padding-bottom: 50px;
  // min-width: 100vw;
  // min-height: 100vh;
  justify-content: center;
  .TestimonialQuote {
    max-width: 70%;
    background: linear-gradient(
      171deg,
      rgba(33, 158, 188, 1) 0%,
      rgba(12, 107, 130, 0) 100%
    );
    border-radius: 20px;
    overflow: hidden;
    padding: 1px;
    height: 137px;
    margin-top: 120px;
    z-index: 1;
    p {
      background-color: #002133;
      border-radius: 22px;
      height: 140px;
      margin-bottom: 0;
      padding: 33px 45px;
    }
  }
  .left {
    position: relative;
    left: 80px;
  }
  .right {
    position: relative;
    right: 80px;
  }
}
.MuiTooltip-arrow {
  width: 80px !important;
  height: 80px !important;
  color: transparent !important;

  &::before {
    content: url("../TestimonialArrow.svg") !important;
    transform-origin: unset !important;
    transform: rotate(0deg) !important;
  }
}

.slick-list {
  .slick-slide.slick-active:hover {
    width: 400px !important;
  }
  .slick-list {
    height: 100px;
  }
}
.TestimonialHead{
  margin-bottom: -3vw;
}
.TestimonialCardMain{
  padding: 10vw 0;
  background: url("../GradianSectionBack.png") no-repeat;
}
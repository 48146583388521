.multitextCon {
    h1 {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 800;
        line-height: 52px;
        letter-spacing: 0.01em;
        color: #FFFFFF;

        span {
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 800;
            line-height: 52px;
            letter-spacing: 0.01em;
            color: #219EBC;
            margin-left: 10px;

        }
    }
}

@media screen and (max-width: 500px){
    .multitextCon {
        h1{
           font-size: 24px !important;
            text-align: left !important;
            line-height: 30px !important;
            span{
                line-height: 30px;
            }
        }
    }
}
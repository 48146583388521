.NSecThreeAPM {
    display: flex;
    flex-direction: column;

    .mobImg {
        display: none;
    }

    .mobbtn {
        display: none;
    }

    .NSecThreeAPMC {
        // height: 80vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MainGetStartedBTNNB {
            margin-top: 30px;
        }

        .NSecThreeAPMCL {
            margin-left: 5vw;
        }

        h2 {
            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-transform: capitalize;

            color: #FFFFFF;

            span {
                color: #219EBC;

            }
        }

        .listCon {
            margin-top: 20px;

            display: flex;

            align-items: flex-start;

            p {
                margin: 0;
                padding: 0;
                margin-top: -3px;
                margin-left: 15px;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */
                width: 481px;

                color: #FFFFFF;
            }
        }

        .ListImCon {
            margin-top: 30px;
            display: flex;
            // width: 560px;

            .ListImConW {
                margin-right: 30px;
            }

            h3 {
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                text-transform: capitalize;
                width: 182px;
                color: #FFFFFF;
            }
        }



        &.sTwo {
            .NSecThreeAPMCR {
                img {

                    width: 711px;
                    height: 569px;
                }
            }

            .NSecThreeAPMCL {
                margin-left: 0vw;
                margin-right: 15vw;
            }

        }
    }
}


@media only screen and (max-width: 1195px) {
    .NSecThreeAPM .NSecThreeAPMC .ListImCon .ListImConW {
        img {
            transform: scale(0.8);
        }
    }

    .NSecThreeAPM .NSecThreeAPMC {
        //    display: none !important;

        &.sTwo {
            .NSecThreeAPMCL {
                position: relative;
                left: -100px;
            }

            .NSecThreeAPMCR {
                flex: 0.5;

                // display: none;
                img {
                    position: relative;
                    left: -100px;
                    transform: scale(0.8)
                }
            }
        }
    }
}




@media only screen and (max-width: 770px) {
    .NSecThreeAPM .NSecThreeAPMC .ListImCon .ListImConW {
        img {
            transform: scale(0.8);
        }
    }

    .NSecThreeAPM .NSecThreeAPMC {
        flex-direction: column;
    }

    .NSecThreeAPM .NSecThreeAPMC .NSecThreeAPMCR {
        img {
            margin-top: -50px;
            position: relative;
            left: 100px;
            transform: scale(0.8);
        }
    }

    .NSecThreeAPM .NSecThreeAPMC .NSecThreeAPMCL {
        margin-left: 0;
    }

    .NSecThreeAPM .NSecThreeAPMC {
        //    display: none !important;

        &.sTwo {
            .NSecThreeAPMCL {
                margin-top: -60px;
                position: relative;
                left: 00px;
            }

            .NSecThreeAPMCR {
                flex: 0.5;

                // display: none;
                img {
                    margin-top: -120px;
                    position: relative;
                    left: -100px;
                    transform: scale(0.8)
                }
            }
        }
    }
}


@media only screen and (max-width: 500px) {
    .NSecThreeAPM {
        .btnHideM {
            display: none;
        }

        .fwb {
            margin: 0;
            padding: 0;
        }

        .dImg {
            display: none;
        }

        .mobImg {
            display: flex;
        }

        .mobbtn {
            display: flex;
            margin-bottom: 50px;
        }
    }

    .NSecThreeAPM .NSecThreeAPMC .NSecThreeAPMCR {
        img {

            // display: none;
        }
    }

    .NSecThreeAPM .NSecThreeAPMC .ListImCon .ListImConW {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        img {
            transform: scale(1);
            width: 70px;
            height: 70px;
            margin-right: 10px;
            margin-top: 10px;

        }

        h3 {

            width: auto;
        }
    }

    .NSecThreeAPM .NSecThreeAPMC .ListImCon {
        flex-direction: column;
    }

    .NSecThreeAPM .NSecThreeAPMC .NSecThreeAPMCL {
        width: 90vw;
    }

    .NSecThreeAPM .NSecThreeAPMC {
        //    display: none !important;

        &.sTwo {
            .NSecThreeAPMCL {
                margin-top: -0px;
                width: 90vw;
                margin: 0;
                padding: 0;
            }

            .NSecThreeAPMCR {
                flex: auto;


                img {

                    margin-top: 0px;



                }
            }
        }
    }








}
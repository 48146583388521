.CardS4Ap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        margin-bottom: 20px;
    }

    h3 {

        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        text-transform: capitalize;

        color: #FFFFFF;
    }

    p {
        width: 300px;


        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        text-align: center;

        color: #7F898F;


    }
}



.NSecFourAPM {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: #00141F;
    margin-top: -180px;

    .bgMOB {
        display: none;
    }

    .mob {
        display: none;
    }

    .NSecFourAPWT {
        display: flex;
        width: 80vw;
        // background-color: #fff;
        align-items: center;
        justify-content: space-between;

        h3 {

            font-family: Archivo;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 40px;
            /* or 167% */

            text-transform: capitalize;
            width: 411px;
            color: #FFFFFF;

            span {
                color: #219EBC;

            }
        }

        p {

            font-family: Lexend;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            /* or 179% */

            width: 520px;
            color: #FFFFFF;

        }
    }

    .NSecFourAPWB {
        margin-top: 300px;

        .topL {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }

        .btmWHOLE {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding-bottom: 120px;

            .btmBGcon {
                position: absolute;
                bottom: 0;
                pointer-events: none;

                z-index: 5;

                .bgImg {
                    // width: 100vw;
                    pointer-events: none;
                }

                .tCONs4a {

                    pointer-events: all;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 65%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    h3 {
                        font-family: Archivo;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 40px;
                        /* identical to box height, or 167% */

                        text-align: center;
                        text-transform: capitalize;

                        color: #FFFFFF;
                        margin-bottom: 10px;
                    }

                    p {
                        width: 698px;

                        margin: 0;
                        padding: 0;

                        font-family: Lexend;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 25px;
                        /* or 179% */

                        text-align: center;

                        color: #80929B;


                    }
                }


                .aBCon {
                    display: flex;
                    align-items: center;

                    .bt2 {
                        margin-left: 30px;
                        background-color: #219EBC;
                        color: #fff;
                    }
                }


            }
        }

        .btmL {
            display: flex;
            position: relative;

            .CardS4Ap {
                position: relative;
                top: -170px;
            }


        }

    }
}

@media only screen and (max-width: 1195px) {
    .NSecFourAPM .NSecFourAPWB {
        margin-top: -20px;
        transform: scale(0.9);
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon {
        transform: scale(1.2);
        margin-bottom: -30px;
    }
}

@media only screen and (max-width: 770px) {
    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon .bgImg {
        height: 400px;
        position: relative;
        bottom: -10px;


    }

    .NSecFourAPM {
        .bgMOB {
            display: flex;
            // display: none;
            position: absolute;
            bottom: 100px;
            left: 50%;
            transform: translateX(-50%);
            height: 450px;
            z-index: -1;
            transition: all 0.5s ease;
            pointer-events: all;

            &:hover {
                cursor: pointer;
                bottom: 140px;
            }
        }
    }


    .NSecFourAPM .NSecFourAPWB .btmL {

        // display: none;
        .mainI {
            display: none;
        }
    }

    .NSecFourAPM .NSecFourAPWB .btmL .CardS4Ap {
        top: auto;
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE {
        padding-bottom: 550px;
    }

    .NSecFourAPM .NSecFourAPWB {
        margin-top: 20px;
        transform: scale(1);
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon {
        transform: scale(1);
        margin-bottom: 0px;

    }
}

@media only screen and (max-width: 500px) {
    .NSecFourAPM .NSecFourAPWB .topL {
        margin-bottom: 0;
    }

    .CardS4Ap {
        margin-top: 20px;
    }

    .NSecFourAPM .NSecFourAPWT {
        flex-direction: column;

        h3,
        p {
            width: auto;
        }
    }

    .NSecFourAPM .NSecFourAPWB .topL {
        flex-direction: column;
    }

    .NSecFourAPM .bgMOB {
        width: 324px;
        height: 325px;
        bottom: 450px;

        &:hover {
            bottom: 450px;
        }
    }

    .NSecFourAPM .NSecFourAPWB .btmL {
        flex-direction: column;
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE {
        padding-bottom: 800px;
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon .bgImg {
        &.pc {
            display: none;
        }

        &.mob {
            display: flex;
            height: 565px;
        }
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon .aBCon {
        flex-direction: column;

        .MainGetStartedBTN {
            width: 300px;
        }

        .bt2 {
            margin-left: 0;

        }
    }

    .NSecFourAPM .NSecFourAPWB .btmWHOLE .btmBGcon .tCONs4a {
        width: 90vw;
        top: auto;
        bottom: 50px;
        transform: translateX(-50%);

        h3 {}

        p {
            width: auto;
        }
    }

}
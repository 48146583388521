.SecuritiesOne-Main {
  display: flex;

  margin-top: 150px;
  // min-height: 90vh;
  width: 100%;
  // background-color: red;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;

  .SecuritiesOne-L {
    flex: 1;
    // margin-top: 70px;
    overflow: hidden;

    h1 {
      margin: 0;
      padding: 0;
      width: 360px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 52px;
      letter-spacing: 0.01em;
      margin-top: 70px;
      color: #ffffff;

      span {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 52px;
        letter-spacing: 0.01em;

        color: #00a5cc;
      }
    }

    svg {
      margin: 40px 0;
    }

    .scrollInd {
      display: flex;
      align-items: center;
      margin-top: -30px;

      // justify-content: center;
      svg {
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }
    }

    .svgTwo {
      position: relative;
      left: -30px;
    }

    p {
      margin: 0;
      padding: 0;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */

      color: #ffffff;
      width: 540px;
    }
  }

  .SecuritiesOne-R {
    flex: 1;
    position: relative;
    // background-color: #FFFFFF;
    height: 700px;

    .srbsvg {
      position: absolute;
      left: 38px;
      top: -80px;
    }

    // overflow-y: hidden;
    img {
      position: absolute;
      left: -250px;
      top: -200px;
    }
  }
}

.section2-New {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;
  padding-bottom: 100px;
  overflow: hidden;
  .rt {
    // margin-left: -60px;

    h1 {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      text-transform: capitalize;

      color: #ffffff;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      /* or 225% */
      width: 357px;
      margin: 0;
      padding: 0;
      color: #ffffff;
      margin-top: 36px;
    }
  }

  // background-color: #fff;
  .left {
    margin-left: 7%;

    .sliderConBOX {
      width: 673px;
      height: 723px;
      // background-color: red;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      .smidCon {
        width: 368px;
        height: 228px;

        background: #002133;
        border-radius: 0px 50px 50px 50px;
        padding: 31px;

        h1 {
          font-family: "Archivo";
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 33px;
          /* identical to box height, or 138% */

          text-align: center;
          text-transform: capitalize;

          color: #00a5cc;
        }

        h2 {
          margin-top: 12px;
          font-family: "Lexend";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          /* or 225% */

          text-align: center;

          color: #ffffff;
        }
      }

      .item1 {
        position: absolute;
        top: 0;
        left: 0;
      }

      .item2 {
        position: absolute;
        top: 0;
        right: 0;
      }

      .item4 {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .item3 {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .boxI {
        // display: none;
        // width: 673px;
        &.active {
          .BoxItem {
            h1 {
              opacity: 1;
            }

            h2 {
              opacity: 0;
              pointer-events: none;
            }

            cursor: pointer;

            .circleWrapper {
              background: linear-gradient(
                180deg,
                #219ebc 0%,
                rgba(33, 158, 188, 0) 100%
              );
            }
          }
        }
      }

      .svgLine1 {
        position: absolute;
        top: 140.5px;
        right: 150.5px;
      }

      .svgLine2 {
        position: absolute;
        top: 285.5px;
        right: 78.5px;
      }

      .svgLine3 {
        position: absolute;
        bottom: 140.5px;
        right: 150.5px;
      }

      .svgLine4 {
        position: absolute;

        top: 298.5px;
        left: 78.5px;
      }
    }
  }

  .right {
    display: flex;
    // background-color: #00A5CC;
    // align-items: center;
    justify-content: flex-end;
    flex: 1;
    position: relative;
    // right: -10vw;
    flex-direction: column;

    // img {
    //   // height:60vh;
    //   mix-blend-mode: normal;
    //   opacity: 0.6;
    // }
    .imgConBuildingN {
      width: 630px;
      height: 490px;
      // background-color: #fff;
      position: relative;
      left: -30px;

      // opacity: 0.6;
      .b1Img {
        position: absolute;
        left: 0;
        // bottom: 35px;
        bottom: -335px;
      }

      .b2Img {
        position: absolute;
        left: 58px;
        bottom: -395px;
      }

      .b3Img {
        position: absolute;
        left: 100px;
        bottom: -345px;
      }

      .b4Img {
        position: absolute;
        left: 166px;
        bottom: -395px;
      }

      .b5Img {
        position: absolute;
        left: 250px;
        bottom: -505px;
      }

      .b6Img {
        position: absolute;
        left: 385px;
        bottom: -395px;
      }

      .b7Img {
        position: absolute;
        left: 445px;
        bottom: -359px;
      }

      .b8Img {
        position: absolute;
        left: 523px;
        bottom: -379px;
      }

      .b9Img {
        position: absolute;
        left: 570px;
        bottom: -349px;
      }
    }
  }
}

.BoxItem {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  // background-color: red;
  width: fit-content;

  &:hover {
    h1 {
      opacity: 1;
    }

    h2 {
      opacity: 0;
      pointer-events: none;
    }

    cursor: pointer;

    .circleWrapper {
      background: linear-gradient(
        180deg,
        #219ebc 0%,
        rgba(33, 158, 188, 0) 100%
      );
    }
  }

  h1 {
    font-family: Archivo;
    font-style: normal;
    font-weight: 800;
    font-size: 96px;
    margin: 0;
    padding: 0;
    margin-bottom: -35px;

    // color: rgba(0, 0, 0, 0);

    letter-spacing: 0.02em;
    text-transform: capitalize;
    background: -webkit-linear-gradient(
      -86deg,
      #219ebc 40%,
      #0e4463 55%,
      #0b3852 31%
    );
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
    color: #00141f;
    opacity: 0.2;
  }

  .circleWrapper {
    width: 141.03px;
    height: 141.03px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 3px;
    box-sizing: content-box;
    // background: linear-gradient(180deg, #219EBC 0%, rgba(33, 158, 188, 0) 100%);

    .circle {
      width: 141.03px;
      height: 141.03px;
      border-radius: 50%;
      background: #002133;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h2 {
    width: 149px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    /* or 153% */

    text-align: center;
    text-transform: capitalize;

    color: #ffffff;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1300px) {
  .section2-New {
    flex-direction: column;

    .left {
      margin-left: 0;
    }

    .right {
      margin-top: 100px;
      right: 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .SecuritiesOne-Main.WL .scrollInd {
    margin-top: 100px !important;
  }
}

.section8 {
  display: flex;
  flex-direction: column;
  height: 60vh;
  .top {
    flex: 1.3;
    // border: 1px solid red;
    background-color: #00141f;
    // background-color: #5fc3fd;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    .appointmentWrapper {
      display: flex;
      flex-direction: column;
      width: 80%;
      .innertop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-weight: 800;
          font-size: 36px;
          line-height: 40px;
          text-transform: capitalize;
        }
        .getStartedCon {
          margin-top: 10px;
          display: flex;
          align-items: center;
          width: fit-content;
          transition: all 0.3s ease;
          .fiar {
            font-size: 17px;
            margin-left: 5px;
            color: #fb8500;
          }
          span {
            font-family: Lexend;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #fb8500;
            transition: all 0.2s ease;

            &:hover {
              cursor: pointer;
            }
          }

          &:hover {
            transform: scale(1.3);
          }
        }
      }
      .innerbottom {
        display: flex;
        flex-direction: column;
        
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          align-items: flex-end;
          // align-items: center;
          justify-content: Start;
         
        }
        .nTF {
          margin: 0 20px;
          padding-bottom: 20px;
          color: red !important;
        }

        .textFieldTF {
          color: red;
        }
     

        .spanCondi {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          opacity: 0.5;
        }
      }
    }
  }
  .bottomM {
    flex: 1;
    // border: 1px solid green;
    background: #000e14;
  }
}



.tiform {
  width: 280px;
  position: relative;
  height: 40px;
  overflow: hidden;
  margin: 0px 20px;
  padding-bottom: 3px;
}

.tiform input {
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  padding-top: 10px;
  border: none;
  outline: none;
  background:rgba(255, 0, 0, 0);
  font-family: Lexend;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.tiform label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background:blue; */
  pointer-events: none;

  border-bottom: 1px solid white;
}

.tiform label::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  height: 100%;
  width: 100%;
  border-bottom: 3px solid lightblue;
  transform: translateX(-100%);
  transition:all 0.5s ease;
}

.content-name {
  position: absolute;
  bottom: 5px;
  font-family: Lexend;
  transition: all 0.3s ease;
  color:white;
  opacity: 0.5;
}

.tiform input:focus + .label-name .content-name,
.tiform input:valid + .label-name .content-name {
  // transform: translate(-270%,-100%);
  transform: translateY(-100%);
  
  // left: 0px;
  // transform: translateX(10%);
  font-size: 13px;
}

.tiform input:focus + .label-name::after,.tiform input:valid+ .label-name::after{
    transform: translateX(0%);

}
.sectionTwoAP {
  // height: 140vh;
  margin: 0;
  padding: 0;
  width: 100%;
  // height: 70vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  padding-top: 5vh;


  .sectionTwoAPCon {
    height: 95%;
    width: 78%;
    // background-color: rgba(184, 72, 53, 0.521);

    .s2aCardGrid {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      // background-color: rgb(68, 184, 53);
      grid-template-columns: auto auto;

      .Gleft {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* or 179% */


          color: #7f898f;
          width: 300px;

        }
      }

      .GRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          margin:104px 0;
          width: 410px;
          font-family: Lexend;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          /* or 175% */


          color: #FFFFFF;
        }
      }
    }
  }


  .s2ACard {
    width: 520px;
    height: 351px;
    background: #002133;
    margin-bottom: 30px;
    border-radius: 0px 50px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .blrCrcle {
      position: absolute;
      width: 352px;
      height: 352px;
      background: #c3d9df;
      opacity: 0.2;
      filter: blur(200px);
    }

    .cContent {
      width: 88%;
      height: 88%;

      .cIMG {}

      h3 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height, or 150% */

        text-transform: capitalize;

        color: #FFFFFF;

      }

      p {
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */


        color: #FFFFFF;
      }
    }
  }





}

@media only screen and (max-width: 1195px) {
  .sectionTwoAP .s2ACard{
    width: 420px;
    height: 281px
  }
  #root > div > div > div > div > div.sectionOneAP > div.sectionTwoAP > div > div > div.Gleft > div:nth-child(1) > div.cContent > img{
    height:75px;
  }
  .sectionTwoAP .s2ACard .cContent h3{
    margin-top: 2px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 3px;
  
  }
  .sectionTwoAP .s2ACard .cContent p{
    font-size: 14px;
    line-height: 23px;
    margin: 0;padding: 0;
  }
}

@media only screen and (max-width: 770px) {
  #root > div > div > div > div > div.sectionOneAP > div.sectionTwoAP{
    padding-top: 0;
  }
  .sectionTwoAP .sectionTwoAPCon .s2aCardGrid .GRight h4{
    margin-top: 30px;
    margin-bottom: 70px;
  }
  .sectionTwoAP .sectionTwoAPCon .s2aCardGrid{
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .sectionTwoAP .sectionTwoAPCon {
    margin-top: 30px;
  }
  .sectionTwoAP .sectionTwoAPCon .s2aCardGrid .GRight h4{
    width: 300px;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .sectionTwoAP .s2ACard{
    width: 325px;
    height: 376px;
  }
  #root > div > div > div > div > div.sectionOneAP > div.sectionTwoAP > div > div > div.Gleft > div:nth-child(1) > div.cContent > img{
    width: 100px;
height: 100px;
  }
  .sectionTwoAP .s2ACard .cContent h3{
   margin-top: 20px;
    font-size: 24px;
    line-height: 36px;

  
  }
  .sectionTwoAP .s2ACard .cContent p{
    font-size: 14px;
    line-height: 25px;
    margin: 0;padding: 0;
    margin-top: 8px;
  }
}

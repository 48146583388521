.NSecOneAPMain {
  height: 110vh;
  display: flex;
  width: 100vw;
  // justify-content: center;
  position: relative;
  flex-direction: column;

  .NSecOneAPMainW .mobIMGB {
    display: none;
  }

  .vnI {
    &.pc {
      width: 1088px;
      height: 87px;
      margin: 0 auto;
    }

    &.tab {
      display: none;
      transform: scale(0.9);
    }
    &.mob {
      display: none;
      transform: scale(0.8);
    }
  }

  .NSecOneAPMainW {
    height: 100vh;
    width: 90vw;
    display: flex;
    margin-left: 10vw;
    align-items: center;
    justify-content: space-between;
    // background-color: rgb(226, 148, 148);

    .NSecOneAPMainWL {
      // background-color: rgb(83, 45, 45);
      width: 43vw;

      h3 {
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 28px;
        /* identical to box height, or 175% */
        text-transform: capitalize;
        color: #ffffff;
      }

      h2 {
        width: 410px;
        font-family: Archivo;
        font-style: normal;
        font-weight: 800;
        font-size: 42px;
        line-height: 67px;
        /* or 160% */

        letter-spacing: 0.01em;
        text-transform: capitalize;

        color: #ffffff;

        span {
          color: #219ebc;
        }
      }

      p {
        margin: 0;
        padding: 0;
        width: 410px;
        font-family: Lexend;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        /* or 179% */

        color: #7f898f;
      }

      .rbCon {
        display: flex;
        align-items: center;

        margin-bottom: 10px;

        p {
          margin-left: 10px;
          font-family: Lexend;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          /* identical to box height, or 179% */

          color: #ffffff;
        }

        &.tm {
          margin-top: 30px;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      .aBCon {
        display: flex;
        align-items: center;

        .bt2 {
          margin-left: 30px;
          background-color: #219ebc;
          color: #fff;
        }
      }
    }

    .NSecOneAPMainWR {
      position: relative;
      width: 100%;
      height: 100%;

      .tb {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
      }

      .rMcon {
        display: flex;
        align-items: center;

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 705px;
        height: 700px;

        z-index: 2;
        overflow: hidden;

        .MainS {
          display: flex;
          position: absolute;
          top: 0px;
          left: 0;
          width: 1410px;
          transition: all 0.5s ease;

          &.active {
            left: -705px;
          }
          img {
            width: 672px;
            height: 500px;
            // transform: scale(0.8);
            margin-top: 50px;

            &:last-child{

                margin-left: 30px;
                position: relative;
                left: 30px;
            }
             
            &:first-child{

               position: relative;
               left: 30px;
            }
            
          }
          // z-index: 5;
        }

        .btmC {
          position: absolute;
          bottom: 50px;
          left: 50px;
          display: flex;
          align-items: center;
          width: 100%;

          .PosiScrollI {
            width: 200px;
            height: 6px;
            background-color: #023047;
            position: relative;
            border-radius: 4px;
            position: absolute;
            right: 90px;

            &.active {
              .PosiScrollIinside {
                left: 100px;
              }
            }

            .PosiScrollIinside {
              transition: all 0.4s ease-in-out;
              width: 100px;
              height: 6px;
              background-color: #219ebc;
              border-radius: 4px;
              position: absolute;
              left: 0;
              // right: auto;
            }
          }
        }
      }
    }
  }
}

.notransiton {
  transition: all 0s ease !important;
}

@media only screen and (max-width: 1195px) {
  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWR .rMcon {
    width: 600px;
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWR .tb {
    right: -15%;
  }
}

@media only screen and (max-width: 770px) {
  .NSecOneAPMain .vnI.tab {
    display: flex;
  }
  .NSecOneAPMain .vnI.pc {
    display: none;
  }
  .NSecOneAPMain {
    height: auto;
  }

  .NSecOneAPMain .NSecOneAPMainW {
    flex-direction: column;
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWR {
    // display: none;
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWR .tb {
    right: 0;
  }

  .NSecOneAPMain .NSecOneAPMainW {
    padding-top: 15vh;
    // width: 100vw;
    margin-left: 0;
    align-items: flex-start;
    height: auto;

    .NSecOneAPMainWL {
      display: flex;
      flex-direction: column;
      margin-left: 10vw;
      // height: 100vh;
      width: auto;
      // background-color: #fff;
    }

    .NSecOneAPMainWR {
      // display: none;
      // background-color: rgb(255, 0, 0);
      width: 100vw;
      height: 750px;
      // padding-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .NSecOneAPMain .vnI.tab {
    display: none;
  }
  .NSecOneAPMain .vnI.mob {
    display: flex;
    margin-top: -100px;
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWL {
    h3 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-transform: capitalize;

      color: #ffffff;
    }

    h2 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 58px;
      /* or 161% */
      width: 300px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }

    p {
      width: 307px;
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
    }
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWL .rbCon img {
    padding-top: 4px;
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWL .aBCon {
    flex-direction: column;
    align-items: flex-start;

    .MainGetStartedBTN {
      width: 300px;
    }

    .bt2 {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .NSecOneAPMain .NSecOneAPMainW .NSecOneAPMainWR {
    display: none;
  }

  .NSecOneAPMain .NSecOneAPMainW .mobIMGB {
    display: flex;
    margin-top: 30px;
    width: 100vw;
    position: relative;
    height: 424.59px;

    img {
      position: absolute;
      right: -40px;
      width: 400.24px;
      // height: 324.59px;
      // transform: rotate(6.98deg);
    }
  }
}

.SectionFiveWho {
  height: 50vh;
  width: 100%;
  position: relative;
  margin-bottom: 26px;
  .snazzyMapF {
    width: 100%;
    height: 50vh;
  }
  .contentBox {
    position: absolute;
    bottom: 50px;
    left: clamp(5px, 5vw, 40px);

    border-left: 1.5px solid white;
    padding-left: 15px;

    h3 {
      font-family: Archivo;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-transform: capitalize;

      color: #ffffff;
    }
    h4 {
      margin-top: 5px;
      font-family: Lexend;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 25px;
      /* identical to box height, or 179% */

      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 500px) {
  .SectionFiveWho {
    height: 60vh;
  }
  .SectionFiveWho .snazzyMapF {
    height: 60vh;
  }
  .SectionFiveWho .contentBox {
    width: 80vw;
  }
}

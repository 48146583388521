.SectionOneJD {
    width: 99vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .SectionOneJDWrapper {
        width: 70%;
        margin-top: 10vh;

        .top {
            width: 100%;
            display: flex;

            justify-content: space-between;
            flex-direction: column;

            .inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }



            a {
                margin: 0;
                padding: 1vh 0;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
                opacity: 0.5;
                text-decoration: none;

            }


            h3 {

                margin: 0;
                padding: 1vh 0;
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height, or 150% */


                color: #fff;
            }

            h5 {
                margin: 0;
                padding: 1vh 0;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                /* identical to box height */

                letter-spacing: 0.1em;

                color: #fff;

                opacity: 0.5;
            }

            h6 {
                margin: 0;
                padding: 1vh 0;
                font-family: Archivo;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 15px;
                text-transform: capitalize;

                color: #fff;
                opacity: 0.5;

            }

            .innerBottom {
                button {
                    background: #219EBC;
                    border-radius: 15px;
                    outline: none;
                    border: none;
                    width: 190px;
                    height: 60px;
                    transition: all 0.3s ease;

                    &:hover {
                        cursor: pointer;
                        background: #228faa;
                    }

                    p {
                        margin: 0;
                        padding: 0;
                        font-family: Lexend;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        /* identical to box height */

                        text-align: center;

                        color: #FFFFFF;
                    }
                }
            }
        }

        .bottom {
            padding-bottom: 5vh;
            // text-align: justify;

            h2 {

                margin: 0;
                padding: 0;
                font-family: Archivo;
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height, or 150% */


                color: #fff;
            }

            p {

                margin: 0;
                padding: 0;
                font-family: Lexend;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                /* or 179% */


                color: #fff;
            }

            li {
                list-style-image: url('./../../assets/GreenDoubleTick.svg');
                color: #fff;
            }

        }
    }
}





@media only screen and (max-width: 639px) {

    .SectionOneJD .SectionOneJDWrapper {
        width: 80%;
    }

    .SectionOneJD .SectionOneJDWrapper .top .inner {
        flex-direction: column;
    }

    .SectionOneJD .SectionOneJDWrapper .top .inner .left {
        padding: 1vh 0;
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
    }

    .SectionOneJD .SectionOneJDWrapper .top .inner .mid {
        padding: 1vh 0;
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
    }
    .SectionOneJD .SectionOneJDWrapper .top .innerBottom {
        padding-top: 2vh;
        padding-bottom: 3vh;
    }
    .SectionOneJD .SectionOneJDWrapper .top .innerBottom button {
        width: clamp(300px, 80vw, 600px);
        height: 50px;
        
    }

    .SectionOneJD .SectionOneJDWrapper .top h6 {
        margin-left: 2vw;
    }

}